import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_examinstance_result } from "../../../actions/action-examinstance";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";

import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

class ExamPost extends Component {
  constructor() {
    super();
    this.state = {
      examinstanceDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_examinstance_result } = this.props;
    await get_examinstance_result(activeOuid, activeOuidChain, id);
    console.log("fetched examinstance: ", store.getState().examinstance);
    this.setState({
      examinstanceDetails: store.getState().examinstance.examinstanceDetails,
    });
  }

  async gotoMyExams() {
    const { activeOuid } = store.getState().permission;
    history.push(`/${activeOuid}/myexams`);
  }

  renderExamPreText() {
    const { showGlobalLoader } = store.getState().examinstance;
    console.log(
      "store.getState().examinstance.examinstanceDetails : ",
      store.getState().examinstance.examinstanceDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="ExamGrid">
            <Paper className="ExamModuleWrapper">
              <div className="ExamDetailsWrapper">
                <div className="">
                  {store.getState().examinstance.examinstanceDetails &&
                  store.getState().examinstance.examinstanceDetails.data &&
                  store.getState().examinstance.examinstanceDetails.status ===
                    "completed" ? (
                    <>
                      <h3>
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examData.postExamText
                        }
                      </h3>
                      <div>
                        <Button
                          onClick={() => {
                            this.gotoMyExams();
                          }}
                          color="primary"
                          variant="outlined"
                        >
                          My Exams
                        </Button>
                      </div>
                    </>
                  ) : (
                    "Exam is not yet completed, for results to be shown."
                  )}
                </div>
              </div>
            </Paper>
          </div>
        )}
        <CommonSnackBar />
      </div>
    );
  }

  renderExamInstanceResult() {
    const { showGlobalLoader } = store.getState().examinstance;

    const { activeOuidType } = store.getState().permission;
    console.log(
      "activeOuidType in renderExamInstanceResult : ",
      activeOuidType
    );

    console.log(
      "store.getState().examinstance.examinstanceDetails : ",
      store.getState().examinstance.examinstanceDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="ExamGrid">
            <Paper className="ExamModuleWrapper">
              <div className="ExamDetailsWrapper">
                <div className="">
                  {store.getState().examinstance.examinstanceDetails &&
                  store.getState().examinstance.examinstanceDetails.data &&
                  store.getState().examinstance.examinstanceDetails.result ? (
                    <>
                      {store.getState().examinstance.examinstanceDetails.data
                        .examData.passMarks && (
                        <h3>
                          Result :
                          {store.getState().examinstance.examinstanceDetails
                            .result.passed
                            ? "Passed"
                            : "Failed"}
                        </h3>
                      )}
                      <h3>
                        Exam :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examData.title
                        }
                      </h3>
                      <h4>
                        Name :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails
                            .modifiedBy
                        }
                      </h4>
                      <h4>
                        Username :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examuser
                        }
                      </h4>
                      {store.getState().examinstance.examinstanceDetails.data
                        .examData.passMarks && (
                        <h4>
                          Minimum marks to pass :{" "}
                          {
                            store.getState().examinstance.examinstanceDetails
                              .result.passMarks
                          }
                        </h4>
                      )}
                      {store.getState().examinstance.examinstanceDetails
                        .result && (
                        <h4>
                          Total Marks Scored :{" "}
                          {
                            store.getState().examinstance.examinstanceDetails
                              .result.totalMarks
                          }
                        </h4>
                      )}
                      {store.getState().examinstance.examinstanceDetails
                        .modifiedDate && (
                        <h4>
                          Date :{" "}
                          {new Date(
                            store.getState().examinstance.examinstanceDetails.modifiedDate
                          )
                            .toUTCString()
                            .toLocaleString()}
                        </h4>
                      )}
                    </>
                  ) : (
                    " Please complete the exam and check the results after sometime."
                  )}
                </div>
              </div>
            </Paper>
            <CommonSnackBar />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="section">{this.renderExamPreText()}</div>
          <div className="section">{this.renderExamInstanceResult()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  examinstance: state.examinstance,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_examinstance_result: (activeOuid, activeOuidChain, id) =>
    dispatch(get_examinstance_result(activeOuid, activeOuidChain, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamPost);
