import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_orgunit_details, update_Orgunit } from '../../../actions/action-orgunit';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import CommonSnackBar from '../../common/CommonSnackBar';
import FormButton from '../../common/FormButton';
import { Input, Paper, TextField } from '@material-ui/core';
import { timeFormat } from '../../common/CommonFunctions'
import Divider from '@material-ui/core/Divider';
import Business from "../../assets/images/edwinImages/buildings.png";
import Tasks from "../../assets/images/edwinImages/tasks.png";
import Repo from "../../assets/images/edwinImages/repo.png";
import Employees from "../../assets/images/edwinImages/Employes.png";
import Branches from "../../assets/images/edwinImages/branches.png";
import HRM from "../../assets/images/edwinImages/hrm.png";
import Account from "../../assets/images/edwinImages/account-manager.png";
import CompanyProfileImage from "../../assets/images/edwinImages/userprofile.png";



const store = configureStore();
const { activeOuid } = store.getState().permission;

class CompanyProfile extends Component {
  
  constructor() {
    super();
    this.state = {
      OrgunitDetails: {},
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split('/');
    const id = path[path.length - 2];
    const { get_orgunit_details } = this.props;
    await get_orgunit_details(id);
    const { OrgunitDetails } = store.getState().orgunit;
    console.log("OrgunitDetails", OrgunitDetails);

    this.setState({
      OrgunitDetails: OrgunitDetails,
    });
  }

  onInputChange(event) {
    let newOrgunitDetails = this.state.OrgunitDetails;
    newOrgunitDetails.data[event.target.id] = event.target.value;
    this.setState({
      OrgunitDetails: newOrgunitDetails
    });
  }

  async handleSubmit() {
    // event.preventDefault();
    // console.log("State  : ", this.state);
    const path = window.location.pathname.split('/');
    const id = path[path.length - 2];
    const { update_Orgunit } = this.props;
    await update_Orgunit(this.state.OrgunitDetails, id);
  }


  render() {
    const { showGlobalLoader} = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    const { OrgunitDetails } = store.getState().orgunit;
    console.log("OrgunitDetails render", OrgunitDetails.data);
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <Paper component="form" className="companyProfileBannerWrapper">
                <div className='companyProfileBanner'>
                  <div className='ProfileImageWrap'>
                    <img src={Business} alt=''/>
                    {this.state.OrgunitDetails.data ?
                  
                      <div>{ this.state.OrgunitDetails.data.name }</div>
                    : ''}
                  </div>
                  <div className='ProfileBasicsWrap'>
                    <div>
                      <div className='ProfileBasicsItem'>
                        <span>Name :</span>
                        {this.state.OrgunitDetails.data ?
                          <span>{ this.state.OrgunitDetails.data.name }</span>
                        : ''}
                      </div>
                      <div className='ProfileBasicsItem'>
                        <span>Email :</span><span>ibc@ibc.com</span>
                      </div>
                      <div className='ProfileBasicsItem'>
                        <span>Address :</span><span>Lakshmi Nivas Po munder kannur</span>
                      </div>
                    </div>
                    <div>
                      <div className='ProfileBasicsItem'>
                        <span>Phone :</span><span>9999999999</span>
                      </div>
                      <div className='ProfileBasicsItem'>
                        <span>Email :</span><span>ibc@ibc.com</span>
                      </div>
                      <div className='ProfileBasicsItem'>
                        <span>Address :</span><span>Lakshmi Nivas Po munder kannur</span>
                      </div>
                    </div>
                    <div>
                      
                    </div>
                  </div>
                  
                </div>
                
                <div className="form-item-wrapper">
                  {this.state.OrgunitDetails.data ?
                    <div>{this.state.OrgunitDetails.data.GSTNumber}</div>
                    : ''}
                </div>
                <div className="form-item-wrapper">
                  {this.state.OrgunitDetails.data ?
                    <div>{this.state.OrgunitDetails.data.GSTNumber}</div>
                    : ''}
                </div>
                <div className="form-item-wrapper">
                  {this.state.OrgunitDetails.data ?
                    <div>{this.state.OrgunitDetails.data.GSTNumber}</div>
                    : ''}
                </div>
                <div className="form-item-wrapper">
                  {this.state.OrgunitDetails.data ?
                    <div>{this.state.OrgunitDetails.data.GSTNumber}</div>
                    : ''}
                </div>
                {/* <FormButton
                  onClick={() => this.handleSubmit()}
                  text="Update"
                />
                <CommonSnackBar /> */}
              </Paper>
              {/* <div className="HomeCompanyAppsSection">
                <h3>Manage your company</h3>
                <Paper className='HomeCompanyAppsWrapper'>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/company-profile`); }}>
                    <img src={CompanyProfileImage} alt=''/>
                    <p>Company Profile</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/orgusers`); }}>
                    <img src={Employees} alt=''/>
                    <p>Employees</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/orgunits`); }}>
                    <img src={Branches} alt=''/>
                    <p>Branches</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/org-workqueue-dash`); }}>
                    <img src={Tasks} alt=''/>
                    <p>Tasks</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/document-dash`); }}>
                    <img src={Repo} alt=''/>
                    <p>Repository</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/hrmapp-dash`); }}>
                    <img src={HRM} alt=''/>
                    <p>HRM</p>
                  </div>
                  <div className='HomeCompanyAppsItem' onClick={() => { history.push(`/${activeOuid}/hrmapp-dash`); }}>
                    <img src={Account} alt=''/>
                    <p>Accounts</p>
                  </div>
                  
                </Paper>
              </div> */}
            </div>}
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_orgunit_details: (id) => dispatch(get_orgunit_details(id)),
  update_Orgunit: (payload, orgunit) => dispatch(update_Orgunit(payload, orgunit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);

