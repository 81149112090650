import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { timeFormat, downloadFromUrl } from '../../common/CommonFunctions';
import Button from '@material-ui/core/Button';
import axios from 'axios';


const store = configureStore();


function DocumentDetails(props) {
    const { documentDetails, closeDetails } = props;

    /**var docs = [
        { uri: getSignedUrl(documentDetails.key) }
    ];**/

    /**const docs = [
        { uri: "https://ibc-probot-backend-dev-document-store.s3.amazonaws.com/Mcompany/87778c2e-fcd8-4466-beb2-30a6fadbbc57/orgusers.png?AWSAccessKeyId=ASIAU7P3MUVRNND3ZJEM&Expires=1642813616&Signature=vhEvqE6CxUevaWYea1KPsxr6SmA%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEBEaCXVzLWVhc3QtMSJIMEYCIQDuCYiLcB%2BEYqLe%2BX9MyMRrX0joKBlmnyPARykN9yGyrgIhAIKVhSZYSce7lSCLogPNFgpsIgnFCmBRDqf7j62%2FvRz9KqYCCDkQARoMMzQyNTEzOTg0ODY2Igx4iZhxh6mNaBzS4jMqgwIsvitlyk4lS%2FLWqcBj1Zfo4EZy2QkfyGxmojN5sqq3TN%2FT4kndM18IOwhyMI6sa3USvBMGoAKCCbaa%2BJp0XhJxQwhom3KTq5u%2BNdHdJGy1ErwYdIDNAh2AXH8nAK8HKUGXfV%2FO6AeZwclZd%2FdntAsM0OZKsS%2FJWsTu6PKT3TqYsN4k7L1wCK5QhbdNu%2Bq%2Bu7GzZIWA3zvoykKVzGjYYhTU7YCCE8zofVadOr2jkFn%2FcOJioZBrg3HmUtxXzpMpM90pZirki1igHUsu97bNG15u0QuoNlb9PgLzq6Hx4JepH6Gqab6B87cC1M0Csxe78lpIKAlTXF1vnu2pGwhJl92%2FRwWSMKCYrY8GOpkByP9Uieq7dnflQm7FwfHNiLtRWDlTR9rUbT0IomVVIrrTOPkKXXXxEHq%2BTkD3e5DlE9xNLLH2ksD5PTVWS2F8EnEo8kJx9ZSI4T3bNjdU1SgFeMQqA7Rxt00XQnthntqyvoG3P0CZttu8OsDWUSD9um7a83o0YH9BwB2mO5Qi6GLWUrCkYOOLcZkEu4ZNaT0mEkgnTDKkDsF9" }
    ];
    
    import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

                <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                />  
    **/

    const renderDetails = (details) => {

        return Object.keys(details).map(function (keyName, keyIndex) {
            return (
                <div key={keyName} className="detailsItem">
                    <div className="detailsKey">
                        {keyName} {" : "}
                    </div>
                    {keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
                        <div className="detailsValue">
                            {timeFormat(details[keyName])}
                        </div>
                        :
                        <div className="detailsValue">
                            {details[keyName]}
                        </div>
                    }
                </div>
            )
        });
    }

    const getDownloadUrl = async (key) => {
        const {
            apiEndpoint,
            credentials,
        } = store.getState().jwt.api;
        if (apiEndpoint && credentials) {
            const { activeOuid, activeOuidChain } = store.getState().permission;
            const method = 'get';
            const jwt = `${credentials.idToken.jwtToken}`;
            const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
            const axiosHeader = {
                headers: {
                    Authorization: jwt,
                    'x-ouid': activeOuid,
                    'x-ouidchain': activeOuidChain,
                },
            };
            return axios[method](
                url,
                axiosHeader,
            )
                .then(async (response) => {
                    console.log("signed url get response : ", JSON.stringify(response));
                    console.log("signed url download : ", JSON.stringify(response.data.getURL));
                    await downloadFromUrl(response.data.getURL, documentDetails.name);
                })
                .catch((err) => {
                    console.log("signed url get failed : ", err);
                });
        }
    }

    function getSignedUrl(key) {
        const {
            apiEndpoint,
            credentials,
        } = store.getState().jwt.api;
        if (apiEndpoint && credentials) {
            const { activeOuid, activeOuidChain } = store.getState().permission;
            const method = 'get';
            const jwt = `${credentials.idToken.jwtToken}`;
            const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
            const axiosHeader = {
                headers: {
                    Authorization: jwt,
                    'x-ouid': activeOuid,
                    'x-ouidchain': activeOuidChain,
                },
            };
            return axios[method](
                url,
                axiosHeader,
            )
                .then(async (response) => {
                    console.log("signed url get response : ", JSON.stringify(response));
                    console.log("signed url string : ", JSON.stringify(response.data.getURL));
                    return response.data.getURL;
                })
                .catch((err) => {
                    console.log("signed url get failed : ", err);
                    return "";
                });
        }
    }

    return (
        <div className="detailsDrawer">
            <Button onClick={() => { closeDetails() }} variant="contained">
                Close
            </Button>

            <div>{renderDetails(documentDetails)}</div>
            <Button onClick={() => { getDownloadUrl(documentDetails.key) }} variant="contained" color="primary">
                Download
            </Button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails);