import {
  CREATE_EXAMINSTANCE_REQUESTED,
  CREATE_EXAMINSTANCE_SUCCESS,
  CREATE_EXAMINSTANCE_ERRORED,
  UPDATE_EXAMINSTANCE_REQUESTED,
  UPDATE_EXAMINSTANCE_SUCCESS,
  UPDATE_EXAMINSTANCE_ERRORED,
  GET_EXAMINSTANCES_REQUESTED,
  GET_EXAMINSTANCES_SUCCESS,
  GET_EXAMINSTANCES_ERRORED,
  GET_EXAMINSTANCE_REQUESTED,
  GET_EXAMINSTANCE_SUCCESS,
  GET_EXAMINSTANCE_ERRORED,
  UPDATE_EXAMLINEINSTANCE_REQUESTED,
  UPDATE_EXAMLINEINSTANCE_SUCCESS,
  UPDATE_EXAMLINEINSTANCE_ERRORED,
  GET_EXAMLINEINSTANCES_REQUESTED,
  GET_EXAMLINEINSTANCES_SUCCESS,
  GET_EXAMLINEINSTANCES_ERRORED,
  GET_EXAMLINEINSTANCE_REQUESTED,
  GET_EXAMLINEINSTANCE_SUCCESS,
  GET_EXAMLINEINSTANCE_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_examinstance_requested = () => ({
  type: CREATE_EXAMINSTANCE_REQUESTED,
});

export const create_examinstance_success = () => ({
  type: CREATE_EXAMINSTANCE_SUCCESS,
});

export const create_examinstance_errored = (error) => ({
  type: CREATE_EXAMINSTANCE_ERRORED,
  error,
});

export const update_examinstance_requested = () => ({
  type: UPDATE_EXAMINSTANCE_REQUESTED,
});

export const update_examinstance_success = () => ({
  type: UPDATE_EXAMINSTANCE_SUCCESS,
});

export const update_examinstance_errored = (error) => ({
  type: UPDATE_EXAMINSTANCE_ERRORED,
  error,
});

export const update_examlineinstance_requested = () => ({
  type: UPDATE_EXAMLINEINSTANCE_REQUESTED,
});

export const update_examlineinstance_success = () => ({
  type: UPDATE_EXAMLINEINSTANCE_SUCCESS,
});

export const update_examlineinstance_errored = (error) => ({
  type: UPDATE_EXAMLINEINSTANCE_ERRORED,
  error,
});

export const create_examinstance = (payload) => async (dispatch) => {
  console.log(`create_examinstance`);

  dispatch(create_examinstance_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_examinstance`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/examinstance`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/examinstance`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Exam created Successfully", "success"));
        dispatch(create_examinstance_success());
      })
      .catch((err) => {
        console.log("Exam creation Failed");
        dispatch(showSnackbar("Exam creation Failed", "error"));
        dispatch(create_examinstance_errored(err));
      });
  }
  return null;
};

export const update_examinstance =
  (id, payload, activeOuid) => async (dispatch) => {
    dispatch(update_examinstance_requested());

    const { activeOuidChain } = store.getState().permission;
    console.log(`update_examinstance`);

    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      var url = `${coursesApiEndpoint}/examinstance/${id}`;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          dispatch(
            showSnackbar("Examinstance updated Successfully", "success")
          );
          dispatch(update_examinstance_success());
        })
        .catch((err) => {
          console.log("Examinstance update Failed");
          dispatch(showSnackbar("Examinstance update Failed", "error"));
          dispatch(update_examinstance_errored(err));
        });
    }
    return null;
  };

export const submit_examinstance = (activeOuid, id) => async (dispatch) => {
  const { activeOuidChain } = store.getState().permission;
  console.log(`submit_examinstance`);

  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    console.log("jwt", jwt);
    var url = `${coursesApiEndpoint}/examinstance/${id}?submit=true`;
    let method = "patch";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, null, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Exam submitted", "success"));
        get_examinstance(activeOuid, id);
      })
      .catch((err) => {
        console.log("Examinstance update Failed");
        dispatch(
          showSnackbar(
            "Exam submission failed, please contact support with the details of the error",
            "error"
          )
        );
      });
  }
  return null;
};

export const start_examinstance = (activeOuid, id) => async (dispatch) => {
  const { activeOuidChain } = store.getState().permission;
  console.log(`start_examinstance`);

  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/examinstance/${id}?start=true`;
    let method = "patch";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, null, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Exam started, Good luck", "success"));
      })
      .catch((err) => {
        console.log("Failed to start exam");
        dispatch(
          showSnackbar(
            "Failed to start exam, please contact support with the details of the error",
            err
          )
        );
      });
  }
  return null;
};

export const get_examinstances_requested = () => ({
  type: GET_EXAMINSTANCES_REQUESTED,
});

export const get_examinstances_success = (payload) => ({
  type: GET_EXAMINSTANCES_SUCCESS,
  payload,
});

export const get_examinstances_errored = (error) => ({
  type: GET_EXAMINSTANCES_ERRORED,
  error,
});

export const get_examinstances =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_examinstances_requested());
    console.log(`get_examinstances`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examinstance`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/examinstance?category=${category}`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Fetched Successfully");
          dispatch(get_examinstances_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courses Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses Failed", "error"));
          }
          dispatch(get_examinstances_errored(err));
        });
    }
    return null;
  };

export const get_examinstances_by_examuser =
  (activeOuid, activeOuidChain, examuser) => async (dispatch) => {
    dispatch(get_examinstances_requested());
    console.log(`get_examinstances_by_examuser`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examinstance?examuser=${examuser}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get Exam instances by examuser Fetched Successfully");
          dispatch(get_examinstances_success(response.data));
        })
        .catch((err) => {
          console.log("Get Exam instances  by examuser Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(
              showSnackbar("Get Exam instances  by examuser Failed", "error")
            );
          }
          dispatch(get_examinstances_errored(err));
        });
    }
    return null;
  };

export const get_examinstances_by_examid =
  (activeOuid, activeOuidChain, examid) => async (dispatch) => {
    dispatch(get_examinstances_requested());
    console.log(`get_examinstances_by_examid`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examinstance?examId=${examid}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get Exam instances by examId Fetched Successfully");
          dispatch(get_examinstances_success(response.data));
        })
        .catch((err) => {
          console.log("Get Exam instances  by examId Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(
              showSnackbar("Get Exam instances  by examId Failed", "error")
            );
          }
          dispatch(get_examinstances_errored(err));
        });
    }
    return null;
  };

export const get_examinstance =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_examinstance_requested());
    console.log(`get_examinstance `);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examinstance/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_examinstance_success(response.data));
        })
        .catch((err) => {
          console.log("Get Examinstance Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get Examinstance Failed", "error"));
          }
          dispatch(get_examinstance_errored(err));
        });
    }
    return null;
  };

export const get_examinstance_result =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_examinstance_requested());
    console.log(`get_examinstance_result `);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examinstance/${id}?result=true`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_examinstance_success(response.data));
        })
        .catch((err) => {
          console.log("Get Examinstance Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get Examinstance Failed", "error"));
          }
          dispatch(get_examinstance_errored(err));
        });
    }
    return null;
  };

export const get_examinstance_requested = () => ({
  type: GET_EXAMINSTANCE_REQUESTED,
});

export const get_examinstance_success = (payload) => ({
  type: GET_EXAMINSTANCE_SUCCESS,
  payload,
});

export const get_examinstance_errored = (error) => ({
  type: GET_EXAMINSTANCE_ERRORED,
  error,
});

export const update_examline =
  (examlineId, ouid, payload, type) => async (dispatch) => {
    dispatch(update_examlineinstance_requested());
    console.log(`update_examline`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      //   console.log(`jwt : ${jwt}`);
      let method = "post";
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      if (examlineId) {
        method = "put";
        url = `${coursesApiEndpoint}/examline/${examlineId}`;
      } else {
        url = `${coursesApiEndpoint}/examline`;
      }
      // console.log(`url : ${url}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Examline Create or Upadate Success");
          dispatch(
            showSnackbar("Examline Create or Upadate Successfully", "success")
          );
          dispatch(update_examlineinstance_success(response.data));
          dispatch(get_examlineinstances());
          return response.data.identifier;
        })
        .catch((err) => {
          console.log("Examline Create or Upadate Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("Examline Create or Upadate Failed", "error"));
          dispatch(update_examlineinstance_errored(err));
        });
    }
    return null;
  };

export const get_examlineinstances_requested = () => ({
  type: GET_EXAMLINEINSTANCES_REQUESTED,
});

export const get_examlineinstances_success = (payload) => ({
  type: GET_EXAMLINEINSTANCES_SUCCESS,
  payload,
});

export const get_examlineinstances_errored = (error) => ({
  type: GET_EXAMLINEINSTANCES_ERRORED,
  error,
});

export const get_examlineinstances =
  (activeOuid, examinstanceId) => async (dispatch) => {
    dispatch(get_examlineinstances_requested());
    console.log(`get_examlineinstances`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examlineinstance?examinstanceId=${examinstanceId}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };

      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Examline Success");
          dispatch(get_examlineinstances_success(response.data));
        })
        .catch((err) => {
          console.log("Get All courseline Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Examline Failed", "error"));
          }
          dispatch(get_examlineinstances_errored(err));
        });
    }
    return null;
  };

/**
 * payload to be as below.
 *  {
      "selectedAnswer": "answer1"
    }
 */
export const update_selectedanswer =
  (ouid, examlineinstanceId, payload) => async (dispatch) => {
    console.log(`update_selectedanswer`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      //   console.log(`jwt : ${jwt}`);
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      let method = "patch";
      url = `${coursesApiEndpoint}/examlineinstance/${examlineinstanceId}?updateSelectedAnswer=true`;

      // console.log(`url : ${url}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("update_selectedanswer success");
          get_examlineinstance(ouid, examlineinstanceId);
        })
        .catch((err) => {
          console.log("update_selectedanswer Failed");
          dispatch(showSnackbar("updating selected answer failed", "error"));
        });
    }
    return null;
  };

export const get_examlineinstance = (activeOuid, id) => async (dispatch) => {
  dispatch(get_examlineinstance_requested());
  console.log(`get_examlineinstance `);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${coursesApiEndpoint}/examlineinstance/${id}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get post Fetched Successfully", response.data);
        dispatch(get_examlineinstance_success(response.data));
      })
      .catch((err) => {
        console.log("Get Examlineinstance Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get Examlineinstance Failed", "error"));
        }
        dispatch(get_examlineinstance_errored(err));
      });
  }
  return null;
};

export const get_examlineinstance_requested = () => ({
  type: GET_EXAMLINEINSTANCE_REQUESTED,
});

export const get_examlineinstance_success = (payload) => ({
  type: GET_EXAMLINEINSTANCE_SUCCESS,
  payload,
});

export const get_examlineinstance_errored = (error) => ({
  type: GET_EXAMLINEINSTANCE_ERRORED,
  error,
});
