import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import {
  create_or_update_configs,
  get_all_active_configs,
  get_all_deployed_configs,
} from "../../../actions/action-config";
import { setActiveOuid } from "../../../actions/action-permissions";
import { hideSnackbar } from "../../../actions/action-snackbar";
import Loader from "../../common/Loader";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import ConfigDetails from "./ConfigDetails";

const store = configureStore();

const tableDeployColumns = [
  {
    field: "Key",
    headerName: "Name",
    width: 300,
  },
  {
    field: "LastModified_Date",
    headerName: "Uploaded Time",
    type: "date",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "LastModified")) || ""}`,
  },
];

const tableActiveColumns = [
  {
    field: "key",
    headerName: "Name",
    width: 300,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 150,
  },
  {
    field: "createdBy_Date",
    headerName: "Created Date",
    type: "date",
    width: 150,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "createdDate")) || ""}`,
  },
  {
    field: "modifiedBy",
    headerName: "Modified By",
    width: 150,
  },
  {
    field: "lastModifiedDate_Date",
    headerName: "Modified Date",
    type: "date",
    width: 150,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "lastModifiedDate")) || ""}`,
  },
];

class ConfigDash extends Component {
  constructor() {
    super();
    this.state = {
      activeConfigsToShow: [],
      deployedConfigsToShow: [],
      showDeployedDetails: false,
      showActiveDetails: false,
      currentDeployedConfig: {},
      selectionModelDeployed: [],
      selectionModelActive: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
    // this.unregisterHistoryListener()
    store.dispatch(hideSnackbar());
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_active_configs, get_all_deployed_configs } = this.props;
    await get_all_active_configs(ouid);
    await get_all_deployed_configs(ouid);
    const { allActiveConfigs, deployedConfigs } = store.getState().config;
    this.setState({
      activeConfigsToShow: allActiveConfigs,
      deployedConfigsToShow: deployedConfigs,
    });
  }

  // DataGrid

  openDeployDetails(e) {
    console.log("openDeployDetails : ", e.row);
    this.setState({
      showDeployedDetails: true,
      showActiveDetails: false,
      selectionModelActive: [],
      currentDeployedConfig: e.row,
      selectionModelDeployed: e.row.id,
    });
  }

  openActiveDetails(e) {
    console.log("openDeployDetails : ", e.row);
    this.setState({
      showActiveDetails: true,
      showDeployedDetails: false,
      selectionModelDeployed: [],
      currentActiveConfig: e.row,
      selectionModelActive: e.row.id,
    });
  }

  setSelectionModelDeployed(selection) {
    this.setState({
      selectionModelDeployed: selection,
    });
  }

  setSelectionModelActive(selection) {
    this.setState({
      selectionModelActive: selection,
    });
  }

  // DataGrid

  closeDetails() {
    console.log("closeDetails");
    this.setState({
      showActiveDetails: false,
      currentActiveConfig: {},
    });
  }

  renderDeploylist(configs) {
    let newRows = [];
    configs.map((config, index) => {
      let newRow = config;
      newRow["id"] = config.Key;
      newRows.push(newRow);
    });
    return newRows;
  }

  renderActivelist(configs) {
    let newRows = [];
    configs.map((config, index) => {
      let newRow = config.data;
      newRow["id"] = config.data.key;
      newRows.push(newRow);
    });
    return newRows;
  }

  async handleActivate(currentConfig) {
    console.log("handleActivate");
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const splitedKey = currentConfig.Key.split("/");
    const identifier = splitedKey[splitedKey.length - 1].replace(".json", "");
    const { create_or_update_configs } = this.props;
    let data = {
      key: currentConfig.Key,
      identifier: identifier,
      ETag: currentConfig.ETag,
    };
    let action = "create";
    const exist = this.state.activeConfigsToShow.find(
      ({ data }) => data.key === currentConfig.Key
    );
    // console.log("handleActivate exist : ", exist);
    if (exist) {
      action = "update";
      data["createdBy"] = exist.data.createdBy;
      data["createdDate"] = exist.data.uploadedDate;
    }
    // console.log("handleActivate data : ", data);
    await create_or_update_configs(ouid, identifier, data, action);
    this.fetchDetails();
    //window.location.reload();
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().config;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="configPageWrapper">
            <div>
              <h2>Deployed Configs</h2>
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <>
                  <div style={{ height: 500, width: 500 }}>
                    <DataGrid
                      rows={this.renderDeploylist(
                        this.state.deployedConfigsToShow
                      )}
                      columns={tableDeployColumns}
                      pageSize={15}
                      onCellClick={(e) => {
                        this.openDeployDetails(e);
                      }}
                      selectionModel={this.state.selectionModelDeployed}
                      hideFooterSelectedRowCount
                      onSelectionModelChange={(selection) => {
                        // console.log("selection  :::: ", selection);
                        if (selection.length > !2) {
                          let lastElement = selection.pop();
                          this.setSelectionModelDeployed(lastElement);
                        } else {
                          let lastElement = selection.pop();
                          this.setSelectionModelDeployed(lastElement);
                        }
                      }}
                    />
                  </div>
                  <div>
                    {this.state.showDeployedDetails ? (
                      <div>
                        <Button
                          onClick={() => {
                            this.handleActivate(
                              this.state.currentDeployedConfig
                            );
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Activate
                        </Button>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              <h2>Active Configs</h2>
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <div style={{ height: 500, width: 500 }}>
                  <DataGrid
                    rows={this.renderActivelist(this.state.activeConfigsToShow)}
                    columns={tableActiveColumns}
                    pageSize={15}
                    onCellClick={(e) => {
                      this.openActiveDetails(e);
                    }}
                    selectionModel={this.state.selectionModelActive}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => {
                      // console.log("selection  :::: ", selection);
                      if (selection.length > !2) {
                        let lastElement = selection.pop();
                        this.setSelectionModelActive(lastElement);
                      } else {
                        let lastElement = selection.pop();
                        this.setSelectionModelActive(lastElement);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              {this.state.showActiveDetails && (
                <ConfigDetails
                  configDetails={this.state.currentActiveConfig}
                  closeDetails={() => {
                    this.closeDetails();
                  }}
                />
              )}
            </div>
          </div>
          <CommonSnackBar />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
  create_or_update_configs: (activeOuid, identifier, payload, action) =>
    dispatch(create_or_update_configs(activeOuid, identifier, payload, action)),
  get_all_active_configs: (activeOuid) =>
    dispatch(get_all_active_configs(activeOuid)),
  get_all_deployed_configs: (activeOuid) =>
    dispatch(get_all_deployed_configs(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigDash);
