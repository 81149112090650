import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_org_plan_details } from '../../../actions/action-orgunit';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { timeFormat } from '../../common/CommonFunctions'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const store = configureStore();

class OrgPlan extends Component {

    constructor() {
        super();
        this.state = {
            orgPlanDetails: '',
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const id = path[path.length - 2];
        const { get_org_plan_details } = this.props;
        await get_org_plan_details(id);
        const { orgPlanDetails } = store.getState().orgunit;
        this.setState({
            orgPlanDetails: orgPlanDetails,
        });
    }

    render() {
        const { showGlobalLoader } = store.getState().orgunit;
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <div className="listHeader">
                                <h1>Org Plan Details</h1>
                            </div>
                            {this.state.orgPlanDetails ?
                                <div>
                                    <TextareaAutosize
                                        value={JSON.stringify(this.state.orgPlanDetails, null, 2)}
                                        style={{ width: "50%" }}
                                    />
                                </div> :
                                'No Org Plan details Found'}
                        </div>
                    }
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orgunit: state.orgunit,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_org_plan_details: (id) => dispatch(get_org_plan_details(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPlan);

