import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { history } from '../reducers';

export const sagaMiddleware = createSagaMiddleware();

let store;

export default function configureStore(initialState) {
  const reduxDevToolsExtCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const env = process.env.NODE_ENV && process.env.NODE_ENV.trim();
  const composeEnhancers = (env === 'development' && reduxDevToolsExtCompose) || compose;

  if (store) {
    return store;
  }

  store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        sagaMiddleware,
      ),
    ),
  );

  return store;
}
