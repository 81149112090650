import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_exams } from "../../../actions/action-exam";
import {
  create_exam_user,
  get_all_exam_users,
} from "../../../actions/action-examuser";

import { get_all_orguesr } from "../../../actions/action-orguser";

import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import FormButton from "../../common/FormButton";
import { Input, Paper, TextField } from "@material-ui/core";
import { timeFormat } from "../../common/CommonFunctions";
import { setActiveOuid } from "../../../actions/action-permissions";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../common/SearchField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { showSnackbar } from "../../../actions/action-snackbar";
import ExamCard from "./ExamCard";
import Select from "react-select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CommonDelete from "../../common/CommonDelete";

const store = configureStore();
let selectedUserName = "";
let selectedExamId = "";
let selectedExam = "";
let selectedExamData = "";
let selectedStartTime = "";

class ExamSubscriptionDash extends Component {
  constructor() {
    super();
    this.state = {
      examsToShow: [],
      examOptionsToShow: [],
      usersToShow: [],
      userOptionsToShow: [],
      examUsersToShow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("fetchDetails ouid : ", ouid);

    const { activeOuid, activeOuidChain } = store.getState().permission;

    console.log(" fetchDetails activeOuid : ", activeOuid);
    console.log("fetchDetails permission : ", store.getState().permission);

    const { setActiveOuid, get_all_orguesr, get_exams, get_all_exam_users } =
      this.props;

    await setActiveOuid(ouid);
    await get_all_orguesr(activeOuid);
    const { orgUserProfiles } = store.getState().orgUserProfile;

    console.log("fetchDetails > orgUserProfiles : ", orgUserProfiles);

    await get_exams(activeOuid, activeOuidChain, "", activeOuid);
    const { exams } = store.getState().exam;
    console.log("fetchDetails > exams : ", exams);
    console.log(" get_all_orguesr activeOuid : ", activeOuid);
    let userOptionsToShow = orgUserProfiles.map((opt) => ({
      label: opt.data.username,
      value: opt.data.username,
    }));
    let examOptionsToShow = exams.map((opt) => ({
      label: opt.data.title,
      value: opt.data.identifier,
      data: opt.data,
    }));

    await get_all_exam_users(activeOuid, activeOuidChain);
    const { examUsers } = store.getState().examuser;

    this.setState({
      examsToShow: exams,
      examOptionsToShow: examOptionsToShow,
      usersToShow: orgUserProfiles,
      userOptionsToShow: userOptionsToShow,
      examUsersToShow: examUsers,
    });
  }

  handleExamOnChange(examOption) {
    console.log(examOption.value);
    selectedExamId = examOption.value;
    selectedExam = examOption.label;
    selectedExamData = examOption.data;

    console.log(examOption.data);
  }

  handleUserOnChange(userOption) {
    console.log(userOption.value);
    selectedUserName = userOption.value;
  }

  onInputChange = (event) => {
    console.log("startDatetime", event.target.value);
    selectedStartTime = event.target.value;
  };

  addSubscription = async (event) => {
    console.log(selectedExamId, selectedUserName);
    const { create_exam_user } = this.props;
    let examUserPayload = {
      examId: selectedExamId,
      examuser: selectedUserName,
      exam: selectedExam,
      examData: selectedExamData,
      examStartTime: selectedStartTime,
    };
    await create_exam_user(examUserPayload);
    this.fetchDetails();
  };

  renderExams(examList) {
    const { showGlobalLoader } = store.getState().exam;
    return (
      <>
        <div>
          <section className="section">
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                <div className="container">
                  Select Exam *
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={this.state.examOptionsToShow}
                    onChange={(opt) => this.handleExamOnChange(opt)}
                  />
                </div>
              </>
            )}
            <CommonSnackBar />
          </section>
        </div>
      </>
    );
  }

  renderExamUsers(examusers) {
    console.log("examusers : ", JSON.stringify(examusers));
    return examusers.map((row) => (
      <TableRow key={row.data.username}>
        <TableCell>{row.data.examuser}</TableCell>
        <TableCell>{row.data.exam}</TableCell>
        <TableCell>{row.data.examId}</TableCell>
        <TableCell>{row.data.createdBy}</TableCell>
        <TableCell>{row.data.examStartTime}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>
          <CommonDelete id={row.data.identifier} type="ExamSub" />
          {/* <DeleteIcon /> */}
        </TableCell>
      </TableRow>
    ));
  }

  renderUsers(userList) {
    const { orgUserProfiles, showGlobalLoader } =
      store.getState().orgUserProfile;

    console.log("renderUsers >orgUserProfiles ", orgUserProfiles);
    return (
      <section className="section">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="container">
            Select User *
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="color"
              options={this.state.userOptionsToShow}
              onChange={(opt) => this.handleUserOnChange(opt)}
            />
          </div>
        )}
      </section>
    );
  }

  render() {
    const { permissions } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div>{this.renderUsers(this.state.usersToShow)}</div>
            <div>{this.renderExams(this.state.examsToShow)}</div>
            <div className="form-item-wrapper">
              <TextField
                id="examStartTime"
                label="Scheduled Start Time"
                type="datetime-local"
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().slice(0, 16),
                }}
                required
                onChange={this.onInputChange}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.addSubscription()}
            >
              Add Subsciption
            </Button>
            <div className="List">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="TableHeader">Username</TableCell>
                      <TableCell className="TableHeader">Exam</TableCell>
                      <TableCell className="TableHeader">ExamId</TableCell>
                      <TableCell className="TableHeader">CreatedBy</TableCell>
                      <TableCell className="TableHeader">
                        ExamStartTime
                      </TableCell>
                      <TableCell className="TableHeader">Status</TableCell>
                      <TableCell className="TableHeader">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.examUsersToShow.length !== 0
                      ? this.renderExamUsers(this.state.examUsersToShow)
                      : "No Exams Registered"}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
  get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
  get_all_exam_users: (activeOuid, activeOuidChain) =>
    dispatch(get_all_exam_users(activeOuid, activeOuidChain)),
  get_exams: (activeOuid, activeOuidChain, category, ouid) =>
    dispatch(get_exams(activeOuid, activeOuidChain, category, ouid)),
  create_exam_user: (payload) => dispatch(create_exam_user(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamSubscriptionDash);
