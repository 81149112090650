import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import Completed from "../assets/images/edwinImages/Completed.png";
import { history } from '../../reducers';

class ChangePasswordConfirmation extends Component {
  render() {
    return (
      <div className="fullPageImage">
        <Paper className="SignupWelcomePage">
          <img src={Completed} alt='' />
          <h1>Change Password</h1>
          <p>Your password has been successfully updated!</p>
          <Button variant="contained" color="primary" type="submit" fullWidth onClick={() => { history.push(`/login`); }}>
            Login
          </Button>
        </Paper>
    </div>
    );
  }
}

export default ChangePasswordConfirmation;