import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_orgunit_details, update_Orgunit } from '../../../actions/action-orgunit';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import CommonSnackBar from '../../common/CommonSnackBar';
import FormButton from '../../common/FormButton';
import { Input, Paper, TextField } from '@material-ui/core';
import { timeFormat } from '../../common/CommonFunctions'
import { setActiveOuid } from "../../../actions/action-permissions";


const store = configureStore();

class EditOrgunitDetails extends Component {

  constructor() {
    super();
    this.state = {
      OrgunitDetails: {},
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];

    const { activeOuid, activeOuidChain } = store.getState().permission;
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);

    const { get_orgunit_details } = this.props;
    await get_orgunit_details(id);
    const { OrgunitDetails } = store.getState().orgunit;
    this.setState({
      OrgunitDetails: OrgunitDetails,
    });
  }

  onInputChange(event) {
    let newOrgunitDetails = this.state.OrgunitDetails;
    newOrgunitDetails.data[event.target.id] = event.target.value;
    this.setState({
      OrgunitDetails: newOrgunitDetails
    });
  }

  async handleSubmit() {
    // event.preventDefault();
    // console.log("State  : ", this.state);
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    const { update_Orgunit } = this.props;
    await update_Orgunit(this.state.OrgunitDetails, id);
  }


  renderDetails(details, onInputChange) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="form-item-wrapper">
          <label htmlFor="Clientid" className="input_label">{keyName}</label>
          {keyName === 'name' || keyName === 'ouidChain' || keyName === 'identifier' || keyName === 'createdBy' || keyName === 'entityType' || keyName === 'type' || keyName === 'createdDate' || keyName === 'lastModifiedDate' ?
            keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
              <Input
                className="FormInput"
                id={keyName}
                value={timeFormat(details[keyName])}
                readOnly
              />
              :
              <Input
                className="input"
                id={keyName}
                value={details[keyName]}
                readOnly
              />
            : <Input
              className="input"
              id={keyName}
              value={details[keyName]}
              onChange={onInputChange}
            />}

        </div>
      )
    });
  }

  render() {
    const { showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <Paper component="form" className="paper">
                <h1>Edit orgunit Details</h1>
                <form>
                  {this.state.OrgunitDetails.data ? this.renderDetails(this.state.OrgunitDetails.data, this.onInputChange) : 'No Orgunit details Found'}
                </form>
                <FormButton
                  onClick={() => this.handleSubmit()}
                  text="Update"
                />
                <CommonSnackBar />
              </Paper>
            </div>}
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_orgunit_details: (id) => dispatch(get_orgunit_details(id)),
  update_Orgunit: (payload, orgunit) => dispatch(update_Orgunit(payload, orgunit)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrgunitDetails);

