import {
    GET_ALL_ORGUSER_REQUESTED,
    GET_ALL_ORGUSER_SUCCESS,
    GET_ALL_ORGUSER_ERRORED,
    GET_ORG_USER_DETAILS_REQUESTED,
    GET_ORG_USER_DETAILS_SUCCESS,
    GET_ORG_USER_DETAILS_ERRORED,
    UPDATE_ORG_USER_REQUESTED,
    UPDATE_ORG_USER_SUCCESS,
    UPDATE_ORG_USER_ERRORED,
    CREATE_ORG_USER_REQUESTED,
    CREATE_ORG_USER_SUCCESS,
    CREATE_ORG_USER_ERRORED,
} from '../constant/action-types';

const defaultState = {
    showGlobalLoader: false,
    orgUserDetails: {},
    orgUserProfiles: [],
    error: {}
};

export function orgUserProfile(state = defaultState, action) {
    switch (action.type) {

        case GET_ALL_ORGUSER_REQUESTED:
            console.log("GET_ALL_ORGUSER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                orgUserProfiles: []
            };

        case GET_ALL_ORGUSER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                orgUserProfiles: action.payload.items
            };

        case GET_ALL_ORGUSER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case GET_ORG_USER_DETAILS_REQUESTED:
            console.log("GET_ORG_USER_DETAILS_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                orgUserDetails: {}
            };

        case GET_ORG_USER_DETAILS_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                orgUserDetails: action.payload
            };

        case GET_ORG_USER_DETAILS_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case UPDATE_ORG_USER_REQUESTED:
            console.log("UPDATE_ORG_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
            };

        case UPDATE_ORG_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
            };

        case UPDATE_ORG_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };


        case CREATE_ORG_USER_REQUESTED:
            console.log("CREATE_ORG_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
            };

        case CREATE_ORG_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
            };

        case CREATE_ORG_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        default:
            return state;
    }
}