import React, { Component } from "react";
import { connect } from "react-redux";
import SidebarWrapper from "../layout/SidebarWrapper";
import Footer from "../layout/Footer";
import configureStore from "../../store/configureStore";
import UnSubscribedUser from "./UserPage/UnSubscribedUser";
import { history } from "../../reducers";
import CommonSnackBar from "../common/CommonSnackBar";
import Header from "../layout/Header";

const store = configureStore();

class UserHome extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    console.log("componentDidMount  : ");
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { details } = store.getState().myProfile;

    return (
      <div className="page">
        <div className="container">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
          <div>
            {details.data && (
              <div>
                {details.data.subscriptionStatus === "unsubscribed" &&
                details.data.defaultOrgType !== "SYSTEM" &&
                details.data.defaultOrgType !== "ROOT" ? (
                  <UnSubscribedUser userDetails={details} />
                ) : (
                  <div></div>
                )}
              </div>
            )}
            <CommonSnackBar />
          </div>
        </div>
      </div>
      // <div className="pageWrapper">
      //     <Header
      //         auth={this.props.auth}
      //         permissions={permissions}
      //     />
      //     <div className="sidenav"></div>
      //     <div className="main"></div>
      //     <div className="footer"></div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  myProfile: state.myProfile,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserHome);
