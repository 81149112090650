import {
    SHOW_SNACKBAR,
    HIDE_SNACKBAR,
  } from '../constant/action-types';
  
  export const showSnackbar = (snackbarMessage, snackbarType) => ({
    type: SHOW_SNACKBAR,
    payload: { snackbarMessage, snackbarType },
  });
  
  export const hideSnackbar = () => ({
    type: HIDE_SNACKBAR,
  });
  
  