import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_exams } from "../../../actions/action-exam";
import { get_all_exam_users } from "../../../actions/action-examuser";

import {
  get_examinstances_by_examuser,
  get_examinstances_by_examid,
} from "../../../actions/action-examinstance";

import { get_all_orguesr } from "../../../actions/action-orguser";

import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Paper, TextField } from "@material-ui/core";
import { setActiveOuid } from "../../../actions/action-permissions";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EventIcon from "@material-ui/icons/Event";
import Tooltip from "@material-ui/core/Tooltip";

import Select from "react-select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonPrimary from "../../common/ButtonPrimary";

const store = configureStore();
let selectedUserName = "";
let selectedExamId = "";
const Icontooltip = withStyles({
  tooltip: {
    fontSize: 12,
  },
})(Tooltip);

class ExamInstances extends Component {
  constructor() {
    super();
    this.state = {
      examsToShow: [],
      examOptionsToShow: [],
      usersToShow: [],
      userOptionsToShow: [],
      examInstancesToShow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("fetchDetails ouid : ", ouid);

    const { activeOuid, activeOuidChain } = store.getState().permission;

    console.log(" fetchDetails activeOuid : ", activeOuid);
    console.log("fetchDetails permission : ", store.getState().permission);

    const { setActiveOuid, get_all_orguesr, get_exams } = this.props;

    await setActiveOuid(ouid);
    await get_all_orguesr(activeOuid);
    const { orgUserProfiles } = store.getState().orgUserProfile;

    console.log("fetchDetails > orgUserProfiles : ", orgUserProfiles);

    await get_exams(activeOuid, activeOuidChain, "", activeOuid);
    const { exams } = store.getState().exam;
    console.log("fetchDetails > exams : ", exams);
    console.log(" get_all_orguesr activeOuid : ", activeOuid);
    let userOptionsToShow = orgUserProfiles.map((opt) => ({
      label: opt.data.username,
      value: opt.data.username,
    }));
    let examOptionsToShow = exams.map((opt) => ({
      label: opt.data.title,
      value: opt.data.identifier,
      data: opt.data,
    }));

    this.setState({
      examsToShow: exams,
      examOptionsToShow: examOptionsToShow,
      usersToShow: orgUserProfiles,
      userOptionsToShow: userOptionsToShow,
    });
  }

  handleExamOnChange(examOption) {
    if (examOption) {
      console.log(examOption.value);
      selectedExamId = examOption.value;
      selectedUserName = "";
    } else {
      selectedExamId = "";
    }
  }

  handleUserOnChange(userOption) {
    if (userOption) {
      console.log(userOption.value);
      selectedUserName = userOption.value;
      selectedExamId = "";
    } else {
      selectedUserName = "";
    }
  }

  getExamInstances = async (event) => {
    console.log(selectedExamId, selectedUserName);
    const { get_examinstances_by_examuser, get_examinstances_by_examid } =
      this.props;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    if (selectedExamId === "" && selectedUserName !== "") {
      await get_examinstances_by_examuser(
        activeOuid,
        activeOuidChain,
        selectedUserName
      );
      this.setState({
        ...this.state,
        examInstancesToShow: store.getState().examinstance.examinstances,
      });
    }
    if (selectedUserName === "" && selectedExamId !== "") {
      await get_examinstances_by_examid(
        activeOuid,
        activeOuidChain,
        selectedExamId
      );
      this.setState({
        ...this.state,
        examInstancesToShow: store.getState().examinstance.examinstances,
      });
    }
  };

  renderExams() {
    const { showGlobalLoader } = store.getState().exam;
    return (
      <>
        <div>
          <section className="section">
            {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                <div className="container">
                  Select Exam
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={this.state.examOptionsToShow}
                    onChange={(opt) => this.handleExamOnChange(opt)}
                  />
                </div>
              </>
            )}
            <CommonSnackBar />
          </section>
        </div>
      </>
    );
  }

  renderExamInstances(examinstances) {
    console.log("examusers : ", JSON.stringify(examinstances));
    const { activeOuid } = store.getState().permission;

    return examinstances.map((row) => (
      <TableRow key={row.data.username}>
        <TableCell>{row.data.examuser}</TableCell>
        <TableCell>{row.data.exam}</TableCell>
        <TableCell>{row.status}</TableCell>
        {row.result ? (
          <>
            <TableCell>
              {row.result.totalMarks}/{row.result.maxMarks}
            </TableCell>
            <TableCell>{row.result.passed}</TableCell>
          </>
        ) : (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        )}
        <TableCell>
          <Icontooltip title="Events">
            <IconButton
              aria-label="view"
              onClick={() => {
                history.push(`/${activeOuid}/eievents/${row.data.identifier}`);
              }}
            >
              <EventIcon />
            </IconButton>
          </Icontooltip>
        </TableCell>
      </TableRow>
    ));
  }

  renderUsers(userList) {
    const { orgUserProfiles, showGlobalLoader } =
      store.getState().orgUserProfile;

    console.log("renderUsers >orgUserProfiles ", orgUserProfiles);
    return (
      <section className="section">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="container">
            Select User
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="color"
              options={this.state.userOptionsToShow}
              onChange={(opt) => this.handleUserOnChange(opt)}
            />
          </div>
        )}
      </section>
    );
  }

  render() {
    const { permissions } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div>{this.renderUsers(this.state.usersToShow)}</div>
            <div>{this.renderExams(this.state.examsToShow)}</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.getExamInstances()}
            >
              Get Exam Instances
            </Button>
            <div className="List">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="TableHeader">Username</TableCell>
                      <TableCell className="TableHeader">Exam</TableCell>
                      <TableCell className="TableHeader">Status</TableCell>
                      <TableCell className="TableHeader">Score</TableCell>
                      <TableCell className="TableHeader">Result</TableCell>
                      <TableCell className="TableHeader">View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.examInstancesToShow.length !== 0
                      ? this.renderExamInstances(this.state.examInstancesToShow)
                      : "No Exam Instances"}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
  get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
  get_all_exam_users: (activeOuid, activeOuidChain) =>
    dispatch(get_all_exam_users(activeOuid, activeOuidChain)),
  get_exams: (activeOuid, activeOuidChain, category, ouid) =>
    dispatch(get_exams(activeOuid, activeOuidChain, category, ouid)),
  get_examinstances_by_examuser: (activeOuid, activeOuidChain, examuser) =>
    dispatch(
      get_examinstances_by_examuser(activeOuid, activeOuidChain, examuser)
    ),
  get_examinstances_by_examid: (activeOuid, activeOuidChain, examId) =>
    dispatch(get_examinstances_by_examid(activeOuid, activeOuidChain, examId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamInstances);
