import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_course,
  get_courselines,
  get_courselines_public,
  get_course_public,
  create_update_courseline,
} from "../../../actions/action-course";
import Loader from "../../common/Loader";
import Header from "../../layout/Header";
import { history } from "../../../reducers";
import Player from "../Vod/Player";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { showSnackbar } from "../../../actions/action-snackbar";
// import nodata from "../../assets/images/edwinImages/novideo.jpeg";

import axios from "axios";

const store = configureStore();

class CoursePublicDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseDetails: {},
      signedUrl: "",
      courselines: [],
      newchapterTitle: "",
      currentVideo: "",
      currentSummary: "",
      featuredImage: "",
      currentSequenceNumber: "",
      addChapterVisibility: false,
      subcEmail: "",
      subcName: "",
      subcPhone: "",
    };
    this.createWorkorder = this.createWorkorder.bind(this);
    this.createOpenWorkorder = this.createOpenWorkorder.bind(this);
  }

  componentDidMount() {
    this.fetchPublicDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchPublicDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    let ouid = 'edwin';
    console.log("ouid : ", ouid);

    const { get_course_public, get_courselines_public } = this.props;
    await get_course_public(ouid, id);
    await get_courselines_public(ouid, id);

    console.log("fetched course details: ", store.getState().course);
    this.setState({
      courseDetails: store.getState().course.courseDetails,
      courselines: store.getState().course.courselines,
    });
    console.log("fetched course details: ", store.getState().course);

    if (this.state.courselines.length > 0) {
      this.state.courselines.forEach((element) => {
        console.log("element.data.sequenceNumber", element.data.sequenceNumber);
        if (element.data.sequenceNumber === "1") {
          console.log("element.data.courseVideo", element.data.courseVideo);
          element["currentSelection"] = "true";
          let email = "";
          let name = "";
          let phone="";
          if (store.getState().jwt.api.userSession) {
            email = store.getState().jwt.api.userSession.session.username;
            name = store.getState().jwt.api.userSession.session.attributes.name;
            phone = store.getState().jwt.api.userSession.session.attributes.phone_number;
          }
          this.setState({
            currentVideo: element.data.courseVideo,
            currentSequenceNumber: element.data.sequenceNumber,
            currentSummary: element.data.summary,
            currentTitle: element.data.title,
            featuredImage: element.data.featuredImage,
            subcEmail: email,
            subcName: name,
            subcPhone: phone,
          });
        }
      });
    }
  }

  onInputChange = (event) => {
    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  renderCourse() {
    const { showGlobalLoader, showGlobalLoaderCourseline } =
      store.getState().course;
    console.log("featuredImage", this.state.featuredImage);
    console.log("currentVideo", this.state.currentVideo);
    console.log(
      "store.getState().jwt.api.userSession",
      store.getState().jwt.api.userSession
    );
    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="CourseGrid">
            <div className="CourseVideoWrapper">
              <div className="currentVideo">
                {showGlobalLoaderCourseline ? (
                  <Loader />
                ) : store.getState().course.courselines.length !== 0 ? (
                  <div>
                    {this.state.currentVideo ? (
                      <Player urlSrc={this.state.currentVideo} />
                    ) : (
                      <img
                        src={this.state.featuredImage}
                        className="postImage"
                        alt="click to view"
                      />
                    )}
                    <div className="CourseModuleTitle">
                      <h2>{this.state.currentTitle}</h2>
                    </div>
                    <div>Chapter: {this.state.currentSequenceNumber}</div>
                    <div>{this.state.currentSummary}</div>
                  </div>
                ) : (
                  <div className="noDataMessage">
                    <h3>No Existing Course Chapters.</h3>
                  </div>
                  
                )}
              </div>
              <div className="CourseDetailsWrapper">
                <div className="">
                  {store.getState().course.courseDetails &&
                  store.getState().course.courseDetails.data ? (
                    <h1>{store.getState().course.courseDetails.data.title}</h1>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {store.getState().course.courseDetails &&
                  store.getState().course.courseDetails.data ? (
                    <>{store.getState().course.courseDetails.data.summary}</>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <Paper className="CourseModuleWrapper">
              {showGlobalLoaderCourseline || showGlobalLoader ? (
                <Loader />
              ) : store.getState().course.courselines.length !== 0 ? (
                <div>
                  {this.renderCourselines(
                    store.getState().course.courselines,
                    store.getState().course.courseDetails.data.identifier
                  )}
                  {!store.getState().jwt.api.userSession ? (
                    <div className="CourseModuleTitleTestBtn">
                      <div className="field">
                        <p className="control">
                          <TextField
                            className="input"
                            variant="outlined"
                            label="Email"
                            type="text"
                            id="subcEmail"
                            aria-describedby="usernameHelp"
                            placeholder="Enter Email"
                            onChange={this.onInputChange}
                            fullWidth
                          />
                        </p>
                      </div>
                      <div className="field">
                        <p className="control">
                          <TextField
                            className="input"
                            variant="outlined"
                            label="Name"
                            type="text"
                            id="subcName"
                            placeholder="Name"
                            onChange={this.onInputChange}
                            fullWidth
                          />
                        </p>
                      </div>
                      <div className="field">
                        <p className="control">
                          <TextField
                            className="input"
                            variant="outlined"
                            label="Phone"
                            type="text"
                            id="subcPhone"
                            placeholder="Phone"
                            onChange={this.onInputChange}
                            fullWidth
                          />
                        </p>
                      </div>
                      <div className="field">
                        <p className="control">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => this.createOpenWorkorder()}
                          >
                            Request Course Access
                          </Button>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="field">
                      <div className="CourseModuleTitleTestBtn">

                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => this.createWorkorder()}
                        >
                          Request Course Subscription
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="noDataMessage">
                  {/* <img src={nodata}/> */}
                  <h3>No Existing Course Chapters.</h3>
                </div>
                
              )}
            </Paper>
          </div>
        )}
      </div>
    );
  }

  renderCourselines = (courselines, courseId) => {
    const courselinesSorted = courselines.sort((a,b)=> a.data.sequenceNumber-b.data.sequenceNumber);
    return courselinesSorted.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "CourseModuleTumbnail Active"
              : "CourseModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
              featuredImage: `${item.data.featuredImage}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div className="CourseModuleTitle">{item.data.title}</div>
            {/* <div>Chapter: {item.data.sequenceNumber}</div> */}
          </div>

          {/* <div>
            <Player
              urlSrc={
                item.data.courseVideo
              }
            />
            Video : <strong>{item.data.courseVideo}</strong>
          </div> */}
        </div>
      </>
    ));
  };

  createOpenWorkorder() {
    const path = window.location.pathname.split("/");
    let ouid = 'edwin';
    const { woUrl } = store.getState().jwt.api;
    const method = "post";
    const url = `${woUrl}/workorder-public`;
    const axiosHeader = {
      headers: {
        "x-ouid": ouid,
      },
    };

    const woDetails = {
      courseTitle: store.getState().course.courseDetails.data.title,
      email: this.state.subcEmail,
      name: this.state.subcName,
      phone: this.state.subcPhone,
    };

    const payload = {
      type: "CourseAccessRequestWorkorder",
      description: "Course Access Request Workorder",
      targetOrg: ouid,
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    console.log("url:::::::", url);

    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );

        console.log("response.data.identifier: ", response.data.identifier);

        await store.dispatch(
          showSnackbar("Course Access Request Success", "success")
        );
      })
      .catch(async (err) => {
        store.dispatch(
          await showSnackbar(
            "Course Access Request Failed, email support@xyz.com",
            "error"
          )
        );
        store.dispatch(
          showSnackbar(
            "Course Access Request Failed, email support@xyz.com",
            "error"
          )
        );
      });
  }

  createWorkorder() {
    const path = window.location.pathname.split("/");
    let ouid = 'edwin';
    const { woUrl, credentials } = store.getState().jwt.api;
    const jwt = `${credentials.idToken.jwtToken}`;
    const method = "post";
    const url = `${woUrl}/workorder`;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": ouid,
      },
    };

    const woDetails = {
      courseTitle: store.getState().course.courseDetails.data.title,
      email: this.state.subcEmail,
      name: this.state.subcName,
      phone: this.state.subcPhone,
    };

    const payload = {
      type: "CourseSubscriptionRequestWorkorder",
      description: "Course Subscription Request Workorder",
      targetOrg: ouid,
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    console.log("url:::::::", url);

    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );
        console.log("response.data.identifier: ", response.data.identifier);

        await store.dispatch(
          showSnackbar("Course Subcription Request Success", "success")
        );
      })
      .catch(async (err) => {
        store.dispatch(
          await showSnackbar(
            "Course Subcription Request Failed, email support@xyz.com",
            "error"
          )
        );
        console.log(
          "CourseSubcRequestorkorder failed, Please contact support : ",
          err
        );
      });
  }

  renderCourselinesForAdmin = (courselines, courseId) => {
    return courselines.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "CourseModuleTumbnail"
              : "CourseModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} alt="" />
          </div>
          <div>
            <div>
              <strong>Title : </strong>
              {item.data.title}
            </div>
            <div>
              <strong>SequenceNumber : </strong>
              {item.data.sequenceNumber}
            </div>
            <div>
              <strong>FeaturedImage : </strong>
              {item.data.featuredImage}
            </div>
            <div>
              <strong>CourseVideo : </strong>
              {item.data.courseVideo}
            </div>
          </div>
        </div>
      </>
    ));
  };

  render() {
    const { permissions } = store.getState().permission;

    return (
      <div className="page">
        <Header auth={this.props.auth} />
        <div className="section">
          <div className="container">{this.renderCourse()}</div>
        </div>
        <CommonSnackBar />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_course: (activeOuid, activeOuidChain, id) =>
    dispatch(get_course(activeOuid, activeOuidChain, id)),
  get_course_public: (activeOuid, id) =>
    dispatch(get_course_public(activeOuid, id)),

  get_courselines: (activeOuid, id) =>
    dispatch(get_courselines(activeOuid, id)),
  get_courselines_public: (activeOuid, id) =>
    dispatch(get_courselines_public(activeOuid, id)),
  create_update_courseline: (activeOuid, courseId) =>
    dispatch(create_update_courseline(activeOuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursePublicDetails);
