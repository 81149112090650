import {
  CREATE_CL_QUESTION_REQUESTED,
  CREATE_CL_QUESTION_SUCCESS,
  CREATE_CL_QUESTION_ERRORED,
  GET_CL_QUESTIONS_REQUESTED,
  GET_CL_QUESTIONS_SUCCESS,
  GET_CL_QUESTIONS_ERRORED,
  DELETE_CL_QUESTION_REQUESTED,
  DELETE_CL_QUESTION_SUCCESS,
  DELETE_CL_QUESTION_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_cl_question_requested = () => ({
  type: CREATE_CL_QUESTION_REQUESTED,
});

export const create_cl_question_success = () => ({
  type: CREATE_CL_QUESTION_SUCCESS,
});

export const create_cl_question_errored = (error) => ({
  type: CREATE_CL_QUESTION_ERRORED,
  error,
});

export const create_cl_question = (payload) => async (dispatch) => {
  console.log(`create_course`);

  dispatch(create_cl_question_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`cl_question`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/question`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(
          showSnackbar("Course Line Question created Successfully", "success")
        );
        dispatch(create_cl_question_success());
      })
      .catch((err) => {
        console.log("Question creation Failed");
        dispatch(showSnackbar("Question creation Failed", "error"));
        dispatch(create_cl_question_errored(err));
      });
  }
  return null;
};

export const get_cl_questions_requested = () => ({
  type: GET_CL_QUESTIONS_REQUESTED,
});

export const get_cl_questions_success = (payload) => ({
  type: GET_CL_QUESTIONS_SUCCESS,
  payload,
});

export const get_cl_questions_errored = (error) => ({
  type: GET_CL_QUESTIONS_ERRORED,
  error,
});

export const get_cl_questions = (ouid, courselineId) => async (dispatch) => {
  dispatch(get_cl_questions_requested());
  console.log(`cl_questions`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${coursesApiEndpoint}/question?ouid=${ouid}&courselineId=${courselineId}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": ouid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get  Questions Fetched Successfully");
        dispatch(get_cl_questions_success(response.data));
      })
      .catch((err) => {
        console.log("Get Questions Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get Questions Failed", "error"));
        }
        dispatch(get_cl_questions_errored(err));
      });
  }
  return null;
};

export const delete_cl_question =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_cl_question_requested());
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/question/${id}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Course Line Question Deleted Successfully");
          dispatch(delete_cl_question_success(response.data));
        })
        .catch((err) => {
          console.log("Delete Course Line Question Failed");
          dispatch(showSnackbar("Delete Course Line Question Failed", "error"));
          dispatch(delete_cl_question_errored(err));
        });
    }
    return null;
  };

export const delete_cl_question_requested = () => ({
  type: DELETE_CL_QUESTION_REQUESTED,
});

export const delete_cl_question_success = (payload) => ({
  type: DELETE_CL_QUESTION_SUCCESS,
  payload,
});

export const delete_cl_question_errored = (error) => ({
  type: DELETE_CL_QUESTION_ERRORED,
  error,
});
