import {
    LOGIN_REQUSTED,
    LOGIN_SUCCESS,
    LOGIN_FAILED
  } from '../constant/action-types';

  
//   import configureStore from '../store/configureStore';
  
//   const store = configureStore();
  
  export const login_requested = () => ({
    type: LOGIN_REQUSTED,
  });
  
  export const login_success = () => ({
    type: LOGIN_SUCCESS,
  });

  export const login_failed = () => ({
    type: LOGIN_FAILED,
  });

  

  
  

  

  
  
  
  
  
  
  