import {
  GET_ALL_ORGUSER_REQUESTED,
  GET_ALL_ORGUSER_SUCCESS,
  GET_ALL_ORGUSER_ERRORED,
  GET_ORG_USER_DETAILS_REQUESTED,
  GET_ORG_USER_DETAILS_SUCCESS,
  GET_ORG_USER_DETAILS_ERRORED,
  UPDATE_ORG_USER_REQUESTED,
  UPDATE_ORG_USER_SUCCESS,
  UPDATE_ORG_USER_ERRORED,
  CREATE_ORG_USER_REQUESTED,
  CREATE_ORG_USER_SUCCESS,
  CREATE_ORG_USER_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const get_all_orguesr_requested = () => ({
  type: GET_ALL_ORGUSER_REQUESTED,
});

export const get_all_orguesr_success = (payload) => ({
  type: GET_ALL_ORGUSER_SUCCESS,
  payload,
});

export const get_all_orguesr_errored = (error) => ({
  type: GET_ALL_ORGUSER_ERRORED,
  error,
});

export const get_all_orguesr = (ouid) => async (dispatch) => {
  console.log(`get_all_orguesr`, ouid);

  dispatch(get_all_orguesr_requested());
  console.log(`get_all_orguesr`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url;
    // console.log(`jwt : ${jwt}`);
    const method = "get";
    let axiosHeader = {};
    if (ouid) {
      url = `${apiEndpoint}/orguser`;
      axiosHeader["headers"] = {
        Authorization: jwt,
        "x-ouid": ouid,
      };
    } else {
      url = `${apiEndpoint}/orguser`;
      axiosHeader["headers"] = {
        Authorization: jwt,
      };
    }
    // console.log(`url : ${url}`);
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get all Orguesr Has success");
        dispatch(get_all_orguesr_success(response.data));
      })
      .catch((err) => {
        console.log("get all Orguesr Has Errored");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(get_all_orguesr_errored(err));
      });
  }
  return null;
};

export const get_org_user_details_requested = () => ({
  type: GET_ORG_USER_DETAILS_REQUESTED,
});

export const get_org_user_details_success = (payload) => ({
  type: GET_ORG_USER_DETAILS_SUCCESS,
  payload,
});

export const get_org_user_details_errored = (error) => ({
  type: GET_ORG_USER_DETAILS_ERRORED,
  error,
});

export const get_org_user_details = (username, ouid) => async (dispatch) => {
  dispatch(get_org_user_details_requested());
  console.log(`get_org_user_details`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url;
    // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
    const method = "get";
    let axiosHeader = {};
    if (ouid) {
      url = `${apiEndpoint}/orguser/${username}`;
      axiosHeader["headers"] = {
        Authorization: jwt,
        "x-ouid": ouid,
      };
    } else {
      url = `${apiEndpoint}/orguser/${username}`;
      axiosHeader["headers"] = {
        Authorization: jwt,
      };
    }
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get org user details success");
        dispatch(get_org_user_details_success(response.data));
      })
      .catch((err) => {
        console.log("get org user details Errored");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(get_org_user_details_errored(err));
      });
  }
  return null;
};

export const update_org_user_profile_requested = () => ({
  type: UPDATE_ORG_USER_REQUESTED,
});

export const update_org_user_profile_success = () => ({
  type: UPDATE_ORG_USER_SUCCESS,
});

export const update_org_user_profile_errored = (error) => ({
  type: UPDATE_ORG_USER_ERRORED,
  error,
});

export const update_org_user_profile =
  (payload, username, ouid) => async (dispatch) => {
    dispatch(update_org_user_profile_requested());
    console.log(`update_org_user_profile`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/orguser/${username}`;
      // console.log(`jwt : ${jwt}`);
      //   console.log(`url : ${url}`);
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Create User Profile Has success");
          dispatch(showSnackbar("User Updated Successfully", "success"));
          dispatch(update_org_user_profile_success(response.data));
        })
        .catch((err) => {
          console.log("Create User Profile Has Errored");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("User Upadation Failed", "error"));
          dispatch(update_org_user_profile_errored(err));
        });
    }
    return null;
  };

export const create_org_user_requested = () => ({
  type: CREATE_ORG_USER_REQUESTED,
});

export const create_org_user_success = () => ({
  type: CREATE_ORG_USER_SUCCESS,
});

export const create_org_user_errored = (error) => ({
  type: CREATE_ORG_USER_ERRORED,
  error,
});

export const create_org_user = (payload, ouid) => async (dispatch) => {
  dispatch(create_org_user_requested());
  console.log(`create_org_user`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/orguser`;
    // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": ouid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("Create Org User Has success");
        dispatch(showSnackbar("Org User created Successfully", "success"));
        dispatch(create_org_user_success(response.data));
      })
      .catch((err) => {
        console.log("Create Org User Has Errored");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Org User Creation Failed", "error"));
        dispatch(create_org_user_errored(err));
      });
  }
  return null;
};
