import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StarIcon from '@material-ui/icons/Star';
import configureStore from '../../../store/configureStore';
import Loader from '../../common/Loader';
import config from "../../../config";
import { update_user_profile } from "../../../actions/action-userProfiles";

const { payemntSecretName ,razorpayId } = config;

const store = configureStore();

function loadRazorPay() {
  console.log("loadRazorPay");
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'
    document.body.appendChild(script)
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
  })
}

export default function PaymentPage(props) {
  const { userDetails, packageDetails, handleClose } = props;

  const [paymentOrder, setPaymentOrder] = useState();
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    placeOrder();
  }, []);

  const placeOrder = () => {
    console.log("placeOrder ::::::::::::::::::::::");
    const {
      apiEndpoint,
      credentials
    } = store.getState().jwt.api;
    const method = 'post';
    const url = `${apiEndpoint}/paymentorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        // 'x-ouid': activeOuid
      },
    };
    const payload = {
      "orderData": {
        "amount": packageDetails.amount,
        "currency": packageDetails.currency,
        "receipt": "rcptid_11"
      },
      "provider": payemntSecretName,
      "type": "external"
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    ).then(async (response) => {
      console.log("Payment response : ", JSON.stringify(response.data));
      setPaymentOrder(response.data)
    })
      .catch((err) => {
        console.log("Payment failed : ", err);
      });
  }



  const showRazorPay = async () => {
    handleClose()
    const res = await loadRazorPay()
    console.log("res", res);
    if (!res) {
      alert("Payemet Failed")
      return
    }
    const options = {
      "key": razorpayId, // Enter the Key ID generated from the Dashboard
      "amount": packageDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": packageDetails.currency,
      "name": packageDetails.name,
      "description": packageDetails.description,
      "image": "https://ibcaamshe.com/wp-content/uploads/2021/11/cropped-webicon.png",
      "order_id": `${paymentOrder.id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response) {
        paymentSucess(response);
      },
      "prefill": {
        "name": userDetails.data.name,
        "email": userDetails.data.email
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', function (response){
      alert("failed");
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    })
  }

  const paymentSucess = async (response) => {
    console.log("success");
    // alert("Payement Success");
    // alert(response.razorpay_payment_id);
    // alert(response.razorpay_order_id);
    // alert(response.razorpay_signature)
    let newUserDetails = userDetails; 
    newUserDetails.data.subscriptionStatus = "subscribed";
    newUserDetails.data.paymentDetails = response;
    newUserDetails.data.bizRegistrationStatus = "inprogress";
    console.log("newUserDetails :: ", newUserDetails);
    await store.dispatch(update_user_profile(newUserDetails, newUserDetails.data.username)); 
    // handleClose();
    window.location.reload();
  }
  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }

  return (
    <div className="PaymentPopUpWrapper">
      {
        paymentOrder ?
          <div>
            <div className="PaymentPopUpIcon">
              <StarIcon style={{ fontSize: 60, color: '#fff' }} />
            </div>
            <div className="PaymentPopUpTitle">
              IBC Life Long Membership
            </div>
            <div className="PaymentPopUpDes">Become a member of the club to grow you business to next level. After you pay the membership fee register your business and start scaling.</div>
            <div className="PaymentPopUpEyebrow">One Time</div>
            <div className="price">Rs 10,000/- + GST</div>
            <div className="PaymentPopUpTerms">
              <input type="checkbox" id="agree" onChange={checkboxHandler} />
              <label htmlFor="agree"> I agree to <a href="#">terms and conditions</a></label>
            </div>
            <button onClick={showRazorPay}>
              Pay Now
            </button>
          </div>
          :
          <Loader />
      }

      {/* {showUploadLoader ?
      <Loader />
      : ""} */}
    </div>
  );
}