import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_examinstance,
  start_examinstance,
} from "../../../actions/action-examinstance";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";

import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const store = configureStore();

class ExamPre extends Component {
  constructor() {
    super();
    this.state = {
      examinstanceDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_examinstance } = this.props;
    await get_examinstance(activeOuid, activeOuidChain, id);
    console.log("fetched examinstance: ", store.getState().examinstance);
    if (
      store.getState().examinstance.examinstanceDetails.status ===
        "completed" &&
      store.getState().examinstance.examinstanceDetails.data.examData
        .examAllowRetakes !== "true"
    ) {
      history.push(`/${activeOuid}/myexams`);
    }
    this.setState({
      examinstanceDetails: store.getState().examinstance.examinstanceDetails,
    });
  }

  async startExam() {
    const { activeOuid } = store.getState().permission;
    const { start_examinstance } = this.props;
    console.log("start_examinstance");
    const examinstanceid = this.state.examinstanceDetails.data.identifier;
    console.log("examinstanceid: ", examinstanceid);
    await start_examinstance(activeOuid, examinstanceid);
    history.push(`/${activeOuid}/myexam/${examinstanceid}`);
  }

  renderExamPreText() {
    const { showGlobalLoader } = store.getState().examinstance;
    console.log(
      "store.getState().examinstance.examinstanceDetails : ",
      store.getState().examinstance.examinstanceDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="ExamGrid">
            <Paper className="ExamModuleWrapper">
              <div className="ExamDetailsWrapper">
                <div className="">
                  {store.getState().examinstance.examinstanceDetails &&
                  store.getState().examinstance.examinstanceDetails.data ? (
                    <>
                      <p>
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examData.preExamText
                        }
                      </p>
                      <div>
                        <Button
                          onClick={() => {
                            this.startExam();
                          }}
                          color="primary"
                          variant="contained"
                        >
                          Start Exam
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Paper>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="section">{this.renderExamPreText()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  examinstance: state.examinstance,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_examinstance: (activeOuid, activeOuidChain, id) =>
    dispatch(get_examinstance(activeOuid, activeOuidChain, id)),
  start_examinstance: (activeOuid, id) =>
    dispatch(start_examinstance(activeOuid, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamPre);
