import React from 'react';
import ActionCard from './ActionCard';
import PackageCard from './PackageCard';
import { makeStyles } from '@material-ui/core/styles';
import RegisterForm from './RegisterForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

export default function UnSubscribedUser(props) {
  const { userDetails } = props;
  const packageDetails = { "heading": "Life Long Membership", "name": "IBC", "description": "Life Long Membership", "currency": "INR", "amount": 1180000, "price": "₹ 11,800/-", "details": "Become a member to the club of enterpernures and investor", "tag": "One Time" }
  const actionCards = [{ "heading": "Get Investment", "color": "#ffad2b" }, { "heading": "Get Loan", "color": "#1693dc" }, { "heading": "Govt. Schemes", "color": "#251c71" }];

  return (
    <div> 
      <div className="container--narrow">
        <PackageCard
          packageDetails={packageDetails}
          userDetails={userDetails}
        />
      </div>
      {/* <section className="businessServicesSection">
        <h3>Services</h3>
        <div className="businessServicesWrapper">
          {
            actionCards.map((actionCard) => (
              <ActionCard
                packageDetails={packageDetails}
                details={actionCard}
                userDetails={userDetails}
              />
            ))
          }
        </div>
      </section> */}
    </div>

  );
}