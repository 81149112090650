import React, { Component } from 'react';
import { connect } from 'react-redux';
import { create_user_profile } from "../../../actions/action-userProfiles";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import Validate from "../../utility/FormValidation";

const store = configureStore();


class CreateUser extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {
                "name": "",
                "username": "",
                "email": "",
                "phone_number": "",
                "password": "",
                "confirmpassword": ""
            },
            errors: {}
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }


    handleSubmit = async event => {
        // event.preventDefault();
        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        } else {
            // AWS Cognito integration here
            this.clearErrorState();
            const { name, username, email, phone_number, password } = this.state.userDetails;
            try {
                const signUpResponse = await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email: email,
                        phone_number: phone_number,
                        name: name
                    }
                });
                console.log("sign Up Response : ", signUpResponse);
                let userProfile = this.state.userDetails;
                delete userProfile["password"];
                delete userProfile["confirmpassword"];
                const { create_user_profile } = this.props;
                await create_user_profile(userProfile);
                const { activeOuid } = store.getState().permission;
                this.props.history.push(`/${activeOuid}/users`);
            } catch (error) {
                let err = null;
                !error.message ? err = { "message": error } : err = error;
                this.setState({
                    errors: {
                        ...this.state.errors,
                        cognito: err
                    }
                });
            }
        }


    }

    onInputChange = event => {
        const newUserDetails = this.state.userDetails;
        newUserDetails[event.target.id] = event.target.value;
        this.setState({
            userDetails: newUserDetails
        });
    }

    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        <div className="formContainer">
                            <FormErrors formerrors={this.state.errors} />
                            <form>
                                <h1 className="formName">User Details</h1>
                                <div className="form-item-wrapper">
                                    <label className="input_label">Full Name</label>
                                    <Input
                                        className="input"
                                        id="name"
                                        placeholder="Full Name"
                                        value={this.state.userDetails.name}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <label className="input_label">User Name</label>
                                    <Input
                                        className="input"
                                        id="username"
                                        placeholder="User Name"
                                        value={this.state.userDetails.username}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <label className="input_label">Email</label>
                                    <Input
                                        className="input"
                                        placeholder="Email"
                                        id="email"
                                        value={this.state.userDetails.email}
                                        onChange={this.onInputChange} />
                                </div>
                                <div className="form-item-wrapper">
                                    <label className="input_label">Phone Number</label>
                                    <Input
                                        className="input"
                                        id="phone_number"
                                        placeholder="Phone Number"
                                        value={this.state.userDetails.phone_number}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <label className="input_label">Password</label>
                                    <Input
                                        type="password"
                                        className="input"
                                        placeholder="Password"
                                        id="password"
                                        value={this.state.userDetails.password}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <label className="input_label">Confirm Password</label>
                                    <Input
                                        type="password"
                                        className="input"
                                        placeholder="Confirm Password"
                                        id="confirmpassword"
                                        value={this.state.userDetails.confirmpassword}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </form>
                            <div className="field">
                                <p className="control">
                                    <FormButton
                                        onClick={() => this.handleSubmit()}
                                        text="Add"
                                    />
                                </p>
                            </div>
                            <CommonSnackBar />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});


const mapDispatchToProps = (dispatch) => ({
    create_user_profile: (payload) => dispatch(create_user_profile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
