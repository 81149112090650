import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Orgunit } from "@material-ui/icons";
import { history } from "../../../reducers";
import Button from "@material-ui/core/Button";
import { RedirectFunc } from "../../common/CommonFunctions";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import configureStore from "../../../store/configureStore";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Loader from "../../common/Loader";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    minWidth: 250,
    height: "100%",
  },
  media: {
    height: 140,
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
  },
});

export default function MyExamCard(props) {
  console.log("props.Posts ", props.Post);

  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const examuser = props.Post;
  const data = examuser.data;
  const { activeOuid } = store.getState().permission;
  const { showGlobalLoader } = store.getState().examuser;

  return (
    <div className="">
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <>
          {data && (
            <Card
              className={classes.root}
              onClick={() => {
                console.log("examuser.status", examuser.status);
                if (examuser.status === "completed") {
                  history.push(
                    `/${activeOuid}/myexam/${data.examinstanceid}/result`
                  );
                  return;
                }
                if (examuser.status === "evaluated") {
                  // show evaluation results page
                  return;
                }

                var fiveMinutesLater = Date.parse(
                  new Date().toISOString().slice(0, 16)
                );
                console.log(
                  "data.examStartTime",
                  Date.parse(data.examStartTime)
                );
                let examStartTime = Date.parse(data.examStartTime);
                console.log("fiveMinutesLater", fiveMinutesLater);

                fiveMinutesLater = fiveMinutesLater + 300000; // 5mins in milliseconds
                console.log("fiveMinutesLater", fiveMinutesLater);

                if (examStartTime < fiveMinutesLater) {
                  history.push(`/${activeOuid}/preexam/${data.examinstanceid}`);
                } else {
                  history.push(`/${activeOuid}/preexam/${data.examinstanceid}`);
                  //alert("Not yet scheduled date time");
                }
              }}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={data.examData.featuredImage}
                  title=""
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.title}
                  >
                    {examuser.data.exam}
                  </Typography>

                  {(examuser.status === "scheduled" ||
                    examuser.status === "started") && (
                    <>
                      <Typography gutterBottom variant="body1" component="p">
                        {examuser.status}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="caption"
                        color="textSecondary"
                        component="p"
                      >
                        Scheduled Time : {data.examStartTime}
                      </Typography>
                    </>
                  )}
                  {examuser.status === "completed" && (
                    <Typography
                      gutterBottom
                      variant="caption"
                      color="textSecondary"
                      component="p"
                    >
                      completed
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.examData.summary.substring(0, 110)}...
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CommonSnackBar />
            </Card>
          )}
        </>
      )}
    </div>
  );
}
