import {
  CREATE_COURSEUSER_REQUESTED,
  CREATE_COURSEUSER_SUCCESS,
  CREATE_COURSEUSER_ERRORED,
  GET_COURSEUSERS_REQUESTED,
  GET_COURSEUSERS_SUCCESS,
  GET_COURSEUSERS_ERRORED,
  GET_COURSEUSER_REQUESTED,
  GET_COURSEUSER_SUCCESS,
  GET_COURSEUSER_ERRORED,
  DELETE_COURSEUSER_REQUESTED,
  DELETE_COURSEUSER_SUCCESS,
  DELETE_COURSEUSER_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  courseUsers: [],
  courseUser: {},
  error: {},
};

export function courseuser(state = defaultState, action) {
  switch (action.type) {
    case GET_COURSEUSERS_REQUESTED:
      console.log("GET_COURSEUSERS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_COURSEUSERS_SUCCESS:
      console.log("GET_COURSEUSERS_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        courseUsers: action.payload.items,
      };

    case GET_COURSEUSERS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_COURSEUSER_REQUESTED:
      console.log("CREATE_COURSEUSER_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_COURSEUSER_SUCCESS:
      console.log("CREATE_COURSEUSER_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_COURSEUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case GET_COURSEUSER_REQUESTED:
      console.log("GET_COURSEUSER_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        courseUser: {},
      };

    case GET_COURSEUSER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        courseUser: action.payload,
      };

    case GET_COURSEUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case DELETE_COURSEUSER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_COURSEUSER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_COURSEUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
}
