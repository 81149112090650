import React, { Component } from 'react';

export default class ThankYouPage extends Component {

  state = {
    channelPartner: {}
  }

  fucntionName = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      
    } catch (err) {

    }
  }

  componentDidMount = () => {
  }

  render() {
    return (
        <section className="section">
          <div className="container">
            <h1>Thank You For Registering</h1>
          </div>
        </section>
    )
  }
}
