import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  Input: {
    fontSize: 14,
  },
  DialogText: {
    fontSize: 16,
  },
  CommentInputWrapper: {
    borderColor: '#dc281e',
    '& .MuiInput-underline': {
      '&:after': {
        borderColor: '#3b4f5a',
      },
    },
  },
  PrimaryButtonroot: {
    background: '#DC281E',
    padding: '5px 15px',
    fontSize: 12,
    '&:hover': {
      background: '#DC281E',
    },
  },
  SecondaryButtonroot: {
    borderColor: '#DC281E',
    marginRight: 15,
    fontSize: 12,
    padding: '5px 15px',
    color: '#DC281E',
    '&:hover': {
      borderColor: '#DC281E',
    },
  },
});

function CommonConfirm(props) {
  const classes = useStyles();
  const { confirmBox, closeConfirmBox } = props;
//   console.log('confirmBox : ', confirmBox);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(confirmBox);
  }, [confirmBox]);
  const handleClose = () => {
    setOpen(false);
    return closeConfirmBox(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    return closeConfirmBox(true);
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogContent>
        <DialogContentText className={classes.DialogText}>
          Are you sure you want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          classes={{
            root: classes.SecondaryButtonroot,
            label: classes.SecondaryButtonrlabel,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          classes={{
            root: classes.PrimaryButtonroot,
            label: classes.PrimaryButtonrlabel,
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonConfirm);

