import React, { Component } from "react";
import Header from "../layout/Header";
import banner from "../assets/images/bannerimg.png";
import SidebarWrapper from "../layout/SidebarWrapper";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { history } from "../../reducers";
import Footer from "../layout/Footer";
import configureStore from "../../store/configureStore";
import CommonSnackBar from "../common/CommonSnackBar";
import Loader from "../common/Loader";
import { get_courses_public } from "../../actions/action-course";
import { setActiveOuid } from "../../actions/action-permissions";
import CourseCardPublic from "../pages/Post/CourseCardPublic";
import TextField from "@material-ui/core/TextField";
import { showSnackbar } from "../../actions/action-snackbar";
import axios from "axios";

// Images
import homeBanner from "../assets/images/edwinImages/Bannerimage.png";
import vision from "../assets/images/edwinImages/vision.png";
import mission from "../assets/images/edwinImages/mission.png";

const store = configureStore();
class Home extends Component {
  constructor() {
    super();
    this.state = {
      coursesToShow: [],
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    let activeOuid = "ces";
    let activeOuidChain = ["system", "ces"];
    const { setActiveOuid } = this.props;
    await setActiveOuid("ces");

    this.setState({
      coursesToShow: [],
    });
    const { get_courses_public } = this.props;
    await get_courses_public(activeOuid, activeOuidChain, "", activeOuid);

    this.setState({
      coursesToShow: store.getState().course.courses,
    });
  }

  renderPosts(PostList) {
    console.log("PostList : ", JSON.stringify(PostList));
    return PostList.map((item) => (
      <CourseCardPublic key={item.title} Post={item} />
    ));
  }
  onInputChange = (event) => {
    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  createOpenWorkorder() {
    const path = window.location.pathname.split("/");
    let ouid = path[1];
    const { woUrl } = store.getState().jwt.api;
    const method = "post";
    const url = `${woUrl}/workorder-public`;
    const axiosHeader = {
      headers: {
        "x-ouid": "ces",
      },
    };

    const enqDetails = {
      email: this.state.Email,
      name: this.state.Name,
      phone: this.state.Phone,
      message: this.state.Message,
    };

    const payload = {
      type: "ContactRequestWorkorder",
      description: "Contact form",
      targetOrg: ouid,
      requestId: "",
      deadline: "",
      details: enqDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    console.log("url:::::::", url);

    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );

        console.log("response.data.identifier: ", response.data.identifier);

        await store.dispatch(
          showSnackbar("You message had been send Successfully", "success")
        );
      })
      .catch(async (err) => {
        store.dispatch(
          await showSnackbar(
            "Enquire form submission Failed, email support@xyz.com",
            "error"
          )
        );
        store.dispatch(
          showSnackbar(
            "Course Access Request Failed, email support@xyz.com",
            "error"
          )
        );
      });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().course;
    return (
      <div className="Homepage">
        <Header auth={this.props.auth} />
        <section className="hero is-primary homePageBanner">
          <div className="hero-body">
            <div className="container">
              <div className="split reverse">
                <div>
                  <h1>Focus and give your best</h1>
                  <p>
                    Trust yourself, you know more than you think you do –
                    Benjamin Spock
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Get started
                  </Button>
                </div>
                <div>
                  <img src={homeBanner} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <CommonSnackBar />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),

  get_courses_public: (activeOuid, activeOuidChain, category, ouid) =>
    dispatch(get_courses_public(activeOuid, activeOuidChain, category, ouid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
