import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_exam,
  get_examlines,
  create_update_examline,
  update_exam,
} from "../../../actions/action-exam";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import Player from "../Vod/Player";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import QuestionCardAdmin from "./QuestionCardAdmin";
import FormErrors from "../../FormErrors";
import FormButton from "../../common/FormButton";
import Select from "react-select";

const store = configureStore();

class ExamDetails extends Component {
  constructor() {
    super();
    this.state = {
      examDetails: {},
      signedUrl: "",
      examlines: [],

      newquestionQuestion: "",
      newquestionSequenceNumber: "",
      newquestionFeaturedImage: "",

      newquestionAnswer1: "",
      newquestionAnswer1Marks: 0,

      newquestionAnswer2: "",
      newquestionAnswer2Marks: 0,
      newquestionAnswer3: "",
      newquestionAnswer3Marks: 0,
      newquestionAnswer4: "",
      newquestionAnswer4Marks: 0,

      currentQuestion: "",
      currentQuestionSequenceNumber: "",

      examTitle: "",
      examSummary: "",
      examFeaturedImage: "",
      examStatus: "",
      examSequenceNumber: "",
      examPassMarks: "",
      examMaxMarks: "",
      examAllowRetakes: "false",
      preExamText: "",
      postExamText: "",
      examDurationMinutes: "",
      addQuestionVisibility: false,
      editExamVisibility: false,
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.fetchPublicDetails();
    } else {
      this.fetchDetails();
    }
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    const { activeOuid, activeOuidChain, userRoles } =
      store.getState().permission;

    const { get_exam, get_examlines } = this.props;
    await get_exam(activeOuid, activeOuidChain, id);
    await get_examlines(activeOuid, id);
    console.log("fetched exam details: ", store.getState().exam);
    this.setState({
      examDetails: store.getState().exam.examDetails,
      examlines: store.getState().exam.examlines,
    });
    if (this.state.examDetails.data) {
      //redundant find a better way
      this.setState({
        examTitle: this.state.examDetails.data.title,
        examSummary: this.state.examDetails.data.summary,
        examStatus: this.state.examDetails.data.status,
        examPassMarks: this.state.examDetails.data.passMarks,
        examMaxMarks: this.state.examDetails.data.maxMarks,
        examFeaturedImage: this.state.examDetails.data.featuredImage,
        preExamText: this.state.examDetails.data.preExamText,
        postExamText: this.state.examDetails.data.postExamText,
        examDurationMinutes: this.state.examDetails.data.examDurationMinutes,
        examAllowRetakes: this.state.examDetails.data.examAllowRetakes,
      });
    }
    if (this.state.examlines.length > 0) {
      this.state.examlines.forEach((element) => {
        console.log("element.data.sequenceNumber", element.data.sequenceNumber);
        if (element.data.sequenceNumber === "1") {
          element["currentSelection"] = "true";
          /**
          this.setState({
            currentQuestionSequenceNumber: element.data.sequenceNumber,
            currentSummary: element.data.summary,
            currentQuestion: element.data.question,
            featuredImage: element.data.featuredImage,
          });*/
        }
      });
    }
  }

  async addQuestion() {
    const { activeOuid } = store.getState().permission;
    console.log("Adding a Question");
    const examId = store.getState().exam.examDetails.data.identifier;
    console.log("examId ", examId);

    let payload = {
      examId: examId,
      question: this.state.newquestionQuestion,
      featuredImage: this.state.newquestionFeaturedImage,
      sequenceNumber: this.state.newquestionSequenceNumber,
      answer1: this.state.newquestionAnswer1,
      answer1Marks: this.state.newquestionAnswer1Marks,
      answer2: this.state.newquestionAnswer2,
      answer2Marks: this.state.newquestionAnswer2Marks,
      answer3: this.state.newquestionAnswer3,
      answer3Marks: this.state.newquestionAnswer3Marks,
      answer4: this.state.newquestionAnswer4,
      answer4Marks: this.state.newquestionAnswer4Marks,
    };
    console.log("add a q payload", payload);
    await store.dispatch(create_update_examline("", activeOuid, payload, ""));
    await store.dispatch(get_examlines(activeOuid, examId));
    this.setAddQuestionVisibility(false);
  }

  onInputChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);

    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
    console.log("state", this.state);
  };

  handleAllowRetakesChange = (option) => {
    console.log(option.value);
    this.setState({ ...this.state, examAllowRetakes: option.value });
  };

  setAddQuestionVisibility(visibility) {
    console.log("addQuestionVisibility", this.state.addQuestionVisibility);
    this.setState({
      addQuestionVisibility: visibility,
    });
  }

  setEditExamVisibility(visibility) {
    console.log("editExamVisibility", this.state.editExamVisibility);
    this.setState({
      editExamVisibility: visibility,
    });
  }

  async handleSave() {
    // Put api call for exam edit
    const { activeOuid } = store.getState().permission;
    console.log("Edit Exam");
    const examId = store.getState().exam.examDetails.data.identifier;
    console.log("examId ", examId);

    let payload = {
      identifier: examId,
      title: this.state.examTitle,
      summary: this.state.examSummary,
      preExamText: this.state.preExamText,
      postExamText: this.state.postExamText,
      featuredImage: this.state.examFeaturedImage,
      status: this.state.examStatus,
      maxMarks: this.state.examMaxMarks,
      passMarks: this.state.examPassMarks,
      examDurationMinutes: this.state.examDurationMinutes,
      examAllowRetakes: this.state.examAllowRetakes,
    };

    await store.dispatch(update_exam(examId, payload, activeOuid));
    console.log("saved payload", payload);
    this.setEditExamVisibility(!this.state.editExamVisibility);
    this.fetchDetails();
  }

  renderExamEditForm(exameditdetails) {
    return (
      <div className="formContainer">
        <FormErrors formerrors={this.state.errors} />
        <form>
          <h1 className="formName">Edit Exam</h1>

          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examTitle"
              placeholder="Title"
              defaultValue={exameditdetails.title}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examSummary"
              placeholder="Summary"
              defaultValue={exameditdetails.summary}
              onChange={this.onInputChange}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examFeaturedImage"
              placeholder="Featured Image"
              defaultValue={exameditdetails.featuredImage}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examMaxMarks"
              placeholder="Max Marks"
              defaultValue={exameditdetails.maxMarks}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
              type="number"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examPassMarks"
              placeholder="Exam Pass Marks"
              defaultValue={exameditdetails.passMarks}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
              type="number"
            />
          </div>
          <div className="form-item-wrapper">
            Allow Retakes
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={exameditdetails.examAllowRetakes}
              id="examAllowRetakes"
              options={[
                { value: "true", label: "true" },
                { value: "false", label: "false" },
              ]}
              onChange={(opt) => this.handleAllowRetakesChange(opt)}
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examDurationMinutes"
              placeholder="Exam Duration In Minutes"
              defaultValue={exameditdetails.examDurationMinutes}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
              type="number"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="examStatus"
              placeholder="Status"
              defaultValue={exameditdetails.status}
              onChange={this.onInputChange}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="preExamText"
              placeholder="Pre Exam Text"
              defaultValue={exameditdetails.preExamText}
              onChange={this.onInputChange}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="postExamText"
              placeholder="Post Exam Text"
              defaultValue={exameditdetails.postExamText}
              onChange={this.onInputChange}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          </div>
        </form>
        <div className="field">
          <p className="control">
            <FormButton onClick={() => this.handleSave()} text="Save" />
          </p>
        </div>
        <CommonSnackBar />
      </div>
    );
  }
  renderExam() {
    const { showGlobalLoader, showGlobalLoaderExamline } =
      store.getState().exam;
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("activeOuidType in renderExam : ", activeOuidType);

    if (
      activeOuidType === "ROOT" &&
      (policies.includes(`${userRoles[0].ouid}=admin`) ||
        policies.includes(`system=admin`))
    ) {
      console.log("showGlobalLoader : ", showGlobalLoader);
      console.log(
        "store.getState().exam.examDetails : ",
        store.getState().exam.examDetails
      );
      return (
        <div>
          <Paper component="div" className="paper">
            <div className="listHeader">
              <h1>Exam Details</h1>
              <div>
                <IconButton
                  color="primary"
                  onClick={() =>
                    this.setEditExamVisibility(!this.state.editExamVisibility)
                  }
                >
                  <EditIcon />
                </IconButton>
                <CommonDelete id={id} type="Exam" />
              </div>
            </div>

            {showGlobalLoader ? (
              <Loader />
            ) : store.getState().exam.examDetails &&
              store.getState().exam.examDetails.data ? (
              <div>
                {this.renderDetails(store.getState().exam.examDetails.data)}
              </div>
            ) : (
              "No Exam Details Found"
            )}

            {this.state.editExamVisibility &&
              this.renderExamEditForm(store.getState().exam.examDetails.data)}
            <div className="listHeader">
              <div>
                <h2>
                  Questions{" "}
                  <Button
                    onClick={() =>
                      this.setAddQuestionVisibility(
                        !this.state.addQuestionVisibility
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    Add a Question
                  </Button>
                </h2>

                {this.state.addQuestionVisibility && (
                  <div className="examAddQuestionsForm">
                    <h2>Add a new Question With Answers</h2>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionQuestion"
                        placeholder="Question"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                      />
                    </div>

                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionSequenceNumber"
                        placeholder="new question sequence number"
                        onChange={this.onInputChange}
                        fullWidth
                        variant="outlined"
                        type="number"
                      />
                    </div>

                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer1"
                        placeholder="Answer Option 1"
                        onChange={this.onInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer1Marks"
                        placeholder="Answer 1 Marks"
                        label="Answer 1 Marks"
                        onChange={this.onInputChange}
                        variant="outlined"
                        type="number"
                        defaultValue={0}
                      />
                    </div>

                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer2"
                        placeholder="Answer Option 2"
                        onChange={this.onInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer2Marks"
                        label="Answer 2 Marks"
                        onChange={this.onInputChange}
                        defaultValue={0}
                        variant="outlined"
                        type="number"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer3"
                        placeholder="Answer Option 3"
                        onChange={this.onInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer3Marks"
                        label="Answer 3 Marks"
                        onChange={this.onInputChange}
                        defaultValue={0}
                        variant="outlined"
                        type="number"
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer4"
                        placeholder="Answer Option 4"
                        onChange={this.onInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="form-item-wrapper">
                      <TextField
                        className="input"
                        id="newquestionAnswer4Marks"
                        label="Answer 4 Marks"
                        onChange={this.onInputChange}
                        defaultValue={0}
                        variant="outlined"
                        type="number"
                      />
                    </div>
                    <Button
                      onClick={() => {
                        this.addQuestion();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Add
                    </Button>
                  </div>
                )}
                <div>
                  {showGlobalLoaderExamline ? (
                    <Loader />
                  ) : store.getState().exam.examlines.length !== 0 &&
                    store.getState().exam.examDetails.data ? (
                    <div>
                      {this.renderExamlinesForAdmin(
                        store.getState().exam.examlines,
                        store.getState().exam.examDetails.data.identifier
                      )}
                    </div>
                  ) : (
                    "No Exam Questions added yet....."
                  )}
                </div>
              </div>
            </div>
          </Paper>
          <CommonSnackBar />
        </div>
      );
    } else {
      console.log("return for non root : ");
      console.log(
        "store.getState().exam.examDetails : ",
        store.getState().exam.examDetails
      );

      return (
        <div className="page">
          <div className="section">
            <div className="container">
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <div className="ExamGrid">
                  <div className="ExamVideoWrapper">
                    <div className="currentVideo">
                      {showGlobalLoaderExamline ? (
                        <Loader />
                      ) : store.getState().exam.examlines.length !== 0 ? (
                        <div>
                          <div>
                            Question: {this.state.currentQuestionSequenceNumber}
                          </div>
                          <div>{this.state.currentQuestion}</div>
                        </div>
                      ) : (
                        "No Exam Questions Added Yet....."
                      )}
                    </div>
                    <div className="ExamDetailsWrapper">
                      <div className="">
                        {store.getState().exam.examDetails &&
                        store.getState().exam.examDetails.data ? (
                          <h1>
                            {store.getState().exam.examDetails.data.title}
                          </h1>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="">
                        {store.getState().exam.examDetails &&
                        store.getState().exam.examDetails.data ? (
                          <>{store.getState().exam.examDetails.data.summary}</>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <Paper className="ExamModuleWrapper">
                    {showGlobalLoaderExamline || showGlobalLoader ? (
                      <Loader />
                    ) : store.getState().exam.examlines.length !== 0 ? (
                      <div>
                        {this.renderExamlines(
                          store.getState().exam.examlines,
                          store.getState().exam.examDetails.data.identifier
                        )}
                      </div>
                    ) : (
                      "No Exam Questions added yet....."
                    )}
                  </Paper>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">{keyName}</div>
          {keyName === "lastModifiedDate" || keyName === "createdDate" ? (
            <div className="detailsValue">{timeFormat(details[keyName])}</div>
          ) : (
            <div className="detailsValue">{details[keyName]}</div>
          )}
        </div>
      );
    });
  }

  renderExamlines = (examlines, examId) => {
    const { activeOuid } = store.getState().permission;
    return examlines.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "ExamModuleTumbnail Active"
              : "ExamModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            this.state.examlines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentQuestionSequenceNumber: `${item.data.sequenceNumber}`,
              currentQuestion: `${item.data.question}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div className="ExamModuleQuestion">{item.data.question}</div>
            {/* <div>Question: {item.data.sequenceNumber}</div> */}
          </div>
        </div>
      </>
    ));
  };

  renderExamlinesForAdmin = (examlines, examId) => {
    const { activeOuid } = store.getState().permission;
    const examlinesSorted = examlines.sort(
      (a, b) => a.data.sequenceNumber - b.data.sequenceNumber
    );

    return examlinesSorted.map((item, index) => (
      <QuestionCardAdmin item={item} examId={examId} />
    ));
  };

  render() {
    const { showGlobalLoader } = store.getState().exam;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];

    return (
      <div className="page">
        {permissions ? (
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        ) : (
          ""
        )}
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <div className="section">
          <div className="container">{this.renderExam()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  exam: state.exam,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_exam: (activeOuid, activeOuidChain, id) =>
    dispatch(get_exam(activeOuid, activeOuidChain, id)),

  get_examlines: (activeOuid, id) => dispatch(get_examlines(activeOuid, id)),
  create_update_examline: (activeOuid, examId) =>
    dispatch(create_update_examline(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetails);
