import {
  CREATE_EXAM_REQUESTED,
  CREATE_EXAM_SUCCESS,
  CREATE_EXAM_ERRORED,
  UPDATE_EXAM_REQUESTED,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_ERRORED,
  GET_EXAMS_REQUESTED,
  GET_EXAMS_SUCCESS,
  GET_EXAMS_ERRORED,
  GET_EXAM_REQUESTED,
  GET_EXAM_SUCCESS,
  GET_EXAM_ERRORED,
  DELETE_EXAM_REQUESTED,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_ERRORED,
  CREATE_OR_UPDATE_EXAMLINE_REQUESTED,
  CREATE_OR_UPDATE_EXAMLINE_SUCCESS,
  CREATE_OR_UPDATE_EXAMLINE_ERRORED,
  GET_EXAMLINES_REQUESTED,
  GET_EXAMLINES_SUCCESS,
  GET_EXAMLINES_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  exams: [],
  showGlobalLoaderExamline: true,
  examlines: [],
  examlineDetails: {},
  examDetails: {},
  error: {},
};

export function exam(state = defaultState, action) {
  switch (action.type) {
    case GET_EXAMS_REQUESTED:
      console.log("GET_EXAMS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_EXAMS_SUCCESS:
      console.log("GET_EXAMS_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        exams: action.payload.items,
      };

    case GET_EXAMS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_EXAM_REQUESTED:
      console.log("CREATE_EXAM_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_EXAM_SUCCESS:
      console.log("CREATE_EXAM_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_EXAM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case UPDATE_EXAM_REQUESTED:
      console.log("UPDATE_EXAM_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_EXAM_SUCCESS:
      console.log("UPDATE_EXAM_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_EXAM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case GET_EXAM_REQUESTED:
      console.log("GET_EXAM_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        examDetails: {},
      };

    case GET_EXAM_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        examDetails: action.payload,
      };

    case GET_EXAM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case DELETE_EXAM_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_EXAM_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_EXAM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_OR_UPDATE_EXAMLINE_REQUESTED:
      console.log("CREATE_OR_UPDATE_EXAMLINE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoaderExamline: true,
      };

    case CREATE_OR_UPDATE_EXAMLINE_SUCCESS:
      console.log("CREATE_OR_UPDATE_EXAMLINE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoaderExamline: false,
      };

    case CREATE_OR_UPDATE_EXAMLINE_ERRORED:
      return {
        ...state,
        showGlobalLoaderExamline: false,
        error: action.error,
      };

    case GET_EXAMLINES_REQUESTED:
      return {
        ...state,
        showGlobalLoaderExamline: true,
        examlines: [],
      };

    case GET_EXAMLINES_SUCCESS:
      return {
        ...state,
        showGlobalLoaderExamline: false,
        examlines: action.payload.items,
      };

    case GET_EXAMLINES_ERRORED:
      return {
        ...state,
        showGlobalLoaderExamline: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}
