import {
  CREATE_EVENT_REQUESTED,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERRORED,
  GET_EVENTS_REQUESTED,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_event_requested = () => ({
  type: CREATE_EVENT_REQUESTED,
});

export const create_event_success = () => ({
  type: CREATE_EVENT_SUCCESS,
});

export const create_event_errored = (error) => ({
  type: CREATE_EVENT_ERRORED,
  error,
});

export const create_event = (payload) => async (dispatch) => {
  console.log(`create_event`);

  dispatch(create_event_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_event`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/event`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/event`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        //dispatch(showSnackbar("Event created Successfully", "success"));
        dispatch(create_event_success());
      })
      .catch((err) => {
        console.log("Event creation Failed");
        //dispatch(showSnackbar("Exam creation Failed", "error"));
        dispatch(create_event_errored(err));
      });
  }
  return null;
};

export const get_events_requested = () => ({
  type: GET_EVENTS_REQUESTED,
});

export const get_events_success = (payload) => ({
  type: GET_EVENTS_SUCCESS,
  payload,
});

export const get_events_errored = (error) => ({
  type: GET_EVENTS_ERRORED,
  error,
});

export const get_events =
  (activeOuid, activeOuidChain, groupObjectId, groupType) =>
  async (dispatch) => {
    dispatch(get_events_requested());
    console.log(`get_events`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/event`;
      url = `${coursesApiEndpoint}/event?groupObjectId=${groupObjectId}&groupType=${groupType}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get Events Fetched Successfully");
          dispatch(get_events_success(response.data));
        })
        .catch((err) => {
          console.log("Get Events Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get Events Failed", "error"));
          }
          dispatch(get_events_errored(err));
        });
    }
    return null;
  };
