import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  Input: {
    fontSize: 14,
  },
  DialogText: {
    fontSize: 16,
  },
  DialogActions:{
    justifyContent: 'center',
  },
  CommentInputWrapper: {
    borderColor: '#dc281e',
    '& .MuiInput-underline': {
      '&:after': {
        borderColor: '#3b4f5a',
      },
    },
  },
  PrimaryButtonroot: {
    background: '#DC281E',
    padding: '5px 15px',
    fontSize: 12,
    '&:hover': {
      background: '#DC281E',
    },
  },
  SecondaryButtonroot: {
    position: 'absolute',
    top: 0,
    right: 0,
    border: 0,
    fontSize: 12,
    padding: '10px',
    color: '#DC281E',
    '&:hover': {
      border: 0,
    },
  },
});

function PaymentPopup(props) {
  const classes = useStyles();
  const { defaultState, componentToPassDown, handleClose } = props;
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);
  const handlePopUpClose = () => {
    setOpen(false);
    return handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm">
      <DialogContent>
        <DialogContentText className={classes.DialogText}>
          {componentToPassDown}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <Button
          onClick={handlePopUpClose}
          color="primary"
          variant="outlined"
          classes={{
            root: classes.SecondaryButtonroot,
            label: classes.SecondaryButtonrlabel,
          }}
        >
          <CloseIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopup);

