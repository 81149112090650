import {
  CREATE_EXAMUSER_REQUESTED,
  CREATE_EXAMUSER_SUCCESS,
  CREATE_EXAMUSER_ERRORED,
  GET_EXAMUSERS_REQUESTED,
  GET_EXAMUSERS_SUCCESS,
  GET_EXAMUSERS_ERRORED,
  DELETE_EXAMUSER_REQUESTED,
  DELETE_EXAMUSER_SUCCESS,
  DELETE_EXAMUSER_ERRORED,
  GET_EXAMUSER_ERRORED,
  GET_EXAMUSER_REQUESTED,
  GET_EXAMUSER_SUCCESS,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_examuser_requested = () => ({
  type: CREATE_EXAMUSER_REQUESTED,
});

export const create_examuser_success = () => ({
  type: CREATE_EXAMUSER_SUCCESS,
});

export const create_examuser_errored = (error) => ({
  type: CREATE_EXAMUSER_ERRORED,
  error,
});

export const create_exam_user = (payload) => async (dispatch) => {
  console.log(`create_exam_user`);

  dispatch(create_examuser_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_exam_user`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/examuser`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/examuser`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Examuser created Successfully", "success"));
        dispatch(create_examuser_success());
      })
      .catch((err) => {
        console.log("Examuser creation Failed");
        dispatch(showSnackbar("Examuser creation Failed", "error"));
        dispatch(create_examuser_errored(err));
      });
  }
  return null;
};

export const get_examusers_requested = () => ({
  type: GET_EXAMUSERS_REQUESTED,
});

export const get_examusers_success = (payload) => ({
  type: GET_EXAMUSERS_SUCCESS,
  payload,
});

export const get_examusers_errored = (error) => ({
  type: GET_EXAMUSERS_ERRORED,
  error,
});

export const get_exam_users =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_examusers_requested());
    console.log(`get_exam_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examuser`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/examuser`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("get_exam_users Fetched Successfully");
          dispatch(get_examusers_success(response.data));
        })
        .catch((err) => {
          console.log("get_exam_users Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Examusers Failed", "error"));
          }
          dispatch(get_examusers_errored(err));
        });
    }
    return null;
  };

export const get_all_exam_users =
  (activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(get_examusers_requested());
    console.log(`get_exam_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examuser?getAll=true`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Examusers Fetched Successfully");
          dispatch(get_examusers_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Examusers Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Examusers Failed", "error"));
          }
          dispatch(get_examusers_errored(err));
        });
    }
    return null;
  };

export const get_my_subc_exams =
  (activeOuid, activeOuidChain) => async (dispatch) => {
    dispatch(get_examusers_requested());
    console.log(`get_exam_users`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examuser?myExams=true`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Examusers Fetched Successfully");
          dispatch(get_examusers_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Examusers Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Examusers Failed", "error"));
          }
          dispatch(get_examusers_errored(err));
        });
    }
    return null;
  };

export const get_exam_user =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_examuser_requested());
    console.log(`get_exam`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/exam/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get  Fetched Successfully", response.data);
          dispatch(get_examuser_success(response.data));
        })
        .catch((err) => {
          console.log("Get Course Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Exams By Failed", "error"));
          }
          dispatch(get_examuser_errored(err));
        });
    }
    return null;
  };

export const get_examuser_requested = () => ({
  type: GET_EXAMUSER_REQUESTED,
});

export const get_examuser_success = (payload) => ({
  type: GET_EXAMUSER_SUCCESS,
  payload,
});

export const get_examuser_errored = (error) => ({
  type: GET_EXAMUSER_ERRORED,
  error,
});

export const delete_exam_user =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    console.log("subid", id);
    dispatch(delete_examuser_requested());
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      const examuserid = encodeURIComponent(id);
      console.log("examuserid", examuserid);
      let url = `${coursesApiEndpoint}/examuser/${examuserid}`;
      console.log("url", url);
      const { activeOuidCoreConfigs } = store.getState().config;
      if (activeOuidCoreConfigs.data) {
        if (activeOuidCoreConfigs.data.config.entityRules) {
          const entityRules = activeOuidCoreConfigs.data.config.entityRules;
          const enitityRule = entityRules.find(
            ({ entity }) => entity === "examuser"
          );
          if (enitityRule) {
            const actionsData = enitityRule.publishToApiTopic.actions;
            if (actionsData.indexOf("D") > -1) {
              url = `${coursesApiEndpoint}/examuser/${id}?publishToApiTopic=true`;
            }
          }
        }
      }
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("examuser Deleted Successfully");
          dispatch(delete_examuser_success(response.data));
        })
        .catch((err) => {
          console.log("Delete examuser  Failed");
          dispatch(showSnackbar("Delete examuser Failed", "error"));
          dispatch(delete_examuser_errored(err));
        });
    }
    return null;
  };

export const delete_examuser_requested = () => ({
  type: DELETE_EXAMUSER_REQUESTED,
});

export const delete_examuser_success = (payload) => ({
  type: DELETE_EXAMUSER_SUCCESS,
  payload,
});

export const delete_examuser_errored = (error) => ({
  type: DELETE_EXAMUSER_ERRORED,
  error,
});
