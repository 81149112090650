import {
  CREATE_EXAMUSER_REQUESTED,
  CREATE_EXAMUSER_SUCCESS,
  CREATE_EXAMUSER_ERRORED,
  GET_EXAMUSERS_REQUESTED,
  GET_EXAMUSERS_SUCCESS,
  GET_EXAMUSERS_ERRORED,
  GET_EXAMUSER_REQUESTED,
  GET_EXAMUSER_SUCCESS,
  GET_EXAMUSER_ERRORED,
  DELETE_EXAMUSER_REQUESTED,
  DELETE_EXAMUSER_SUCCESS,
  DELETE_EXAMUSER_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  examUsers: [],
  examUser: {},
  error: {},
};

export function examuser(state = defaultState, action) {
  switch (action.type) {
    case GET_EXAMUSERS_REQUESTED:
      console.log("GET_EXAMUSERS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_EXAMUSERS_SUCCESS:
      console.log("GET_EXAMUSERS_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        examUsers: action.payload.items,
      };

    case GET_EXAMUSERS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_EXAMUSER_REQUESTED:
      console.log("CREATE_EXAMUSER_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_EXAMUSER_SUCCESS:
      console.log("CREATE_EXAMUSER_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_EXAMUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case GET_EXAMUSER_REQUESTED:
      console.log("GET_EXAMUSER_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        examUser: {},
      };

    case GET_EXAMUSER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        examUser: action.payload,
      };

    case GET_EXAMUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case DELETE_EXAMUSER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_EXAMUSER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_EXAMUSER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
}
