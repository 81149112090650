import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { timeFormat } from '../../common/CommonFunctions'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { update_notification } from '../../../actions/action-notification';


const store = configureStore();

function NotificationDetails(props) {
    const { notif, closeNotifDash } = props;
    const [state, setState] = React.useState(notif);

    const Icontooltip = withStyles({
        tooltip: {
            fontSize: 12,
        },
    })(Tooltip);



    const update = async () => {
        await setState({ ...state, 'status': 'CLOSED' });
        const newData = state;
        newData.status = 'CLOSED';
        console.log("New data ::: ", newData);
        const notifId = state.identifier;
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        if (ouid && notifId) {
            let updateNotificationDetails = { "data": state }
            await store.dispatch(update_notification(notifId, ouid, updateNotificationDetails));
            return closeNotifDash()
        }
    }


    const renderDetails = () => {
        return (
            <>
                <div className="NotificationLabel">
                    Notification Type
                </div>
                <div className="NotificationData">
                    {state.notifName}
                </div>
                <div className="NotificationLabel">
                    Details
                </div>
                <div className="NotificationDetail NotificationData">
                    {state.notifDetail}
                </div>
                <div className="NotificationLabel">
                    Status
                </div>
                <div className="NotificationStatus">
                    {state.status}
                </div>
                <div className="NotificationLabel">
                    Created Time
                </div>
                <div className="NotificationData">
                    {timeFormat(state.createdDate)}
                </div>
                <div className="NotificationLabel">
                    Due date
                </div>
                <div className="NotificationData">
                    {timeFormat(state.dueDate)}
                </div>

            </>
        )
    }

    return (
        <>
            <div>
                <h1>Notifications Details</h1>
            </div>
            <div className="TicketdetailsDrawer">
                <Button onClick={() => { closeNotifDash() }} variant="contained" className="detailsCloseBtn">
                    Close Details
                </Button>
                <div className="NotificationDetailsWrapper">
                    <div>
                        {state &&
                            renderDetails()
                        }
                    </div>
                    {state.status === 'OPEN' && (
                        <Button onClick={() => { update() }} variant="contained" className="detailsCloseBtn" color="primary">
                            {state.notifActionName}
                        </Button>
                    )}

                </div>
            </div >
        </>
    );
}
const mapStateToProps = (state) => ({
    permission: state.permission,
    document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
    update_notification: (notifId, ouid, payload) => dispatch(update_notification(notifId, ouid, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetails);

