import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Orgunit } from "@material-ui/icons";
import { history } from "../../../reducers";
import Button from "@material-ui/core/Button";
import { RedirectFunc } from "../../common/CommonFunctions";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import {
  get_courselines,
} from "../../../actions/action-course";
import TextField from "@material-ui/core/TextField";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import { create_update_courseline } from "../../../actions/action-course";
import DeleteIcon from "@material-ui/icons/Delete";
import CommonDelete from "../../common/CommonDelete";

const store = configureStore();

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    minWidth: 250,
    height: "100%",
  },
  button: {
    marginRight: "10px",
  },
});

export default function ChapterCardAdmin(props) {
  const classes = useStyles();
  const [editChapterVisibility, setEditChapterVisibility] =
    React.useState(false);
  console.log("props", props);
  const [state, setState] = React.useState({
    courseId: props.courseId,
    title: props.item.data.title,
    summary: props.item.data.summary,
    featuredImage: props.item.data.featuredImage,
    courseVideo: props.item.data.courseVideo,
    sequenceNumber: props.item.data.sequenceNumber,
    previewable: props.item.data.previewable,
  });

  const onInputChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
    console.log("state", state);
  };
  async function handleSave() {
    // Put api call for chapter edit
    const { activeOuid } = store.getState().permission;
    console.log("Edit Chapter");
    const courselineId = props.item.data.identifier;
    console.log("courselineId ", courselineId);

    await store.dispatch(
      create_update_courseline(courselineId, activeOuid, state, ""),      
    );
    await store.dispatch(
      get_courselines(activeOuid, state.courseId)
    );

  }

  return (
    <div className="">
      <>
        <div className="courseLineItemAdmin" key={props.item.data.identifier}>
          <div className="my_course_card_wrap">
            <div>
              <img src={props.item.data.featuredImage} />
            </div>
            <div>
              <div>
                <strong>Title : </strong>
                {props.item.data.title}
              </div>
              <div>
                <strong>SequenceNumber : </strong>
                {props.item.data.sequenceNumber}
              </div>
              <div>
                <strong>FeaturedImage : </strong>
                {props.item.data.featuredImage}
              </div>
              <div>
                <strong>CourseVideo : </strong>
                {props.item.data.courseVideo}
              </div>
              <div>
                <strong>Previewable : </strong>
                {props.item.data.previewable}
              </div>
              <div className="courseLineItemAdminBtnWrap">
                {/* <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      history.push(
                        `/${activeOuid}/mng-test/${item.data.identifier}`
                      );
                    }}
                  >
                    Test Questions & Answers
                  </Button> */}

                <IconButton
                  color="primary"
                  onClick={() =>
                    setEditChapterVisibility(!editChapterVisibility)
                  }
                >
                  <EditIcon />
                </IconButton>
                {/* <IconButton
                  color="primary"
                  onClick={() =>
                    setEditChapterVisibility(!editChapterVisibility)
                  }
                >
                  <DeleteIcon />
                </IconButton> */}
                <CommonDelete
                  id={props.item.data.identifier}
                  type="CourseLine"
                />
              </div>
            </div>
          </div>
          <div>
            {editChapterVisibility && (
              <div className="courseAddChaptersForm">
                <h2>Edit Chapter</h2>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="title"
                    placeholder="Title"
                    defaultValue={props.item.data.title}
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="summary"
                    defaultValue={props.item.data.summary}
                    placeholder="Summary"
                    onChange={onInputChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    defaultValue={props.item.data.featuredImage}
                    id="featuredImage"
                    placeholder="Featured Image"
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    defaultValue={props.item.data.courseVideo}
                    id="courseVideo"
                    placeholder="Course Video - upload to s3 and give url here"
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    defaultValue={props.item.data.sequenceNumber}
                    id="sequenceNumber"
                    placeholder="new chapter sequence number"
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="form-item-wrapper">
                  <label>Show preview to unregistered users?... </label>
                  <select
                    id="previewable"
                    onChange={onInputChange}
                    fullWidth
                    defaultValue={props.item.data.previewable}
                  >
                    <option value="yes">Yes</option>
                    <option selected value="no">
                      No
                    </option>
                  </select>
                </div>

                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
                <Button
                  onClick={() =>
                    setEditChapterVisibility(!editChapterVisibility)
                  }
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
