import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Orgunit } from "@material-ui/icons";
import { history } from "../../../reducers";
import Button from "@material-ui/core/Button";
import { RedirectFunc } from "../../common/CommonFunctions";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../../actions/action-permissions";
import { get_examlines } from "../../../actions/action-exam";
import TextField from "@material-ui/core/TextField";
import configureStore from "../../../store/configureStore";
import Loader from "../../common/Loader";
import { create_update_examline } from "../../../actions/action-exam";
import DeleteIcon from "@material-ui/icons/Delete";
import CommonDelete from "../../common/CommonDelete";

const store = configureStore();

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    minWidth: 250,
    height: "100%",
  },
  button: {
    marginRight: "10px",
  },
});

export default function QuestionCardAdmin(props) {
  const classes = useStyles();
  const [editQuestionVisibility, setEditQuestionVisibility] =
    React.useState(false);
  console.log("props", props);
  const [state, setState] = React.useState({
    examId: props.examId,
    question: props.item.data.question,
    questionImage: props.item.data.questionImage,
    answer1: props.item.data.answer1,
    answer1Marks: props.item.data.answer1Marks,
    answer2: props.item.data.answer2,
    answer2Marks: props.item.data.answer2Marks,
    answer3: props.item.data.answer3,
    answer3Marks: props.item.data.answer3Marks,
    answer4: props.item.data.answer4,
    answer4Marks: props.item.data.answer4Marks,
    sequenceNumber: props.item.data.sequenceNumber,
  });

  const onInputChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
    console.log("state", state);
  };
  async function handleSave() {
    // Put api call for chapter edit
    const { activeOuid } = store.getState().permission;
    console.log("Edit Question");
    const examlineId = props.item.data.identifier;
    console.log("examlineId ", examlineId);

    await store.dispatch(
      create_update_examline(examlineId, activeOuid, state, "")
    );
    await store.dispatch(get_examlines(activeOuid, state.examId));
  }

  return (
    <div className="">
      <>
        <div className="courseLineItemAdmin" key={props.item.data.identifier}>
          <div className="my_course_card_wrap">
            <div>
              <div>
                <strong>Question : </strong>
                {props.item.data.question}
              </div>
              <div>
                <strong>QuestionImage : </strong>
                <img src={props.item.data.questionImage} alt="new" />
              </div>
              <div>
                <strong>SequenceNumber : </strong>
                {props.item.data.sequenceNumber}
              </div>
              <div>
                <strong>Answer 1 : </strong>
                {props.item.data.answer1}
              </div>
              <div>
                <strong>Answer 1 Marks: </strong>
                {props.item.data.answer1Marks}
              </div>
              <div>
                <strong>Answer 2 : </strong>
                {props.item.data.answer2}
              </div>
              <div>
                <strong>Answer 2 Marks : </strong>
                {props.item.data.answer2Marks}
              </div>
              <div>
                <strong>Answer 3 : </strong>
                {props.item.data.answer3}
              </div>
              <div>
                <strong>Answer 3 Marks : </strong>
                {props.item.data.answer3Marks}
              </div>
              <div>
                <strong>Answer 4 : </strong>
                {props.item.data.answer4}
              </div>
              <div>
                <strong>Answer 4 Marks : </strong>
                {props.item.data.answer4Marks}
              </div>
              <div className="courseLineItemAdminBtnWrap">
                <IconButton
                  color="primary"
                  onClick={() =>
                    setEditQuestionVisibility(!editQuestionVisibility)
                  }
                >
                  <EditIcon />
                </IconButton>
                <CommonDelete id={props.item.data.identifier} type="ExamLine" />
              </div>
            </div>
          </div>
          <div>
            {editQuestionVisibility && (
              <div className="examAddQuestionsForm">
                <h2>Edit Question</h2>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    defaultValue={props.item.data.sequenceNumber}
                    id="sequenceNumber"
                    placeholder="question sequence number"
                    label="sequence number"
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                </div>

                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="question"
                    label="Question"
                    placeholder="Question"
                    defaultValue={props.item.data.question}
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="questionImage"
                    label="Question Image URL"
                    placeholder="Question Image URL"
                    defaultValue={props.item.data.questionImage}
                    onChange={onInputChange}
                    fullWidth
                    variant="outlined"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer1"
                    defaultValue={props.item.data.answer1}
                    placeholder="Answer Option 1"
                    label="Answer Option 1"
                    onChange={onInputChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer1Marks"
                    label="Answer 1 Marks"
                    onChange={onInputChange}
                    defaultValue={props.item.data.answer1Marks}
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer2"
                    defaultValue={props.item.data.answer2}
                    placeholder="Answer Option 2"
                    onChange={onInputChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer2Marks"
                    label="Answer 2 Marks"
                    onChange={onInputChange}
                    defaultValue={props.item.data.answer2Marks}
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer3"
                    defaultValue={props.item.data.answer3}
                    placeholder="Answer Option 3"
                    onChange={onInputChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer3Marks"
                    label="Answer 3 Marks"
                    onChange={onInputChange}
                    defaultValue={props.item.data.answer3Marks}
                    variant="outlined"
                    type="number"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer4"
                    defaultValue={props.item.data.answer4}
                    placeholder="Answer Option 4"
                    onChange={onInputChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="input"
                    id="answer4Marks"
                    label="Answer 4 Marks"
                    onChange={onInputChange}
                    defaultValue={props.item.data.answer4Marks}
                    variant="outlined"
                    type="number"
                  />
                </div>
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
                <Button
                  onClick={() =>
                    setEditQuestionVisibility(!editQuestionVisibility)
                  }
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
