import React, { useRef, useState } from "react";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import { connect } from "react-redux";
import { submit_examinstance } from "../../../actions/action-examinstance";
const store = configureStore();
const timeCounter = (time) => {
  return time.toString().padStart(2, "0");
};

const ExamTimer = (props) => {
  var examinstanceDetails = props.examinstanceDetails;
  console.log(
    "examDurationMinutes in timers",
    examinstanceDetails.data.examData.examDurationMinutes
  );

  console.log("startTime in epoch", examinstanceDetails.startTime);
  let currentTimeinEpoc = Math.floor(Date.now() / 1000);
  console.log("currentTime in epoch", currentTimeinEpoc);
  let timeSinceExamStart =
    currentTimeinEpoc - 0 - (examinstanceDetails.startTime - 0);
  console.log("time since exam start", timeSinceExamStart);
  let timeLeftCalculated =
    examinstanceDetails.data.examData.examDurationMinutes * 60 -
    timeSinceExamStart;
  console.log("timeLeftCalculated", timeLeftCalculated);

  const [timeLeft, setTimeLeft] = useState(timeLeftCalculated);
  const { activeOuid } = store.getState().permission;

  const examinstanceid = examinstanceDetails.data.identifier;
  console.log("examinstanceid: ", examinstanceid);
  // const [isCounting, setIsCounting] = useState(false);
  const intervalRef = useRef(null);
  const inputRef = useRef("");
  startTimer();

  async function startTimer() {
    if (intervalRef.current !== null) return;
    if (isNaN(timeLeft)) return;
    //setIsCounting(true);
    inputRef.current = "";

    intervalRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => {
        console.log("in timer", timeLeft);
        console.log(store.getState().examinstance.examinstanceDetails.status);
        if (timeLeft >= 1) return timeLeft - 2;
        if (
          store.getState().examinstance.examinstanceDetails.status !==
          "completed"
        ) {
          // reset the timer
          clearInterval(intervalRef.current);
          //setIsCounting(false);
          intervalRef.current = null;
          submitExamInstance();
          return 0;
        }
      });
    }, 2000);
  }

  async function submitExamInstance() {
    const { submit_examinstance } = props;
    await submit_examinstance(activeOuid, examinstanceid);
    history.push(`/${activeOuid}/postexam/${examinstanceid}`);
  }

  const minutes = timeCounter(Math.floor(timeLeft / 60));
  const seconds = timeCounter(timeLeft - minutes * 60);

  return (
    <div className="ExamTimer">
      <div>
        Time remaining : <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  examinstance: state.examinstance,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  submit_examinstance: (activeOuid, id) =>
    dispatch(submit_examinstance(activeOuid, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamTimer);
