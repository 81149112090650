import {
  CREATE_EVENT_REQUESTED,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERRORED,
  GET_EVENTS_REQUESTED,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  events: [],
  error: {},
};

export function event(state = defaultState, action) {
  switch (action.type) {
    case GET_EVENTS_REQUESTED:
      console.log("GET_EVENTS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_EVENTS_SUCCESS:
      console.log("GET_EVENTS_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        events: action.payload.items,
      };

    case GET_EVENTS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_EVENT_REQUESTED:
      console.log("CREATE_EVENT_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_EVENT_SUCCESS:
      console.log("CREATE_EVENT_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_EVENT_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
}
