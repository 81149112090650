const dev = {
  api: {
    invokeUrl: "https://74udikyx6b.execute-api.eu-west-2.amazonaws.com/dev",
    coursesUrl: "https://9pxmy7egh6.execute-api.eu-west-2.amazonaws.com/dev",
    woUrl: "https://sni1mg4xql.execute-api.eu-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_pApwzdSKZ",
    APP_CLIENT_ID: "43a1utognucnoa1rjbr25mu8eb",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const test = {
  api: {
    invokeUrl: "https://74udikyx6b.execute-api.eu-west-2.amazonaws.com/dev",
    coursesUrl: "https://9pxmy7egh6.execute-api.eu-west-2.amazonaws.com/dev",
    woUrl: "https://sni1mg4xql.execute-api.eu-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_pApwzdSKZ",
    APP_CLIENT_ID: "43a1utognucnoa1rjbr25mu8eb",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const prod = {
  api: {
    invokeUrl: "https://7qnpzneorb.execute-api.eu-west-2.amazonaws.com/prod",
    coursesUrl: "https://o21locbvl0.execute-api.eu-west-2.amazonaws.com/prod",
    woUrl: "",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_X8LhiA1tw",
    APP_CLIENT_ID: "2uh4bbq5d852urrfeuaolh7i3f",
  },
  payemntSecretName: "",
  razorpayId: "",
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
