import {
  CREATE_COURSE_REQUESTED,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_ERRORED,
  UPDATE_COURSE_REQUESTED,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_ERRORED,
  GET_COURSES_REQUESTED,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERRORED,
  GET_COURSE_REQUESTED,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERRORED,
  DELETE_COURSE_REQUESTED,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_ERRORED,
  CREATE_OR_UPDATE_COURSELINE_REQUESTED,
  CREATE_OR_UPDATE_COURSELINE_SUCCESS,
  CREATE_OR_UPDATE_COURSELINE_ERRORED,
  GET_COURSELINES_REQUESTED,
  GET_COURSELINES_SUCCESS,
  GET_COURSELINES_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_course_requested = () => ({
  type: CREATE_COURSE_REQUESTED,
});

export const create_course_success = () => ({
  type: CREATE_COURSE_SUCCESS,
});

export const create_course_errored = (error) => ({
  type: CREATE_COURSE_ERRORED,
  error,
});

export const update_course_requested = () => ({
  type: UPDATE_COURSE_REQUESTED,
});

export const update_course_success = () => ({
  type: UPDATE_COURSE_SUCCESS,
});

export const update_course_errored = (error) => ({
  type: UPDATE_COURSE_ERRORED,
  error,
});

export const create_course = (payload) => async (dispatch) => {
  console.log(`create_course`);

  dispatch(create_course_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_course`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/course`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/course`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Course created Successfully", "success"));
        dispatch(create_course_success());
      })
      .catch((err) => {
        console.log("Course creation Failed");
        dispatch(showSnackbar("Course creation Failed", "error"));
        dispatch(create_course_errored(err));
      });
  }
  return null;
};

export const update_course = (id, payload, activeOuid) => async (dispatch) => {
  dispatch(update_course_requested());

  const { activeOuidChain } = store.getState().permission;
  console.log(`update_course`);

  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/course/${id}`;
    const method = "put";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Course updated Successfully", "success"));
        dispatch(update_course_success());
      })
      .catch((err) => {
        console.log("Course update Failed");
        dispatch(showSnackbar("Course update Failed", "error"));
        dispatch(update_course_errored(err));
      });
  }
  return null;
};

export const get_courses_requested = () => ({
  type: GET_COURSES_REQUESTED,
});

export const get_courses_success = (payload) => ({
  type: GET_COURSES_SUCCESS,
  payload,
});

export const get_courses_errored = (error) => ({
  type: GET_COURSES_ERRORED,
  error,
});

export const get_courses =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_courses_requested());
    console.log(`get_courses`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/course?ouid=${ouid}&type=default`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/course?ouid=${ouid}&type=default&category=${category}`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Fetched Successfully");
          dispatch(get_courses_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courses Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses Failed", "error"));
          }
          dispatch(get_courses_errored(err));
        });
    }
    return null;
  };

export const get_courses_public =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_courses_requested());
    console.log(`get_courses_public`);
    const { coursesApiEndpoint } = store.getState().jwt.api;
    if (coursesApiEndpoint) {
      let url = `${coursesApiEndpoint}/course-public?ouid=${ouid}&type=default`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/course-public?ouid=${ouid}&type=default&category=${category}`;
      }
      const method = "get";
      const axiosHeader = {
        headers: {
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Public Fetched Successfully");
          dispatch(get_courses_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courses Public Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses Public Failed", "error"));
          }
          dispatch(get_courses_errored(err));
        });
    }
    return null;
  };

export const get_course =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_course_requested());
    console.log(`get_course`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/course/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_course_success(response.data));
        })
        .catch((err) => {
          console.log("Get Course Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses By Failed", "error"));
          }
          dispatch(get_course_errored(err));
        });
    }
    return null;
  };

export const get_course_public = (activeOuid, id) => async (dispatch) => {
  dispatch(get_course_requested());
  console.log(`get_course_public`);
  const { coursesApiEndpoint } = store.getState().jwt.api;
  if (coursesApiEndpoint) {
    let url = `${coursesApiEndpoint}/course-public/${id}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get Coruse Public fetched Successfully", response.data);
        dispatch(get_course_success(response.data));
      })
      .catch((err) => {
        console.log("Get Course Public Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Courses Public Failed", "error"));
        }
        dispatch(get_course_errored(err));
      });
  }
  return null;
};

export const get_course_requested = () => ({
  type: GET_COURSE_REQUESTED,
});

export const get_course_success = (payload) => ({
  type: GET_COURSE_SUCCESS,
  payload,
});

export const get_course_errored = (error) => ({
  type: GET_COURSE_ERRORED,
  error,
});

export const delete_course =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_course_requested());
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/course/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      if (activeOuidCoreConfigs.data) {
        if (activeOuidCoreConfigs.data.config.entityRules) {
          const entityRules = activeOuidCoreConfigs.data.config.entityRules;
          const enitityRule = entityRules.find(
            ({ entity }) => entity === "course"
          );
          if (enitityRule) {
            const actionsData = enitityRule.publishToApiTopic.actions;
            if (actionsData.indexOf("D") > -1) {
              url = `${coursesApiEndpoint}/course/${id}?publishToApiTopic=true`;
            }
          }
        }
      }
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Course Deleted Successfully");
          dispatch(delete_course_success(response.data));
        })
        .catch((err) => {
          console.log("Delete Course  Failed");
          dispatch(showSnackbar("Delete Course Failed", "error"));
          dispatch(delete_course_errored(err));
        });
    }
    return null;
  };

export const delete_course_requested = () => ({
  type: DELETE_COURSE_REQUESTED,
});

export const delete_course_success = (payload) => ({
  type: DELETE_COURSE_SUCCESS,
  payload,
});

export const delete_course_errored = (error) => ({
  type: DELETE_COURSE_ERRORED,
  error,
});

export const create_update_courseline_requested = () => ({
  type: CREATE_OR_UPDATE_COURSELINE_REQUESTED,
});

export const create_update_courseline_success = () => ({
  type: CREATE_OR_UPDATE_COURSELINE_SUCCESS,
});

export const create_update_courseline_errored = (error) => ({
  type: CREATE_OR_UPDATE_COURSELINE_ERRORED,
  error,
});

export const create_update_courseline =
  (courselineId, ouid, payload, type) => async (dispatch) => {
    dispatch(create_update_courseline_requested());
    console.log(`create_update_courseline`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      //   console.log(`jwt : ${jwt}`);
      let method = "post";
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      if (courselineId) {
        method = "put";
        url = `${coursesApiEndpoint}/courseline/${courselineId}`;
      } else {
        url = `${coursesApiEndpoint}/courseline`;
      }
      // console.log(`url : ${url}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Courseline Create or Upadate Success");
          dispatch(
            showSnackbar("Courseline Create or Upadate Successfully", "success")
          );
          dispatch(create_update_courseline_success(response.data));
          dispatch(get_courses());
          return response.data.identifier;
        })
        .catch((err) => {
          console.log("Courseline Create or Upadate Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(
            showSnackbar("Courseline Create or Upadate Failed", "error")
          );
          dispatch(create_update_courseline_errored(err));
        });
    }
    return null;
  };

// export const update_courseline = (payload) => async (dispatch) => {
//   dispatch(update_courseline_requested());
//   const path = window.location.pathname.split("/");
//   const activeOuid = path[1];
//   const { activeOuidChain } = store.getState().permission;
//   console.log(`update_course`);
//   const id = path[3];
//   const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
//   if (coursesApiEndpoint && credentials) {
//     const jwt = `${credentials.idToken.jwtToken}`;
//     var url = `${coursesApiEndpoint}/course/${id}`;
//     const method = "put";
//     const axiosHeader = {
//       headers: {
//         Authorization: jwt,
//         "x-ouid": activeOuid,
//         "x-ouidchain": activeOuidChain,
//       },
//     };
//     return axios[method](url, payload, axiosHeader)
//       .then((response) => {
//         dispatch(showSnackbar("courseline updated Successfully", "success"));
//         dispatch(update_courseline_success());
//       })
//       .catch((err) => {
//         console.log("Course update Failed");
//         dispatch(showSnackbar("courseline update Failed", "error"));
//         dispatch(update_courseline_errored(err));
//       });
//   }
//   return null;
// };

export const get_courselines_requested = () => ({
  type: GET_COURSELINES_REQUESTED,
});

export const get_courselines_success = (payload) => ({
  type: GET_COURSELINES_SUCCESS,
  payload,
});

export const get_courselines_errored = (error) => ({
  type: GET_COURSELINES_ERRORED,
  error,
});

export const get_courselines = (activeOuid, courseid) => async (dispatch) => {
  dispatch(get_courselines_requested());
  console.log(`get_courselines`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${coursesApiEndpoint}/courseline?courseId=${courseid}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get All Courseline Success");
        dispatch(get_courselines_success(response.data));
      })
      .catch((err) => {
        console.log("Get All courseline Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Courseline Failed", "error"));
        }
        dispatch(get_courselines_errored(err));
      });
  }
  return null;
};

export const get_courselines_public =
  (activeOuid, courseid) => async (dispatch) => {
    dispatch(get_courselines_requested());
    console.log(`get_courselines_public`);
    const { coursesApiEndpoint } = store.getState().jwt.api;
    if (coursesApiEndpoint) {
      let url = `${coursesApiEndpoint}/courseline-public?courseId=${courseid}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courseline Public Success");
          dispatch(get_courselines_success(response.data));
        })
        .catch((err) => {
          console.log("Get All courseline public Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courseline public Failed", "error"));
          }
          dispatch(get_courselines_errored(err));
        });
    }
    return null;
  };

export const delete_course_line =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/courseline/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Course Line Deleted Successfully");
          //dispatch(delete_course_success(response.data));
          dispatch(
            showSnackbar("Chapter deleted Successfully", "success")
          );
        })
        .catch((err) => {
          console.log("Delete Course Line  Failed");
          dispatch(showSnackbar("Delete Course Failed", "error"));
          // dispatch(delete_course_errored(err));
        });
    }
    return null;
  };
