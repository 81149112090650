import configureStore from "../../store/configureStore";
import { setUserRole, setActiveOuid } from "../../actions/action-permissions";
import { history } from "../../reducers";
import axios from "axios";

const store = configureStore();

export function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

export async function userRoleCheck(policies) {
  console.log("userRoleCheck function called");
  let userRoles = [];
  if (policies.includes("system=admin")) {
    for (let i = 0; i < policies.length; i++) {
      let policy = policies[i];
      if (policy === "system=admin") {
        let role = policy.split("=");
        let userRole = {};
        userRole["ouid"] = role[0];
        userRole["roles"] = role[1].split(",");
        userRoles.push(userRole);
      }
    }
    await store.dispatch(setUserRole(userRoles));
    // store.dispatch(setActiveOuid(userRoles[0].ouid));
    // const path = window.location.pathname.split('/');
    // const activeOuidValue = path[path.length - path.length-1];
    // if (activeOuidValue) {
    //     store.dispatch(setActiveOuid(activeOuidValue));
    // } else {
    //     store.dispatch(setActiveOuid(userRoles[0].ouid));
    // }
    return;
  }
  for (let i = 0; i < policies.length; i++) {
    let policy = policies[i];
    if (policy !== "system=admin") {
      let role = policy.split("=");
      let userRole = {};
      userRole["ouid"] = role[0];
      userRole["roles"] = role[1].split(",");
      userRoles.push(userRole);
    }
  }
  await store.dispatch(setUserRole(userRoles));
  // store.dispatch(setActiveOuid(userRoles[0].ouid));
  // const path = window.location.pathname.split('/');
  // const activeOuidValue = path[path.length - 2];
  // if (activeOuidValue) {
  //     store.dispatch(setActiveOuid(activeOuidValue));
  // } else {
  //     store.dispatch(setActiveOuid(userRoles[0].ouid));
  // }
  return;
}

const policies = {
  Statement: [
    {
      Effect: "Allow",
      Action: [
        "CreateChannelPartner",
        "ListChannelPartner",
        "ViewChannelPartner",
        "EditChannelPartner",
        "CreateOrgunit",
        "ListOrgunit",
        "ViewOrgunit",
        "EditOrgunit",
        "CreateInvestor",
        "ListInvestors",
        "ViewInvestor",
        "EditInvestor",
        "UploadReports",
        "ListReports",
        "ViewReport",
        "ListLeads",
        "ViewLeads",
        "CreateUser",
        "ListUsers",
        "ViewUser",
        "EditUser",
        "CreateUser",
        "ListOrgUsers",
        "ViewOrgUser",
        "EditOrgUser",
        "ViewDashboard",
      ],
      Principal: "admin",
    },
    {
      Effect: "Allow",
      Action: ["ViewDashboard"],
      Principal: "user",
    },
  ],
};

// old policy check

export function permissionsCheck(userRoles, requestedAction, type) {
  console.log("permissionsCheck --> ", userRoles, requestedAction, type);
  const { activeOuid } = store.getState().permission;
  for (let i = 0; i < userRoles.length; i++) {
    let userRole = userRoles[i];
    const roles = userRole.roles;
    if (userRole.ouid === "system" && roles.includes("admin")) {
      return true;
    } else {
      if (userRole.ouid === activeOuid) {
        console.log("permissionsCheck userRole : ", userRole);
        console.log("permissionsCheck roles : ", roles);
        console.log("permissionsCheck activeOuid : ", activeOuid);
        // for (let j = 0; j < roles.length; j++) {
        let userHeighestRole;
        if (roles.includes("admin")) {
          userHeighestRole = "admin";
        } else {
          userHeighestRole = "user";
        }
        const policy = policies.Statement.find(
          ({ Principal }) => Principal === userHeighestRole
        );
        if (policy) {
          let action = policy.Action;
          if (action.includes(requestedAction)) {
            switch (type) {
              case "orgunit": {
                const ouidRole = userRoles.find(
                  ({ ouid }) => ouid === activeOuid
                );
                console.log("permissionsCheck ouidRole : ", ouidRole);
                if (ouidRole) {
                  return true;
                } else {
                  // return false;
                  return true;
                }
              }
              case "orgUser": {
                return true;
              }
              case "authUser": {
                return true;
              }
              case "commonPages": {
                return true;
              }
              default:
                // return false;
                return true;
            }
          }
        }
        // }
      }
    }
  }
  // return false;
  return true;
}

// export function permissionsCheck(requestedAction, type) {
//     const { activeOuid, userRoles } = store.getState().permission;
//     const userRole = userRoles.find(({ ouid }) => ouid === activeOuid);
//     if (userRole) {
//         const roles = userRole.roles;
//         if (userRole.ouid === "system" && roles.includes("admin")) {
//             return true;
//         }
//         else {
//             console.log("permissionsCheck userRole : ", userRole);
//             if (userRole.ouid === activeOuid) {
//                 console.log("permissionsCheck userRole : ", userRole);
//                 console.log("permissionsCheck roles : ", roles);
//                 // for (let j = 0; j < roles.length; j++) {
//                 let userHeighestRole;
//                 if (roles.includes("admin")) {
//                     userHeighestRole = "admin";
//                 } else {
//                     userHeighestRole = "user";
//                 }
//                 const policy = policies.Statement.find(({ Principal }) => Principal === userHeighestRole);
//                 if (policy) {
//                     let action = policy.Action;
//                     if (action.includes(requestedAction)) {
//                         switch (type) {
//                             case 'orgunit':
//                                 {
//                                     const ouidRole = userRoles.find(({ ouid }) => ouid === activeOuid);
//                                     if (ouidRole) {
//                                         return true;
//                                     } else {
//                                         return false;
//                                     }
//                                 }
//                             case 'orgUser':
//                                 {
//                                     return true;
//                                 }
//                             case 'authUser':
//                                 {
//                                     return true;
//                                 }
//                             default:
//                                 return false;
//                         }
//                     }
//                 }
//                 // }
//             }
//         }
//     }
//     return false;
// }

export function RedirectFunc(url) {
  history.push(url);
}

export function timeFormat(date) {
  const date1Stamp = new Date(date);
  if (date1Stamp instanceof Date && !isNaN(date1Stamp)) {
    return date1Stamp.toLocaleString();
  }
  return "";
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isEmptyJson(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

export function getParentChain(ouid) {
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/orgunit/${ouid}`;
    console.log(`jwt : ${jwt}`);
    console.log(`url : ${url}`);
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get Parent Chain Success : ", JSON.stringify(response));
        const { data } = response.data;
        if (data.parentChain) {
          return data.parentChain;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("Get Parent Chain  Failed", JSON.stringify(err));
      });
  }
}

export function downloadFromUrl(url, name) {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = window.document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = name;
      window.document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      alert("File has been downloaded"); // or you know, something with better UX...
    })
    .catch(() => alert("oh no!"));
}

export function updateQueryStringParameter(key, value, viewType) {
  if (viewType !== null) {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${key}=${value}&viewType=${viewType}`;
    window.history.pushState({ path: newurl }, "", newurl);
  } else {
    const currentUrlParams = new URLSearchParams(window.location.search);
    if (value === "") {
      currentUrlParams.delete("identifier");
    }
    currentUrlParams.set(key, value);
    const newurl = `${window.location.pathname}?${currentUrlParams.toString()}`;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}
