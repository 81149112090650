import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import configureStore from '../../../store/configureStore';
import CommonSnackBar from '../../common/CommonSnackBar';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { Auth } from 'aws-amplify';
import { history } from '../../../reducers';
import { resetState } from '../../../actions';
import CompletedImage from '../../../components/assets/images/edwinImages/registrationcomplete.png';
import ProgressCircle from '../../common/ProgressCircle';


const store = configureStore();

class RegistartionCompleted extends Component {

    constructor() {
        super();
        this.state = {
        };
        this.handleLogOut = this.handleLogOut.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.handleLogOut();
        }, 5000)
    }


    handleLogOut() {
        try {
            Auth.signOut();
            this.props.auth.setAuthStatus(false);
            this.props.auth.setUser(null);
            store.dispatch(resetState());
            history.push("/login");
        } catch (error) {
            console.log(error.message);
        }
    }

    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                {/* <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                /> */}
                <section className="section">
                    <div className='container--narrow'>
                        <Paper>
                            <div className="UserOrgConfirmationPageWrapper">
                                <img src={CompletedImage} alt="" />
                                <div>
                                    <h2>
                                        Business Registration Completed
                                    </h2>
                                    <div>
                                        You need to login to your organisation.
                                    </div>
                                    <div className="redirect_message">
                                        You will be automaticaly redirected to login page in 5 Seconds
                                    </div>
                                    <ProgressCircle />
                                </div>
                            </div>
                        </Paper>
                    </div>
                </section>
                <CommonSnackBar />
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistartionCompleted);