import {
  CREATE_EXAMINSTANCE_REQUESTED,
  CREATE_EXAMINSTANCE_SUCCESS,
  CREATE_EXAMINSTANCE_ERRORED,
  UPDATE_EXAMINSTANCE_REQUESTED,
  UPDATE_EXAMINSTANCE_SUCCESS,
  UPDATE_EXAMINSTANCE_ERRORED,
  GET_EXAMINSTANCES_REQUESTED,
  GET_EXAMINSTANCES_SUCCESS,
  GET_EXAMINSTANCES_ERRORED,
  GET_EXAMINSTANCE_REQUESTED,
  GET_EXAMINSTANCE_SUCCESS,
  GET_EXAMINSTANCE_ERRORED,
  UPDATE_EXAMLINEINSTANCE_REQUESTED,
  UPDATE_EXAMLINEINSTANCE_SUCCESS,
  UPDATE_EXAMLINEINSTANCE_ERRORED,
  GET_EXAMLINEINSTANCES_REQUESTED,
  GET_EXAMLINEINSTANCES_SUCCESS,
  GET_EXAMLINEINSTANCES_ERRORED,
  GET_EXAMLINEINSTANCE_REQUESTED,
  GET_EXAMLINEINSTANCE_SUCCESS,
  GET_EXAMLINEINSTANCE_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: true,
  showGlobalLoaderExamlineinstance: true,
  showGlobalLoaderExamlineinstances: true,
  examlineinstances: [],
  examlineinstanceDetails: {},
  examinstanceDetails: {},
  examinstances: [],
  error: {},
};

export function examinstance(state = defaultState, action) {
  switch (action.type) {
    case GET_EXAMINSTANCES_REQUESTED:
      console.log("GET_EXAMINSTANCES_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_EXAMINSTANCES_SUCCESS:
      console.log(
        "GET_EXAMINSTANCES_SUCCESS : ",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        showGlobalLoader: false,
        examinstances: action.payload.items,
      };

    case GET_EXAMINSTANCES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_EXAMINSTANCE_REQUESTED:
      console.log("CREATE_EXAMINSTANCE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_EXAMINSTANCE_SUCCESS:
      console.log("CREATE_EXAMINSTANCE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_EXAMINSTANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case UPDATE_EXAMINSTANCE_REQUESTED:
      console.log("UPDATE_EXAMINSTANCE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_EXAMINSTANCE_SUCCESS:
      console.log("UPDATE_EXAMINSTANCE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_EXAMINSTANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case GET_EXAMINSTANCE_REQUESTED:
      console.log("GET_EXAMINSTANCE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        examinstanceDetails: {},
      };

    case GET_EXAMINSTANCE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        examinstanceDetails: action.payload,
      };

    case GET_EXAMINSTANCE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case UPDATE_EXAMLINEINSTANCE_REQUESTED:
      console.log("UPDATE_EXAMLINEINSTANCE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoaderExamlineinstance: true,
      };

    case UPDATE_EXAMLINEINSTANCE_SUCCESS:
      console.log("UPDATE_EXAMLINEINSTANCE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoaderExamlineinstance: false,
      };

    case UPDATE_EXAMLINEINSTANCE_ERRORED:
      return {
        ...state,
        showGlobalLoaderExamlineinstance: false,
        error: action.error,
      };

    case GET_EXAMLINEINSTANCES_REQUESTED:
      return {
        ...state,
        showGlobalLoaderExamlineinstances: true,
        examlineinstances: [],
      };

    case GET_EXAMLINEINSTANCES_SUCCESS:
      return {
        ...state,
        showGlobalLoaderExamlineinstances: false,
        examlineinstances: action.payload.items,
      };

    case GET_EXAMLINEINSTANCES_ERRORED:
      return {
        ...state,
        showGlobalLoaderExamlineinstances: false,
        error: action.payload,
      };

    case GET_EXAMLINEINSTANCE_REQUESTED:
      return {
        ...state,
        showGlobalLoaderExamlineinstance: true,
        examlineinstanceDetails: {},
      };

    case GET_EXAMLINEINSTANCE_SUCCESS:
      return {
        ...state,
        showGlobalLoaderExamlineinstance: false,
        examlineinstanceDetails: action.payload,
      };

    case GET_EXAMLINEINSTANCE_ERRORED:
      return {
        ...state,
        showGlobalLoaderExamlineinstance: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}
