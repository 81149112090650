import {
  CREATE_OR_UPDATE_CONFIGS_REQUESTED,
  CREATE_OR_UPDATE_CONFIGS_SUCCESS,
  CREATE_OR_UPDATE_CONFIGS_ERRORED,
  GET_ALL_ACTIVE_CONFIGS_REQUESTED,
  GET_ALL_ACTIVE_CONFIGS_SUCCESS,
  GET_ALL_ACTIVE_CONFIGS_ERRORED,
  GET_ALL_DEPLOYED_CONFIGS_REQUESTED,
  GET_ALL_DEPLOYED_CONFIGS_SUCCESS,
  GET_ALL_DEPLOYED_CONFIGS_ERRORED,
  SET_ACTIVE_OUID_CONFIGS_REQUESTED,
  SET_ACTIVE_OUID_CONFIGS_SUCCESS,
  SET_ACTIVE_OUID_CONFIGS_ERRORED,
} from '../constant/action-types';

const defaultState = {
  showGlobalLoader: false,
  activeOuidConfigs :[],
  activeOuidCoreConfigs:{},
  allActiveConfigs: [],
  deployedConfigs: [],
  configDetails: {},
  error: {}
};

export function config(state = defaultState, { type, payload }) {
  switch (type) {

    case CREATE_OR_UPDATE_CONFIGS_REQUESTED:
      // console.log("CREATE_OR_UPDATE_CONFIGS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        allActiveConfigs: []
      };

    case CREATE_OR_UPDATE_CONFIGS_SUCCESS:
      // console.log("CREATE_OR_UPDATE_CONFIGS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_OR_UPDATE_CONFIGS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    case GET_ALL_ACTIVE_CONFIGS_REQUESTED:
      // console.log("GET_ALL_ACTIVE_CONFIGS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        allActiveConfigs: []
      };

    case GET_ALL_ACTIVE_CONFIGS_SUCCESS:
      // console.log("GET_ALL_ACTIVE_CONFIGS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        allActiveConfigs: payload.items
      };

    case GET_ALL_ACTIVE_CONFIGS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

      case SET_ACTIVE_OUID_CONFIGS_REQUESTED:
        // console.log("SET_ACTIVE_OUID_CONFIGS_REQUESTED : ");
        return {
          ...state,
          showGlobalLoader: true,
          activeOuidConfigs: [],
          activeOuidCoreConfigs: {}
        };
  
      case SET_ACTIVE_OUID_CONFIGS_SUCCESS:
        // console.log("SET_ACTIVE_OUID_CONFIGS_SUCCESS : ", JSON.stringify(payload));
        return {
          ...state,
          showGlobalLoader: false,
          activeOuidConfigs: payload.items,
          activeOuidCoreConfigs: (payload.items.find(({ data }) => data.identifier === 'core')) ? payload.items.find(({ data }) => data.identifier === 'core') : {}
        };
  
      case SET_ACTIVE_OUID_CONFIGS_ERRORED:
        return {
          ...state,
          showGlobalLoader: false,
          error: payload,
          activeOuidCoreConfigs:{},
        };

    case GET_ALL_DEPLOYED_CONFIGS_REQUESTED:
      // console.log("GET_ALL_DEPLOYED_CONFIGS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        deployedConfigs: []
      };

    case GET_ALL_DEPLOYED_CONFIGS_SUCCESS:
      // console.log("GET_ALL_DEPLOYED_CONFIGS_SUCCESS : ", JSON.stringify(payload));
      return {
        ...state,
        showGlobalLoader: false,
        deployedConfigs: payload.items
      };

    case GET_ALL_DEPLOYED_CONFIGS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    default:
      return state;
  }
}