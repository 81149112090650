import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_all_notification } from '../../../actions/action-notification';
import SidebarWrapper from '../../layout/SidebarWrapper';
import { history } from '../../../reducers';
import Loader from '../../common/Loader';
import { DataGrid } from '@material-ui/data-grid';
import { timeFormat, updateQueryStringParameter } from '../../common/CommonFunctions'
import { hideSnackbar } from '../../../actions/action-snackbar';
import NotificationDetails from './NotificationDetails';
import CommonSnackBar from "../../common/CommonSnackBar";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const store = configureStore();

const columns = [
  {
    field: 'notifName',
    headerName: 'Notif Name',
    width: 300,
  },
  {
    field: 'created-Date',
    headerName: 'CreatedDate',
    width: 150,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, 'createdDate')) || ''}`
  },
  {
    field: 'due-Date',
    headerName: 'DueDate',
    width: 150,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, 'dueDate')) || ''}`
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'workflowName',
    headerName: 'workflowName',
    type: 'number',
    width: 200,
    editable: false
  },
];



class Notifications extends Component {

  constructor() {
    super();
    this.state = {
      notificationsToShow: [],
      selectionModel: "",
      notifDash: false,
      currentNotif: {},
      allStatus: false,
    };
  }


  componentDidMount() {
    console.log("componentDidMount");
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
    store.dispatch(hideSnackbar());
  }

  async fetchDetails() {
    const { get_all_notification } = this.props;
    const path = window.location.pathname.split('/');
    const ouid = path[1];
    await get_all_notification(ouid);
    const { notifications } = store.getState().notification;
    console.log("renderNotifications :::: notifications ", notifications)
    this.setState({
      notificationsToShow: notifications.filter(function (item) { return item.data.status === "OPEN" }),
    })
  }

  renderNotifications(notifications) {
    console.log("renderNotifications :::: ", notifications)
    let newRows = [];
    notifications.map((notification, index) => {
      let newRow = notification.data;
      newRow['id'] = notification.data.identifier;
      newRows.push(newRow);
    });
    return newRows;
  }

  setSelectionModel(id) {
    this.setState({
      selectionModel: id,
    });
  }

  setNOtifDash(value) {
    this.setState({
      notifDash: value,
    });
  }

  openNotifDash(data) {
    console.log("openWorkDash : ", data.row)
    this.setNOtifDash(true);
    this.setSelectionModel(data.row.id);
    this.setState({
      currentNotif: data.row,
    });
    updateQueryStringParameter('identifier', data.row.id, null);
  }

  closeNotifDash() {
    console.log("openNotifDash")
    this.setNOtifDash(false);
    this.setState({
      currentNotif: "",
    });
    updateQueryStringParameter('identifier', '', null);
  }

  handleCheckChange(event) {
    const { notifications } = store.getState().notification;
    this.setState({
      allStatus: event.target.checked,
      notificationsToShow: notifications.filter(function (item) { return item.data.status === "OPEN" }),
    });
    if (event.target.checked) {
      this.setState({
        notificationsToShow: notifications,
      })
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().notification;
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          <div className="container">
            {showGlobalLoader ?
              <Loader />
              : <div className="container">
                <div className="listHeader">
                  <h1>Notifications</h1>
                  {/* <SearchField onChange={(e) => this.search(e.target.value.split(' '), Investors)} /> */}
                </div>
                <div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.allStatus}
                          onChange={event => this.handleCheckChange(event)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="View All Notifications"
                    />
                  </div>
                  <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={this.renderNotifications(this.state.notificationsToShow)}
                      columns={columns}
                      pageSize={10}
                      checkboxSelection
                      disableSelectionOnClick
                      onCellClick={(e) => { this.openNotifDash(e) }}
                      selectionModel={this.state.selectionModel}
                      hideFooterSelectedRowCount
                      onSelectionModelChange={(selection) => {
                        if (selection.length > !2) {
                          let lastElement = selection.pop();
                          this.setSelectionModel(lastElement);
                        } else {
                          let lastElement = selection.pop();
                          this.setSelectionModel(lastElement);
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  {this.state.notifDash && this.state.currentNotif &&
                    <NotificationDetails
                      notif={this.state.currentNotif}
                      closeNotifDash={() => { this.closeNotifDash() }}
                    />
                  }
                </div>
              </div>}
          </div>
        </section>
        <CommonSnackBar />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  notification: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_notification: (ouid) => dispatch(get_all_notification(ouid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);