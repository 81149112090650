import {
  CREATE_EXAM_REQUESTED,
  CREATE_EXAM_SUCCESS,
  CREATE_EXAM_ERRORED,
  UPDATE_EXAM_REQUESTED,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_ERRORED,
  GET_EXAMS_REQUESTED,
  GET_EXAMS_SUCCESS,
  GET_EXAMS_ERRORED,
  GET_EXAM_REQUESTED,
  GET_EXAM_SUCCESS,
  GET_EXAM_ERRORED,
  DELETE_EXAM_REQUESTED,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_ERRORED,
  CREATE_OR_UPDATE_EXAMLINE_REQUESTED,
  CREATE_OR_UPDATE_EXAMLINE_SUCCESS,
  CREATE_OR_UPDATE_EXAMLINE_ERRORED,
  GET_EXAMLINES_REQUESTED,
  GET_EXAMLINES_SUCCESS,
  GET_EXAMLINES_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_exam_requested = () => ({
  type: CREATE_EXAM_REQUESTED,
});

export const create_exam_success = () => ({
  type: CREATE_EXAM_SUCCESS,
});

export const create_exam_errored = (error) => ({
  type: CREATE_EXAM_ERRORED,
  error,
});

export const update_exam_requested = () => ({
  type: UPDATE_EXAM_REQUESTED,
});

export const update_exam_success = () => ({
  type: UPDATE_EXAM_SUCCESS,
});

export const update_exam_errored = (error) => ({
  type: UPDATE_EXAM_ERRORED,
  error,
});

export const create_exam = (payload) => async (dispatch) => {
  console.log(`create_course`);

  dispatch(create_exam_requested());
  const path = window.location.pathname.split("/");
  const activeOuid = path[1];
  const { activeOuidChain } = store.getState().permission;
  console.log(`create_course`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/exam`;
    if (payload.bookInParent) {
      url = `${coursesApiEndpoint}/exam`;
    }
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Exam created Successfully", "success"));
        dispatch(create_exam_success());
      })
      .catch((err) => {
        console.log("Exam creation Failed");
        dispatch(showSnackbar("Exam creation Failed", "error"));
        dispatch(create_exam_errored(err));
      });
  }
  return null;
};

export const update_exam = (id, payload, activeOuid) => async (dispatch) => {
  dispatch(update_exam_requested());

  const { activeOuidChain } = store.getState().permission;
  console.log(`update_exam `);

  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    var url = `${coursesApiEndpoint}/exam/${id}`;
    const method = "put";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Exam updated Successfully", "success"));
        dispatch(update_exam_success());
      })
      .catch((err) => {
        console.log("Exam update Failed");
        dispatch(showSnackbar("Exam update Failed", "error"));
        dispatch(update_exam_errored(err));
      });
  }
  return null;
};

export const get_exams_requested = () => ({
  type: GET_EXAMS_REQUESTED,
});

export const get_exams_success = (payload) => ({
  type: GET_EXAMS_SUCCESS,
  payload,
});

export const get_exams_errored = (error) => ({
  type: GET_EXAMS_ERRORED,
  error,
});

export const get_exams =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_exams_requested());
    console.log(`get_exams`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/exam`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/exam?category=${category}`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Fetched Successfully");
          dispatch(get_exams_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courses Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses Failed", "error"));
          }
          dispatch(get_exams_errored(err));
        });
    }
    return null;
  };

export const get_exams_public =
  (activeOuid, activeOuidChain, category, ouid) => async (dispatch) => {
    dispatch(get_exams_requested());
    console.log(`get_exams_public`);
    const { coursesApiEndpoint } = store.getState().jwt.api;
    if (coursesApiEndpoint) {
      let url = `${coursesApiEndpoint}/exam-public?ouid=${ouid}&type=default`;
      if (!category === "") {
        url = `${coursesApiEndpoint}/exam-public?ouid=${ouid}&type=default&category=${category}`;
      }
      const method = "get";
      const axiosHeader = {
        headers: {
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Courses Public Fetched Successfully");
          dispatch(get_exams_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Courses Public Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses Public Failed", "error"));
          }
          dispatch(get_exams_errored(err));
        });
    }
    return null;
  };

export const get_exam =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(get_exam_requested());
    console.log(`get_exam `);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/exam/${id}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get post Fetched Successfully", response.data);
          dispatch(get_exam_success(response.data));
        })
        .catch((err) => {
          console.log("Get Exam Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All Courses By Failed", "error"));
          }
          dispatch(get_exam_errored(err));
        });
    }
    return null;
  };

export const get_exam_public = (activeOuid, id) => async (dispatch) => {
  dispatch(get_exam_requested());
  console.log(`get_exam_public`);
  const { coursesApiEndpoint } = store.getState().jwt.api;
  if (coursesApiEndpoint) {
    let url = `${coursesApiEndpoint}/exam-public/${id}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get Coruse Public fetched Successfully", response.data);
        dispatch(get_exam_success(response.data));
      })
      .catch((err) => {
        console.log("Get Exam Public Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Courses Public Failed", "error"));
        }
        dispatch(get_exam_errored(err));
      });
  }
  return null;
};

export const get_exam_requested = () => ({
  type: GET_EXAM_REQUESTED,
});

export const get_exam_success = (payload) => ({
  type: GET_EXAM_SUCCESS,
  payload,
});

export const get_exam_errored = (error) => ({
  type: GET_EXAM_ERRORED,
  error,
});

export const delete_exam =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    dispatch(delete_exam_requested());
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/exam/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      if (activeOuidCoreConfigs.data) {
        if (activeOuidCoreConfigs.data.config.entityRules) {
          const entityRules = activeOuidCoreConfigs.data.config.entityRules;
          const enitityRule = entityRules.find(
            ({ entity }) => entity === "course"
          );
          if (enitityRule) {
            const actionsData = enitityRule.publishToApiTopic.actions;
            if (actionsData.indexOf("D") > -1) {
              url = `${coursesApiEndpoint}/exam/${id}?publishToApiTopic=true`;
            }
          }
        }
      }
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Exam Deleted Successfully");
          dispatch(delete_exam_success(response.data));
        })
        .catch((err) => {
          console.log("Delete Exam  Failed");
          dispatch(showSnackbar("Delete Exam Failed", "error"));
          dispatch(delete_exam_errored(err));
        });
    }
    return null;
  };

export const delete_exam_requested = () => ({
  type: DELETE_EXAM_REQUESTED,
});

export const delete_exam_success = (payload) => ({
  type: DELETE_EXAM_SUCCESS,
  payload,
});

export const delete_exam_errored = (error) => ({
  type: DELETE_EXAM_ERRORED,
  error,
});

export const create_update_examline_requested = () => ({
  type: CREATE_OR_UPDATE_EXAMLINE_REQUESTED,
});

export const create_update_examline_success = () => ({
  type: CREATE_OR_UPDATE_EXAMLINE_SUCCESS,
});

export const create_update_examline_errored = (error) => ({
  type: CREATE_OR_UPDATE_EXAMLINE_ERRORED,
  error,
});

export const create_update_examline =
  (examlineId, ouid, payload, type) => async (dispatch) => {
    dispatch(create_update_examline_requested());
    console.log(`create_update_examline`);
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      //   console.log(`jwt : ${jwt}`);
      let method = "post";
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      if (examlineId) {
        method = "put";
        url = `${coursesApiEndpoint}/examline/${examlineId}`;
      } else {
        url = `${coursesApiEndpoint}/examline`;
      }
      // console.log(`url : ${url}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Examline Create or Upadate Success");
          dispatch(
            showSnackbar("Examline Create or Upadate Successfully", "success")
          );
          dispatch(create_update_examline_success(response.data));
          dispatch(get_exams());
          return response.data.identifier;
        })
        .catch((err) => {
          console.log("Examline Create or Upadate Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("Examline Create or Upadate Failed", "error"));
          dispatch(create_update_examline_errored(err));
        });
    }
    return null;
  };

export const get_examlines_requested = () => ({
  type: GET_EXAMLINES_REQUESTED,
});

export const get_examlines_success = (payload) => ({
  type: GET_EXAMLINES_SUCCESS,
  payload,
});

export const get_examlines_errored = (error) => ({
  type: GET_EXAMLINES_ERRORED,
  error,
});

export const get_examlines = (activeOuid, examid) => async (dispatch) => {
  dispatch(get_examlines_requested());
  console.log(`get_examlines`);
  const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
  if (coursesApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${coursesApiEndpoint}/examline?examId=${examid}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get All Examline Success");
        dispatch(get_examlines_success(response.data));
      })
      .catch((err) => {
        console.log("Get All courseline Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Examline Failed", "error"));
        }
        dispatch(get_examlines_errored(err));
      });
  }
  return null;
};

export const delete_exam_line =
  (activeOuid, activeOuidChain, id) => async (dispatch) => {
    const { coursesApiEndpoint, credentials } = store.getState().jwt.api;
    if (coursesApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${coursesApiEndpoint}/examline/${id}`;
      const { activeOuidCoreConfigs } = store.getState().config;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Exam Line Deleted Successfully");
          //dispatch(delete_exam_success(response.data));
          dispatch(showSnackbar("Chapter deleted Successfully", "success"));
        })
        .catch((err) => {
          console.log("Delete Exam Line  Failed");
          dispatch(showSnackbar("Delete Exam Failed", "error"));
          // dispatch(delete_exam_errored(err));
        });
    }
    return null;
  };
