import React, { Component } from "react";
import configureStore from '../../../store/configureStore';
import SidebarWrapper from '../../layout/SidebarWrapper';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const store = configureStore();


export default class TextEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    const { permissions } = store.getState().permission;
    
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    return (
      <div className="page">
        <SidebarWrapper
        auth={this.props.auth}
        permissions={permissions}
      />
      <section className="container">
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={this.onEditorStateChange}
          />
          <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          ></textarea>
      </section>
        
      </div>
    );
  }
}