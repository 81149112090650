import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_all_documents_by_ouid } from "../../../actions/action-document";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import DocumentDetails from "./DocumentDetails";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import DocumentUploader from "../../common/DocumentUploader";
import Loader from "../../common/Loader";
import { Paper } from "@material-ui/core";

const store = configureStore();

const workColumns = [
  {
    field: "title",
    headerName: "Title",
    width: 150,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
  },
  {
    field: "uploadedBy",
    headerName: "Uploaded By",
    width: 150,
    editable: false,
  },
  {
    field: "uploaded_Date",
    headerName: "Uploaded Date",
    type: "number",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "uploadedDate")) || ""}`,
  },
];

class DocumentBoard extends Component {
  constructor() {
    super();
    this.state = {
      documentListToShow: [],
      tabValue: 0,
      showDocumentDetails: false,
      currentDocument: {},
      upload: false,
      selectionModel: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_documents_by_ouid } = this.props;
    await get_all_documents_by_ouid(ouid);
    const { documents } = store.getState().document;
    this.setState({
      documentListToShow: documents,
    });
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
    // this.unregisterHistoryListener()
    // store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  renderDocument(documents) {
    let newRows = [];
    documents.map((document, index) => {
      let newRow = document.data;
      newRow["id"] = document.data.identifier;
      newRows.push(newRow);
    });
    return newRows;
  }

  openDetails(e) {
    console.log("openDetails");
    this.setState({
      showDocumentDetails: true,
      currentDocument: e.row,
      selectionModel: e.row.id,
    });
  }

  handleUploadBox() {
    console.log("handleUploadBox");
    this.setState((prevState) => ({
      upload: !prevState.upload,
    }));
  }

  rerender = () => {
    this.fetchDetails();
  };

  closeDetails() {
    console.log("closeDetails");
    this.setState({
      showDocumentDetails: false,
      currentDocument: {},
    });
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().document;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div>
            <div>
              <Button
                onClick={() => {
                  this.handleUploadBox();
                }}
                variant="contained"
                color="primary"
              >
                Add Document
              </Button>

              <DocumentUploader
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                rerender={this.rerender}
                defaultState={this.state.upload}
                handleClose={() => {
                  this.handleUploadBox();
                }}
              />
            </div>
            {showGlobalLoader ? (
              <Loader />
            ) : this.state.documentListToShow.length !== 0 ? (
              // <DocumentDataGrid
              //     rows={this.renderDocument(this.state.documentListToShow)}
              // />
              <Paper
                style={{ height: 500 }}
                className={`formList ${
                  this.state.showDocumentDetails ? "collapsed" : ""
                }`}
              >
                <DataGrid
                  rows={this.renderDocument(this.state.documentListToShow)}
                  columns={workColumns}
                  pageSize={15}
                  checkboxSelection
                  disableSelectionOnClick
                  onCellClick={(e) => {
                    this.openDetails(e);
                  }}
                  selectionModel={this.state.selectionModel}
                  hideFooterSelectedRowCount
                  onSelectionModelChange={(selection) => {
                    // console.log("selection  :::: ", selection);
                    if (selection.length > !2) {
                      let lastElement = selection.pop();
                      this.setSelectionModel(lastElement);
                    } else {
                      let lastElement = selection.pop();
                      this.setSelectionModel(lastElement);
                    }
                  }}
                />
              </Paper>
            ) : (
              "No Documents....."
            )}
            <div>
              {this.state.showDocumentDetails && (
                <DocumentDetails
                  documentDetails={this.state.currentDocument}
                  closeDetails={() => {
                    this.closeDetails();
                  }}
                />
              )}
            </div>
            <CommonSnackBar />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_documents_by_ouid: (activeOuid) =>
    dispatch(get_all_documents_by_ouid(activeOuid)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentBoard);
