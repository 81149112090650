import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../store/configureStore';
import SidebarWrapper from '../layout/SidebarWrapper';
import { history } from '../../reducers';
import Button from '@material-ui/core/Button';

const store = configureStore();

class PermissionError extends Component {

    render() {
        const { permissions } = store.getState().permission;
        const isAuthenticated = this.props.auth.isAuthenticated;
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        You don't have permission to this action.
                        <div>Contact Ibc admin for more information <a href="#">More info</a></div>
                        {!isAuthenticated && (
                            <Button variant="text" onClick={() => { history.push(`/login?redirect=${window.location.pathname}`); }}>
                                <div className="detailHeading">If you are loged out please login here</div>
                            </Button>
                        )}
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionError);