import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import configureStore from "../../../store/configureStore";
import { showSnackbar, hideSnackbar } from "../../../actions/action-snackbar";
import { history } from "../../../reducers";
import { Paper } from "@material-ui/core";
import axios from "axios";

const store = configureStore();

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 750,
    margin: "0 auto",
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

export default function RegisterForm(props) {
  const { userDetails } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    businessDetails: { type: "biz" },
  });
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    createWorkOrder(userDetails, state.businessDetails);
  };

  const createWorkOrder = async (userDetails, businessDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const method = "post";
    const url = `${apiEndpoint}/workorder`;
    const jwt = `${credentials.idToken.jwtToken}`;
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": "ibc",
      },
    };
    let userData = userDetails.data;
    var orgType = "biz";
    if (userDetails.data.usertype === "investor") {
      orgType = "inv";
      businessDetails.type = "inv"; //todo remove the duplication.
    }
    /**
         * 
         *  {
             "type": "BusinessRegistrationWorkOrder",
             "requestId": "{clientSideIdIfNeededForTracking}",
             "description": "Business Registration Order",
             "deadline": "2022-01-02T18:11:43.000Z",
             "details": {
                 "orgType": "biz",
                 "registrationDetails": {
                     "name": "test",
                     "businessType": "Education",
                     "companyID": "12345",
                     "GSTNumber": "123456",
                     "address": "mattannur",
                     "phoneNumber": "1234567890",
                     "numberOfStaffs": "2"
                 },
                 "userDetails": {
                     "username": "vyshak",
                     "name": "Vyshak",
                     "email": "vyshak@cloudechosystems.com",
                     "cognitoStatus": "CONFIRMED",
                     "createdBy": "Cognito Login",
                     "subscriptionStatus": "subscribed",
                     "createdDate": 1640602098014,
                     "lastModifiedDate": 1641450050104,
                     "paymentDetails": {
                         "razorpay_payment_id": "pay_IgM5iltfnJsdwN",
                         "razorpay_order_id": "order_IgM5bOddSfz0Bn",
                         "razorpay_signature": "37a8a98928711b0633caf6da92ceb5c6b16e5284eb8531cd5690b3b1cb5edfdf"
                     },
                     "bizRegistrationStatus": "inprogress"
                 }
             }
         }
         * 
         * 
         * 
         */
    const woDetails = {
      orgType: orgType,
      registrationDetails: businessDetails,
      userDetails: userData,
    };
    const payload = {
      type: "BusinessRegistrationWorkorder",
      description:
        "Business Registration WorkOrder to create organisation for the business and add org users.",
      requestId: "",
      deadline: "",
      details: woDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );
        await store.dispatch(
          showSnackbar("Business Registration Requested", "success")
        );
        history.push(`/system/registration-completed`);
      })
      .catch((err) => {
        console.log(
          "Business Registration Request failed, Please contact support : ",
          err
        );
      });
  };

  const onInputChange = (event) => {
    const newBusinessDetails = state.businessDetails;
    if (event.target.id) {
      newBusinessDetails[event.target.id] = event.target.value;
    } else {
      newBusinessDetails[event.target.name] = event.target.value;
    }
    setState({
      businessDetails: newBusinessDetails,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <form>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="name"
                label="Company Name"
                placeholder="Company Name"
                value={state.businessDetails.name || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="business-type">Type Of business</InputLabel>
                <Select
                  labelId="business-type"
                  id="business-type"
                  name="businessType"
                  value={state.businessDetails.businessType || ""}
                  onChange={onInputChange}
                  label="Type Of business"
                  className={classes.input}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Education"}>Education</MenuItem>
                  <MenuItem value={"Information technology"}>
                    Information technology
                  </MenuItem>
                  <MenuItem value={"Human resources"}>Human resources</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="companyID"
                label="Company ID"
                placeholder="Company ID"
                value={state.businessDetails.companyID || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="GSTNumber"
                label="GST Number"
                placeholder="GST Number"
                value={state.businessDetails.GSTNumber || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
          </form>
        );
      case 1:
        return (
          <form>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="address"
                label="Address"
                placeholder="Address"
                value={state.businessDetails.address || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                value={state.businessDetails.phoneNumber || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="numberOfStaffs"
                label="Number Of Staffs"
                placeholder="Number Of Staffs"
                value={state.businessDetails.numberOfStaffs || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="websiteAddress"
                label="Website address"
                placeholder="Website address"
                value={state.businessDetails.websiteAddress || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
          </form>
        );
      case 2:
        return (
          <form>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="pancard"
                label="Pancard"
                placeholder="Pancard"
                value={state.businessDetails.pancard || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
            <div className="form-item-wrapper">
              <TextField
                className={classes.input}
                id="registarionCertificates"
                label="Registarion Certificates"
                placeholder="Registarion Certificates"
                value={state.businessDetails.registarionCertificates || ""}
                onChange={onInputChange}
                variant="outlined"
              />
            </div>
          </form>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className={classes.root}>
      <div className="container--narrow">
        <Paper className="formContainer">
          <h1>Complete your company registration to continue.</h1>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Paper>
      </div>
      {/* {JSON.stringify(state.businessDetails)}
            {JSON.stringify(userDetails)} */}
    </div>
  );
}
