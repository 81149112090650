import React from 'react';
import Button from '@material-ui/core/Button';

export default function FormButton(props) {
    const { onClick, text } = props;
    return (
        <Button variant="contained" color="primary" onClick={onClick}>
        {text}
      </Button>
    );
}