import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_examinstance_result } from "../../../actions/action-examinstance";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const store = configureStore();

class MyExamResult extends Component {
  constructor() {
    super();
    this.state = {
      examinstanceDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 2];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_examinstance_result } = this.props;
    await get_examinstance_result(activeOuid, activeOuidChain, id);
    console.log("fetched examinstance result: ", store.getState().examinstance);

    this.setState({
      examinstanceDetails: store.getState().examinstance.examinstanceDetails,
    });
  }

  renderExamInstanceResult() {
    const { showGlobalLoader } = store.getState().examinstance;

    const { activeOuidType } = store.getState().permission;
    console.log(
      "activeOuidType in renderExamInstanceResult : ",
      activeOuidType
    );

    console.log(
      "store.getState().examinstance.examinstanceDetails : ",
      store.getState().examinstance.examinstanceDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="ExamGrid">
            <Paper className="ExamModuleWrapper">
              <div className="ExamDetailsWrapper">
                <div className="">
                  {store.getState().examinstance.examinstanceDetails &&
                  store.getState().examinstance.examinstanceDetails.data &&
                  store.getState().examinstance.examinstanceDetails.result ? (
                    <>
                      {store.getState().examinstance.examinstanceDetails.data
                        .examData.passMarks && (
                        <h3>
                          Result :
                          {store.getState().examinstance.examinstanceDetails
                            .result.passed
                            ? "Passed"
                            : "Failed"}
                        </h3>
                      )}
                      <h3>
                        Exam :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examData.title
                        }
                      </h3>
                      <h4>
                        Name :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails
                            .modifiedBy
                        }
                      </h4>
                      <h4>
                        Username :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails.data
                            .examuser
                        }
                      </h4>
                      {store.getState().examinstance.examinstanceDetails.data
                        .examData.passMarks && (
                        <h4>
                          Minimum marks to pass :{" "}
                          {
                            store.getState().examinstance.examinstanceDetails
                              .result.passMarks
                          }
                        </h4>
                      )}
                      <h4>
                        Total Marks Scored :{" "}
                        {
                          store.getState().examinstance.examinstanceDetails
                            .result.totalMarks
                        }
                      </h4>
                      <h4>
                        Date :{" "}
                        {new Date(
                          store.getState().examinstance.examinstanceDetails.modifiedDate
                        )
                          .toUTCString()
                          .toLocaleString()}
                      </h4>
                    </>
                  ) : (
                    "Exam result is not available yet."
                  )}
                </div>
              </div>
            </Paper>
            <CommonSnackBar />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { showGlobalLoader } = store.getState().exam;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div className="page">
        <div className="container">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
          <div className="section">{this.renderExamInstanceResult()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  exam: state.exam,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_examinstance_result: (activeOuid, activeOuidChain, id) =>
    dispatch(get_examinstance_result(activeOuid, activeOuidChain, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyExamResult);
