import React, { Component } from "react";
import { connect } from "react-redux";
import Webcam from "react-webcam";

import configureStore from "../../../store/configureStore";
import {
  get_examinstance,
  get_examlineinstances,
  get_examlineinstance,
  update_selectedanswer,
  submit_examinstance,
} from "../../../actions/action-examinstance";

import { create_event } from "../../../actions/action-event";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExamTimer from "./ExamTimer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const store = configureStore();
const videoConstraints = {
  width: 100,
  height: 100,
  facingMode: "user",
};

class MyExamDetails extends Component {
  constructor() {
    super();
    this.webcamRef = React.createRef();
    this.state = {
      examinstanceDetails: {},
      examlineinstances: [],
      examlineinstanceDetails: {},
      currentIndex: 0,
      open: false,
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_examinstance, get_examlineinstances, get_examlineinstance } =
      this.props;
    await get_examinstance(activeOuid, activeOuidChain, id);
    console.log("fetched examinstance: ", store.getState().examinstance);

    await get_examlineinstances(activeOuid, id);
    console.log(
      "fetched examlineinstances: ",
      store.getState().examinstance.examlineinstances[0]
    );

    if (
      store.getState().examinstance.examlineinstances &&
      store.getState().examinstance.examlineinstances.length > 0
    ) {
      var examlineinstanceId =
        store.getState().examinstance.examlineinstances[0].data.identifier;
      console.log("examlineinstanceId: ", examlineinstanceId);
      await get_examlineinstance(activeOuid, examlineinstanceId);
    }

    this.setState({
      examinstanceDetails: store.getState().examinstance.examinstanceDetails,
      examlineinstances: store.getState().examinstance.examlineinstances,
      examlineinstanceDetails:
        store.getState().examinstance.examlineinstanceDetails,
    });
  }

  async answerChanged(item, answerValue) {
    const { activeOuid } = store.getState().permission;
    const { update_selectedanswer, create_event } = this.props;
    item.selectedAnswer = answerValue;
    await update_selectedanswer(activeOuid, item.data.identifier, item);
    this.setState({
      ...this.state,
      examlineinstanceDetails:
        store.getState().examinstance.examlineinstanceDetails,
    });
    console.log(
      "update_selectedanswer examinstance",
      store.getState().examinstance
    );
    const imageSrc = this.webcamRef.current.getScreenshot();
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const payload = {
      groupObjectId: id,
      groupType: "examlineinstance_answerSelected",
      imageSrc: imageSrc,
      questionId: item.data.identifier,
      answerSelected: answerValue,
    };
    await create_event(payload);
    console.log("imageSrc", imageSrc);
  }

  async gotoPrevious() {
    const { activeOuid } = store.getState().permission;
    const { get_examlineinstance } = this.props;
    console.log("currentIndex", this.state.currentIndex);

    if (this.state.currentIndex > 0) {
      const previousIndex = this.state.currentIndex - 1;

      var examlineinstanceId =
        store.getState().examinstance.examlineinstances[previousIndex].data
          .identifier;
      console.log("examlineinstanceId: ", examlineinstanceId);
      await get_examlineinstance(activeOuid, examlineinstanceId);

      this.setState({
        ...this.state,
        examlineinstanceDetails:
          store.getState().examinstance.examlineinstanceDetails,
        currentIndex: previousIndex,
      });
    }
  }

  async submitExam() {
    const { activeOuid } = store.getState().permission;
    const { submit_examinstance } = this.props;
    console.log("submit_examinstance");
    const examinstanceid = this.state.examinstanceDetails.data.identifier;
    console.log("examinstanceid: ", examinstanceid);
    await submit_examinstance(activeOuid, examinstanceid);
    history.push(`/${activeOuid}/postexam/${examinstanceid}`);
  }

  async gotoNext() {
    console.log("currentIndex", this.state.currentIndex);
    const { get_examlineinstance } = this.props;
    const { activeOuid } = store.getState().permission;

    if (this.state.currentIndex < this.state.examlineinstances.length - 1) {
      const nextIndex = this.state.currentIndex + 1;

      var examlineinstanceId =
        store.getState().examinstance.examlineinstances[nextIndex].data
          .identifier;
      console.log("examlineinstanceId: ", examlineinstanceId);
      await get_examlineinstance(activeOuid, examlineinstanceId);

      this.setState({
        ...this.state,
        examlineinstanceDetails:
          store.getState().examinstance.examlineinstanceDetails,
        currentIndex: nextIndex,
      });
    }
  }

  renderExamInstance() {
    const {
      showGlobalLoader,
      showGlobalLoaderExamlineinstance,
      showGlobalLoaderExamlineinstances,
    } = store.getState().examinstance;

    const { activeOuidType } = store.getState().permission;
    console.log("activeOuidType in renderExamInstance : ", activeOuidType);

    console.log(
      "store.getState().examinstance.examinstanceDetails : ",
      store.getState().examinstance.examinstanceDetails
    );

    console.log(
      "store.getState().examinstance.examlineinstanceDetails : ",
      store.getState().examinstance.examlineinstanceDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="ExamGrid">
            <Paper className="ExamModuleWrapper">
              <div className="ExamDetailsWrapper">
                <div className="ExamTitleWrapper">
                  <div className="ExamTitle">
                    {store.getState().examinstance.examinstanceDetails &&
                    store.getState().examinstance.examinstanceDetails.data ? (
                      <>
                        <h3>
                          {
                            store.getState().examinstance.examinstanceDetails
                              .data.examData.title
                          }
                        </h3>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {showGlobalLoaderExamlineinstances ? (
                    <Loader />
                  ) : (
                    <ExamTimer
                      examinstanceDetails={
                        store.getState().examinstance.examinstanceDetails
                      }
                    />
                  )}
                </div>
                <div className="ExamWebCam">
                  <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    height={100}
                    width={100}
                    videoConstraints={videoConstraints}
                    ref={this.webcamRef}
                    className="ExamTitle"
                  />
                </div>
              </div>
              {showGlobalLoaderExamlineinstance ||
              showGlobalLoaderExamlineinstances ? (
                <Loader />
              ) : store.getState().examinstance.examlineinstances.length !==
                  0 &&
                (store.getState().examinstance.examinstanceDetails.data.examData
                  .status === "active" ||
                  store.getState().examinstance.examinstanceDetails.data
                    .examData.status === "open") ? (
                <div>
                  {this.renderQuestionMeta()}
                  {this.renderQuestion(
                    store.getState().examinstance.examlineinstanceDetails
                  )}
                  <hr size="2" width="100%" color="#000" />

                  {this.renderSubmit()}
                </div>
              ) : (
                "Exam not open/active or no Questions....."
              )}
            </Paper>
            <CommonSnackBar />
          </div>
        )}
      </div>
    );
  }

  renderQuestion = (examlineinstance) => {
    const { activeOuid } = store.getState().permission;

    var currentItem = examlineinstance;

    return (
      <>
        <div>
          <div className="ExamQuestionText">{currentItem.data.question}</div>
          <div className="CourseModuleTitle">
            {currentItem.data.questionImage ? (
              <img src={currentItem.data.questionImage} alt="" />
            ) : (
              ""
            )}
          </div>
          <div className="ExamAnswerOptionWrapper">
            <div>1.</div>
            <input
              type="radio"
              name="currentSelectedAnswer"
              value="answer1"
              checked={currentItem.selectedAnswer === "answer1"}
              onChange={() => {
                this.answerChanged(currentItem, "answer1");
              }}
            />
            <div className="">{currentItem.data.answer1}</div>
          </div>
          <div className="ExamAnswerOptionWrapper">
            <div>2.</div>
            <input
              type="radio"
              name="currentSelectedAnswer"
              value="answer2"
              checked={currentItem.selectedAnswer === "answer2"}
              onChange={() => {
                this.answerChanged(currentItem, "answer2");
              }}
            />
            <div className="">{currentItem.data.answer2}</div>
          </div>
          <div className="ExamAnswerOptionWrapper">
            <div>3.</div>
            <input
              type="radio"
              name="currentSelectedAnswer"
              value="answer3"
              checked={currentItem.selectedAnswer === "answer3"}
              onChange={() => {
                this.answerChanged(currentItem, "answer3");
              }}
            />
            <div className="">{currentItem.data.answer3}</div>
          </div>
          <div className="ExamAnswerOptionWrapper ">
            <div>4.</div>

            <input
              type="radio"
              name="currentSelectedAnswer"
              value="answer4"
              checked={currentItem.selectedAnswer === "answer4"}
              onChange={() => {
                this.answerChanged(currentItem, "answer4");
              }}
            />
            <div className="">{currentItem.data.answer4}</div>
          </div>
          <div className="ExamNavButtonWrapper">
            <Button
              onClick={() => {
                this.gotoPrevious();
              }}
              color="primary"
              variant="outlined"
            >
              &lt; Previous
            </Button>
            <span></span>
            <Button
              onClick={() => {
                this.gotoNext();
              }}
              color="primary"
              variant="outlined"
            >
              Next &gt;
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderQuestionMeta = () => {
    var currentQ = this.state.currentIndex + 1;
    var maxQ = this.state.examlineinstances.length;

    return (
      <>
        {" "}
        <div>
          <h4>
            Question {currentQ} of {maxQ}
          </h4>
        </div>
      </>
    );
  };

  handleSubmit = () => {
    console.log("I agree!");
    this.handleClose();
    this.submitExam();
  };
  handleCancel = () => {
    console.log("Submit cancelled by user");
    this.setState({ ...this.state, open: false });
  };

  handleClose = () => {
    console.log("handleClose");
    this.setState({ ...this.state, open: false });
  };

  handleSubmitClickOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  renderSubmit = () => {
    return (
      <div className="SubmitBtnWrapper">
        <Button
          onClick={() => {
            this.handleSubmitClickOpen();
          }}
          color="primary"
          variant="contained"
        >
          Submit Exam
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"CONFIRM SUBMIT to submit the exam"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Some exams don't allow retake once submitted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              CANCEL
            </Button>
            <Button onClick={this.handleSubmit} color="primary" autoFocus>
              CONFIRM SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    const { showGlobalLoader } = store.getState().exam;
    const { examinstanceDetails } = store.getState().examinstance;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div className="page">
        <div className="container">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />

          <div className="section">{this.renderExamInstance()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  exam: state.exam,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_examinstance: (activeOuid, activeOuidChain, id) =>
    dispatch(get_examinstance(activeOuid, activeOuidChain, id)),
  get_examlineinstances: (activeOuid, id) =>
    dispatch(get_examlineinstances(activeOuid, id)),
  get_examlineinstance: (activeOuid, id) =>
    dispatch(get_examlineinstance(activeOuid, id)),
  update_selectedanswer: (activeOuid, id, answer) =>
    dispatch(update_selectedanswer(activeOuid, id, answer)),
  submit_examinstance: (activeOuid, id) =>
    dispatch(submit_examinstance(activeOuid, id)),
  create_event: (payload) => dispatch(create_event(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyExamDetails);
