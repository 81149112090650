import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth: 275,
        marginRight: 15,
        marginBottom: 15,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        backgroundColor: '#ffffff',
        boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
        borderRadius: '3px',
        '&:hover': {
            boxShadow: 'rgb(9 30 66 / 25%) 0px 8px 16px -4px, rgb(9 30 66 / 31%) 0px 0px 1px',
            transition: 'box-shadow 0.2s ease 0s',
        },
    },

    avatar: {
        backgroundColor: '#dc281e',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    CardActions: {
        padding: 0,
    },
});

export default function AppPolicyCard(props) {
    const { context, policy, index, deletePolicy, editable } = props;
    const classes = useStyles();
    const Icontooltip = withStyles({
        tooltip: {
            fontSize: 12,
        },
    })(Tooltip);
    return (
        context === "createPage" ?
            <Card className={classes.root}>
                <div className="card">
                    <div className="detailwrapper">
                    </div>
                    <div className="detailwrapper">
                        <div className="detailValue">{policy.appId}</div>
                        <div className="detailValue">{policy.roles[0]}</div>
                    </div>
                </div>
                {editable &&
                    <CardActions disableSpacing className={classes.CardActions}>
                        <Icontooltip title="Delete">
                            <IconButton aria-label="add to favorites" onClick={() => { deletePolicy(index) }}>
                                <DeleteIcon />
                            </IconButton>
                        </Icontooltip>
                    </CardActions>
                }
            </Card>
            :
            <Card className={classes.root}>
                <div className="card">
                    <div className="detailwrapper">
                    </div>
                    <div className="detailwrapper">
                        <div className="detailValue">{policy.data.appId}</div>
                        <div className="detailValue">{policy.data.roles[0]}</div>
                    </div>
                </div>
                {editable &&
                    <CardActions disableSpacing className={classes.CardActions}>
                        <Icontooltip title="Delete">
                            <IconButton aria-label="add to favorites" onClick={() => { deletePolicy(index) }}>
                                <DeleteIcon />
                            </IconButton>
                        </Icontooltip>
                    </CardActions>
                }
            </Card>

    );
}
