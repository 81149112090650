import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_orgunit_details } from '../../../actions/action-orgunit';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import CommonDelete from '../../common/CommonDelete';
import { timeFormat } from '../../common/CommonFunctions'
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from '../../../actions/action-snackbar';
import { Paper } from '@material-ui/core';


const store = configureStore();

class OrgunitDetails extends Component {

  constructor() {
    super();
    this.state = {
      OrgunitDetails: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
  }

  async fetchDetails() {
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    const { get_orgunit_details } = this.props;
    await get_orgunit_details(id);
    const { OrgunitDetails } = store.getState().orgunit;
    this.setState({
      OrgunitDetails: OrgunitDetails,
    });
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">
            {keyName} :
          </div>
          {keyName === 'lastModifiedDate' || keyName === 'createdDate' ?
            <div className="detailsValue">
              {timeFormat(details[keyName])}
            </div>
            :
            <div className="detailsValue">
              {details[keyName]}
            </div>
          }
        </div>
      )
    });
  }

  render() {
    const { showGlobalLoader } = store.getState().orgunit;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <Paper component="div" className="paper">
                <div className="listHeader">
                  <h1>Orgunit Details</h1>
                  {this.state.OrgunitDetails.markedForDeletion ?
                    <div>Orgunit Already Requested For Deletion</div>
                    :
                    <CommonDelete
                      id={id}
                      type="Orgunit"
                    />
                  }
                </div>
                {this.state.OrgunitDetails.data ? this.renderDetails(this.state.OrgunitDetails.data) : 'No Orgunit details Found'}
              </Paper>
            </div>
          }
          <CommonSnackBar />
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_orgunit_details: (id) => dispatch(get_orgunit_details(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgunitDetails);

