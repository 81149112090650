import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 275,
    marginRight: 15,
    marginBottom: 15,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'rgb(9 30 66 / 25%) 0px 8px 16px -4px, rgb(9 30 66 / 31%) 0px 0px 1px',
      transition: 'box-shadow 0.2s ease 0s',
    },
  },

  avatar: {
    backgroundColor: '#dc281e',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CardActions: {
    padding: 0,
  },
});

export default function UserCard(props) {
  const { user } = props;
  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = user.data;
  return (
    <Card className={classes.root}>
      {data && (
      <div>
      <div className="card">
        <div className="detailwrapper">
        </div>
        <div className="detailwrapper">
          <div className="detailHeading">First Name: </div>
          <div className="detailValue">{data.name}</div>
        </div>
        <div className="detailwrapper">
          <div className="detailHeading">Email: </div>
          <div className="detailValue">{data.email}</div>
        </div>
      </div>
      <CardActions disableSpacing className={classes.CardActions}>
        <a href={`/user/${data.username.replace(/ /g, '-')}`}>
        <Icontooltip title="Details">
            <IconButton aria-label="add to favorites">
              <VisibilityIcon />
            </IconButton>
          </Icontooltip>
        </a>
        <a href={`/edit-user/${data.username}`}>
        <Icontooltip title="Edit">
              <IconButton aria-label="add to favorites">
                <EditIcon />
              </IconButton>
            </Icontooltip>
        </a>
      </CardActions> 
      </div>
      )}
    </Card>
  );
}
