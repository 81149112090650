import {
  CREATE_CL_QUESTION_REQUESTED,
  CREATE_CL_QUESTION_SUCCESS,
  CREATE_CL_QUESTION_ERRORED,
  GET_CL_QUESTIONS_REQUESTED,
  GET_CL_QUESTIONS_SUCCESS,
  GET_CL_QUESTIONS_ERRORED,
  DELETE_CL_QUESTION_REQUESTED,
  DELETE_CL_QUESTION_SUCCESS,
  DELETE_CL_QUESTION_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  questions: [],
  error: {},
};

export function question(state = defaultState, action) {
  switch (action.type) {
    case GET_CL_QUESTIONS_REQUESTED:
      console.log("GET_CL_QUESTIONS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CL_QUESTIONS_SUCCESS:
      console.log(
        "GET_CL_QUESTIONS_SUCCESS : ",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        showGlobalLoader: false,
        questions: action.payload.items,
      };

    case GET_CL_QUESTIONS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_CL_QUESTION_REQUESTED:
      console.log("CREATE_CL_QUESTION_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_CL_QUESTION_SUCCESS:
      console.log("CREATE_CL_QUESTION_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_CL_QUESTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case DELETE_CL_QUESTION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_CL_QUESTION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_CL_QUESTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
}
