import { 
  GET_MY_PROFILE_DETAILS_REQUESTED, 
  GET_MY_PROFILE_DETAILS_SUCCESS,
  GET_MY_PROFILE_DETAILS_ERRORED,
 } from '../constant/action-types';

const defaultState = {
  showGlobalLoader: false,
  details : {},
  error:{}
};

export function myProfile(state = defaultState, action) {
  switch (action.type) {
    case GET_MY_PROFILE_DETAILS_REQUESTED:
      console.log("GET_MY_PROFILE_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        details :{}
      };
  
    case GET_MY_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        details : action.payload
      };

      case GET_MY_PROFILE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error
      };
  
    default:
      return state;
    }
}