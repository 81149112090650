import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import configureStore from '../../store/configureStore';
import { hideSnackbar } from '../../actions/action-snackbar';

const store = configureStore();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CommonSnackBar(props) {
  const { snackbar } = store.getState();
  const handleSnackClose = () => {
    store.dispatch(hideSnackbar());
  };
  return (
    <Snackbar open={snackbar.snackbarOpen} autoHideDuration={3000} onClose={handleSnackClose}>
      <Alert onClose={handleSnackClose} severity={snackbar.snackbarType}>
        {snackbar.snackbarMessage}
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonSnackBar);

