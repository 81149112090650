import {
    CREATE_APP_USER_REQUESTED,
    CREATE_APP_USER_SUCCESS,
    CREATE_APP_USER_ERRORED,
    GET_APP_USER_DETAILS_REQUESTED,
    GET_APP_USER_DETAILS_SUCCESS,
    GET_APP_USER_DETAILS_ERRORED,
    GET_ALL_APP_USER_REQUESTED,
    GET_ALL_APP_USER_SUCCESS,
    GET_ALL_APP_USER_ERRORED,
    DELETE_APP_USER_REQUESTED,
    DELETE_APP_USER_SUCCESS,
    DELETE_APP_USER_ERRORED,
} from '../constant/action-types';

const defaultState = {
    showGlobalLoader: false,
    appUserDetails: {},
    appUserProfiles: [],
    error: {}
};

export function appUserProfile(state = defaultState, action) {
    switch (action.type) {

        case CREATE_APP_USER_REQUESTED:
            console.log("CREATE_APP_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
            };

        case CREATE_APP_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
            };

        case CREATE_APP_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case GET_APP_USER_DETAILS_REQUESTED:
            console.log("GET_APP_USER_DETAILS_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                appUserDetails: {}
            };

        case GET_APP_USER_DETAILS_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                appUserDetails: action.payload
            };

        case GET_APP_USER_DETAILS_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case GET_ALL_APP_USER_REQUESTED:
            console.log("GET_ALL_APP_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                appUserProfiles: []
            };

        case GET_ALL_APP_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                appUserProfiles: action.payload.items
            };

        case GET_ALL_APP_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case DELETE_APP_USER_REQUESTED:
            console.log("DELETE_APP_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
            };

        case DELETE_APP_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
            };

        case DELETE_APP_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        default:
            return state;
    }
}