import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import {
  get_cl_questions,
  create_cl_question,
} from "../../../actions/action-cl-question";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import Player from "../Vod/Player";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const store = configureStore();

class CourseLineTest extends Component {
  constructor() {
    super();
    this.state = {
      questions: [],
      currentCourselineId: "",
      currentQuestionText: "",
      currentAnswerOption1: "",
      currentAnswerOption2: "",
      currentAnswerOption3: "",
      currentAnswerOption4: "",
      currentAnswerOptions: "",

      currentSequenceNumber: "",
      addQuestionVisibility: false,
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("store.getState().permission : ", store.getState().permission);
    const { activeOuid } = store.getState().permission;
    console.log("id*********** : ", id);

    const { get_cl_questions } = this.props;
    await get_cl_questions(activeOuid, id);
    console.log("fetched course line questions ", store.getState().question);
    this.setState({
      questions: store.getState().question.questions,
      currentCourselineId: id,
    });
    if (this.state.questions.length > 0) {
      this.state.questions.forEach((element) => {
        console.log("element.data.question", element.data.question);
        if (element.data.sequenceNumber === "1") {
          console.log("element.data.questionText", element.data.questionText);
          element["currentSelection"] = "true";

          this.setState({
            currentQuestionText: element.data.questionText,
            currentSequenceNumber: element.data.sequenceNumber,
            currentAnswerOptions: element.data.answerOptions,
          });
        }
      });
    }
  }

  async addQuestion() {
    console.log("Add Question");
    console.log(
      "this.state.currentCourselineId ",
      this.state.currentCourselineId
    );

    let payload = {
      courselineId: this.state.currentCourselineId,
      questionText: this.state.newQuestionText,
      answerOptions: [
        {
          answerText: this.state.newAnswerOption1,
          isCorrect: this.state.newAnswerOption1Correctness,
        },
        {
          answerText: this.state.newAnswerOption2,
          isCorrect: this.state.newAnswerOption2Correctness,
        },
        {
          answerText: this.state.newAnswerOption3,
          isCorrect: this.state.newAnswerOption3Correctness,
        },
        {
          answerText: this.state.newAnswerOption4,
          isCorrect: this.state.newAnswerOption4Correctness,
        },
      ],
      sequenceNumber: this.state.newSequenceNumber,
    };
    await store.dispatch(create_cl_question(payload));
  }

  onInputChange = (event) => {
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", event.target.value);

    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  setAddQuestionVisibility(visibility) {
    console.log("addQuestionVisibility", this.state.addQuestionVisibility);
    this.setState({
      addQuestionVisibility: visibility,
    });
  }

  renderQuestionList(questions) {
    console.log("questions : ", JSON.stringify(questions));
    return questions.map((item) => (
      <div>
        <div>
          Q{item.data.sequenceNumber} : {item.data.questionText}
        </div>
        {item.data.answerOptions && (
          <List>
            {item.data.answerOptions.map((answer) => (
              <ListItem key={answer.answerText}>
                <ListItemText primary={answer.answerText} />
                {answer.isCorrect && <div>{"  >> Correct"}</div>}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    ));
  }

  render() {
    const { showGlobalLoader } = store.getState().question;
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("activeOuidType in render : ", activeOuidType);
    console.log("permissions in render : ", store.getState().permission);
    console.log("userRoles[0] in render : ", userRoles[0]);
    if (
      policies &&
      activeOuidType === "ROOT" &&
      (policies.includes(`${activeOuid}=admin`) ||
        policies.includes(`system=admin`) ||
        policies.includes(`${activeOuid}=user`))
    ) {
      console.log("return for ROOT : ");
      console.log("showGlobalLoader : ", showGlobalLoader);
      console.log("this.state.questions : ", this.state.questions);
      const { permissions } = store.getState().permission;

      return (
        <div className="page">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
          <div className="section">
            <div className="container--narrow">
            
              {showGlobalLoader ? (
                <Loader />
              ) : store.getState().question.questions.length !== 0 ? (
                <div>
                  {this.renderQuestionList(store.getState().question.questions)}
                </div>
              ) : (
                <h2>No Course Questions added yet...</h2>
              )}
            </div>
          </div>
          <CommonSnackBar />
        </div>
      );
    } else {
      console.log("return for non root : ");
      console.log("store.getState().question : ", store.getState().question);

      return (
        <div className="">Please subscribe to the course to take the test.</div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  question: state.question,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_cl_questions: (activeOuid, id) =>
    dispatch(get_cl_questions(activeOuid, id)),

  create_cl_question: (payload) => dispatch(create_cl_question(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseLineTest);
