import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth } from "aws-amplify";
import { history } from "../../reducers";
import configureStore from "../../store/configureStore";
import { resetState } from "../../actions";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import AdjustIcon from "@material-ui/icons/Adjust";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import GroupIcon from "@material-ui/icons/Group";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PublicIcon from "@material-ui/icons/Public";
import Logo from "../assets/images/ces/ces-logo.png";

// Images
import AccountCircle from "@material-ui/icons/AccountCircle";

// Material Ui
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CreditScoreIcon from "@material-ui/icons/CreditCard";

// const apps = require('../pages/Apps');

const store = configureStore();

const drawerWidth = 240;
const headerHeight = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop: headerHeight,
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  linkText: {
    fontWeight: 900,
    color: "rgba(0, 0, 0, 0.70)",
  },
  ActiveItem: {
    background: "#e8f0fe",
    color: "#1967d2",
  },
}));
function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [open, setOpen] = React.useState(true);

  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const openProfile = Boolean(anchorProfile);
  const isAuthenticated = props.auth.isAuthenticated;
  const { activeOuid, userRoles, policies, activeOuidType } =
    store.getState().permission;
  const { activeOuidCoreConfigs } = store.getState().config;
  const { session } = store.getState().jwt.api.userSession;
  useEffect(() => {
    setActiveOuidValue(store.getState().permission.activeOuid);
    console.log("width", width);
    if (width <= 768) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [JSON.stringify(props)]);
  const [activeOuidValue, setActiveOuidValue] = React.useState(activeOuid);

  const [Dropdownopen, setDropdownOpen] = React.useState(false);
  const handleDropdownClick = () => {
    setDropdownOpen(!Dropdownopen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfile(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      props.auth.setAuthStatus(false);
      props.auth.setUser(null);
      store.dispatch(resetState());
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="secondary"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img src={Logo} alt="" />

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {/* {isAuthenticated && (
              <div>
                <IconButton aria-label="show 4 new mails" color="inherit" edge="end">
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </div>
            )} */}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`/${activeOuid}/my-profile`);
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            )}
            {!isAuthenticated && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorProfile}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfile}
                onClose={handleProfileClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            )}
            {isAuthenticated && <div>{session.username}</div>}
          </div>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.MenuList}
          >
            <ListItem
              button
              className={classes.ActiveItem}
              onClick={() => {
                history.push(`/${activeOuid}/user-home`);
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Home"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/${activeOuid}/course-dash`);
              }}
            >
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Certification"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/${activeOuid}/loanreq-dash`);
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Get Loan"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/${activeOuid}/investreq-dash`);
              }}
            >
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Get Investment"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/${activeOuid}/user-govtscheme`);
              }}
            >
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Govt. Schemes"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push(`/${activeOuid}/appointment-dash`);
              }}
            >
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="Meetings"
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleDropdownClick}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.linkText }}
                primary="My Company"
              />
              {Dropdownopen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={Dropdownopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/company-profile`);
                  }}
                >
                  <ListItemIcon>
                    <LocationCityIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Company Profile"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/orgusers`);
                  }}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Users"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/orgunits`);
                  }}
                >
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Branches"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/org-workqueue-dash`);
                  }}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Tasks"
                  />
                </ListItem>

                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/document-dash`);
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="Documents"
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => {
                    history.push(`/${activeOuid}/hrmapp-dash`);
                  }}
                >
                  <ListItemIcon>
                    <EmojiPeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.linkText }}
                    primary="HRM App"
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
          <ListItem
            button
            onClick={() => {
              history.push(`/${activeOuid}/user-support`);
            }}
          >
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.linkText }}
              primary="Support/Help"
            />
          </ListItem>
        </Drawer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  jwt: state.jwt,
  config: state.config,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
