import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_events } from "../../../actions/action-event";

import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import CommonSnackBar from "../../common/CommonSnackBar";
import { Paper, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const store = configureStore();

const Icontooltip = withStyles({
  tooltip: {
    fontSize: 12,
  },
})(Tooltip);

class ExamViewEvents extends Component {
  constructor() {
    super();
    this.state = {
      eventsToShow: [],
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("fetchDetails ouid : ", ouid);
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;

    console.log(" fetchDetails activeOuid : ", activeOuid);
    console.log("fetchDetails permission : ", store.getState().permission);
    let groupType = "examlineinstance_answerSelected";
    let groupObjectId = id;

    const { get_events } = this.props;

    await get_events(activeOuid, activeOuidChain, groupObjectId, groupType);
    const { events } = store.getState().event;
    console.log("fetchDetails > events : ", events);
    console.log(" get_all_orguesr activeOuid : ", activeOuid);

    this.setState({
      eventsToShow: events,
    });
  }

  renderEvents(events) {
    console.log("events : ", events);
    return events.map((event) => (
      <TableRow key={event.data.groupObjectId}>
        <TableCell>
          <img src={event.data.imageSrc} />
          questionId : {event.data.questionId}
          <br></br>
          answerSelected : {event.data.answerSelected}
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    const { permissions } = store.getState().permission;

    return (
      <div className="page">
        <SidebarWrapper auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <div className="List">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="TableHeader">
                        Exam Instance Events
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.eventsToShow.length !== 0
                      ? this.renderEvents(this.state.eventsToShow)
                      : "No Events"}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orgunit: state.orgunit,
  showGlobalLoader: state.orgunit.showGlobalLoader,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_events: (activeOuid, activeOuidChain, groupObjectId, groupType) =>
    dispatch(get_events(activeOuid, activeOuidChain, groupObjectId, groupType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamViewEvents);
