import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Logo from "../assets/images/ces/ces-logo-dark.png";
import HomeIcon from "../assets/images/edwinImages/Home.png";
import UserIcon from "../assets/images/edwinImages/Profile.png";
import NotificationIcon from "../assets/images/edwinImages/Notify.png";
import MessageIcon from "../assets/images/edwinImages/Support.png";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#132749",
    color: "#fff",
    padding: 10,
    fontSize: 16,
    textAlign: "center",
    // [theme.breakpoints.up("xs")]: {
    //   display: "flex",
    // },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
  footerLogo: {
    margin: "0 auto",
    maxWidth: "60px",
    marginBottom: "00px",
  },
  footerLink: {
    color: "#F29F05",
  },
}));

function Footer() {
  const classes = useStyles();

  const { activeOuid } = store.getState().permission;
  return (
    <>
      <div className={classes.footer}>
        <div className="container">
          <div className="split alignStart">
            <div className="companyDetails">
              <img className={classes.footerLogo} src={Logo} />
              <div>
                <div>
                  <a
                    href="mailto:app.skillgodzilla@gmail.com"
                    className={classes.footerLink}
                  >
                    app.skillgodzilla@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
