/**
 * Function for removing items from application state
 * @param {*} state
 * @param {*} fieldNamesStart - array of field names patterns,
 *                              used as argument for `startsWith()` function for comporation
 */

export const cleanState = (state = {}, fieldNamesStart = []) => {
  const clearState = {};
  Object.keys(state).map((key) => {
    if (fieldNamesStart.filter((keyPart) => key.startsWith(keyPart)).length === 0) {
      clearState[key] = state[key];
    }
    return null;
  });
  return clearState;
};
